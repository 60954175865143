import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n\n  div {\n    margin: 32px 0;\n\n    h5 {\n      font-family: ",
        ";\n      font-weight: 400;\n      ",
        "\n      margin: 0;\n\n      b {\n        font-family: ",
        ";\n        font-weight: 400;\n      }\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-top: 48px;\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-top: 48px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-top: 40px;\n  margin-bottom: 40px;\n  padding: 0;\n\n  li {\n    list-style: none;\n    font-family: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 56px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  p,\n  span {\n    font-style: normal;\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n  }\n\n  div {\n    margin: 0;\n    margin-top: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin: 0;\n      margin-top: 8px;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  ",
        "\n  color: #333;\n\n  @media (min-width: ",
        ") {\n    margin-top: 24px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n    \n    margin: 0;\n\n    div {\n      margin: 0;\n       margin-top: 12px;\n    }\n\n    .rec {\n      ",
        "\n    }\n\n    @media (min-width: ",
        ") {\n      div {\n        margin-top: 8px;\n        ",
        "\n\n        b:first-of-type {\n          ",
        "\n        }\n      }\n\n      .rec {\n        ",
        "\n      }\n    }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-top: 18px;\n    ",
        "\n    color: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin: 0;\n      margin-top: 18px;\n    }\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    padding-top: 16px;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ParagraphContainer = styled.p.withConfig({
    componentId: "sc-d6495ddb-0"
})(_templateObject());
export var Address = styled.h5.withConfig({
    componentId: "sc-d6495ddb-1"
})(_templateObject1(), fonts.light, pxToCssFont(20, 30), fonts.regular);
export var SectionTitle = styled(ParagraphContainer).withConfig({
    componentId: "sc-d6495ddb-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), colors.$262626, breakpoints.desktop, pxToCssFont(36, 45));
export var Title = styled(ParagraphContainer).withConfig({
    componentId: "sc-d6495ddb-3"
})(_templateObject3(), fonts.exbold, pxToCssFont(24, 30), colors.$262626, breakpoints.desktop);
export var OffersList = styled.ul.withConfig({
    componentId: "sc-d6495ddb-4"
})(_templateObject4(), fonts.regular, breakpoints.desktop);
export var Paragraph = styled.div.withConfig({
    componentId: "sc-d6495ddb-5"
})(_templateObject5(), pxToCssFont(18, 24), colors.$0d0d0d, breakpoints.desktop);
export var ParagraphBold = styled(Paragraph).withConfig({
    componentId: "sc-d6495ddb-6"
})(_templateObject6(), pxToCssFont(20, 26), breakpoints.desktop);
export var Price = styled(Title).withConfig({
    componentId: "sc-d6495ddb-7"
})(_templateObject7(), pxToCssFont(24, 28), breakpoints.desktop, pxToCssFont(24, 40), pxToCssFont(42, 52), pxToCssFont(24, 30));
export var Description = styled.div.withConfig({
    componentId: "sc-d6495ddb-8"
})(_templateObject8(), pxToCssFont(16, 22), colors.$262626, breakpoints.desktop);
export var Spacer = styled.div.withConfig({
    componentId: "sc-d6495ddb-9"
})(_templateObject9(), breakpoints.desktop);
