import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useVolaUtils } from "@vfit/business/data-access";
import { AuthenticationContext, TrackingPageContext } from "@vfit/business/providers";
import { iFrameManager } from "@vfit/shared/providers";
import { manageErrorLeadPlatform, manageSuccessLeadPlatform, openLeadModal } from "@vfit/shared/data-access";
import { IActionType } from "@vfit/shared/models";
import { useRouter } from "next/router";
import { useContext } from "react";
import { useQueryClient } from "react-query";
export var useWidgetsAction = function() {
    var ref = useRouter(), push = ref.push, back = ref.back;
    var ref1 = useVolaUtils(), formatUrlVola = ref1.formatUrlVola, openPopupVola = ref1.openPopupVola, formatDynamicVolaUrl = ref1.formatDynamicVolaUrl;
    var queryClient = useQueryClient();
    var ref2 = useContext(TrackingPageContext), descriptionVolaUrl = ref2.descriptionVolaUrl, link = ref2.link, currentDataLayer = ref2.currentDataLayer;
    var authType = useContext(AuthenticationContext).authType;
    var activeLayer = {
        event_name: []
    };
    var handleClick = function(param, setActions, volaDesc, product) {
        var type = (param === null || param === void 0 ? void 0 : param.type) ? +param.type : 0;
        switch(type){
            case IActionType.CUSTOM_URL:
                if (param === null || param === void 0 ? void 0 : param.url) window.open(param === null || param === void 0 ? void 0 : param.url, (param === null || param === void 0 ? void 0 : param.isBlank) === "true" ? "_blank" : "_self");
                break;
            case IActionType.LOCAL_URL:
                if (param === null || param === void 0 ? void 0 : param.url) push(param === null || param === void 0 ? void 0 : param.url);
                break;
            case IActionType.RETRY:
                back();
                break;
            case IActionType.WHATSAPP_URL:
                var ref;
                setActions === null || setActions === void 0 ? void 0 : (ref = setActions.whatsapp) === null || ref === void 0 ? void 0 : ref.call(setActions, true);
                break;
            case IActionType.COVERAGE_TOOL:
                var ref1;
                setActions === null || setActions === void 0 ? void 0 : (ref1 = setActions.coverage) === null || ref1 === void 0 ? void 0 : ref1.call(setActions, true);
                break;
            case IActionType.CALL_ME_NOW:
                if (param === null || param === void 0 ? void 0 : param.url) {
                    if (descriptionVolaUrl && volaDesc) openPopupVola(formatDynamicVolaUrl(param === null || param === void 0 ? void 0 : param.url, volaDesc), "vola-ctc");
                    else openPopupVola(formatUrlVola(param === null || param === void 0 ? void 0 : param.url, product), "vola-ctc");
                }
                break;
            case IActionType.IFRAME_URL:
                if (!(param === null || param === void 0 ? void 0 : param.url)) return;
                // add tracking
                Object.keys(currentDataLayer).forEach(function(key) {
                    if (currentDataLayer[key].event_name.length > 0) activeLayer = currentDataLayer[key];
                });
                link(_object_spread_props(_object_spread({}, activeLayer), {
                    link_name: "".concat(activeLayer.page_name, ":CTC")
                }));
                iFrameManager.handleIFrame({
                    url: param === null || param === void 0 ? void 0 : param.url,
                    id: "custom_iframe"
                });
                break;
            case IActionType.LEAD_MODAL:
                if (queryClient) {
                    var allLeads = queryClient === null || queryClient === void 0 ? void 0 : queryClient.getQueryData("getAllLeads");
                    // form name
                    var ref2 = (param === null || param === void 0 ? void 0 : param.url) || "";
                    var forms = allLeads === null || allLeads === void 0 ? void 0 : allLeads[ref2].forms;
                    if (forms) {
                        openLeadModal({
                            form: forms,
                            onSuccess: function(submitOutput) {
                                return manageSuccessLeadPlatform(submitOutput, push, queryClient);
                            },
                            onError: function(submitOutput, errorCmsApi) {
                                return manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient);
                            },
                            trackingOption: {
                                visitor_type: authType
                            }
                        });
                    }
                }
                break;
            default:
                break;
        }
    };
    return {
        handleClick: handleClick
    };
};
