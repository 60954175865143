import { useGetProductEcommerceCatalog } from "@vfit/business/data-access";
import { useContext } from "react";
import { HubPostCoverageContext } from "@vfit/business/providers";
import { formatProducts } from "./widgetSliderProductsHub.utils";
var useWidgetSliderProductHub = function(widget, placeholders) {
    var elements = widget.elements;
    var ref = useContext(HubPostCoverageContext), isHubPostCoverage = ref.isHubPostCoverage, hubPostCoverageElements = ref.hubPostCoverageElements;
    var currentElements = isHubPostCoverage && hubPostCoverageElements.length > 0 ? hubPostCoverageElements : elements;
    var catalog = useGetProductEcommerceCatalog(currentElements).catalog;
    return {
        title: widget.titleHtml || "",
        products: formatProducts(currentElements, placeholders, catalog)
    };
};
export { useWidgetSliderProductHub };
