import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-business-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { ErrorAction, errorManager, ErrorSeverity, PageLoadingContext } from "@vfit/business/providers";
import { PAGES } from "@vfit/shared/data-access";
import { handleUseQuery } from "@vfit/shared/providers";
import { useContext, useEffect, useRef, useState } from "react";
import { awsAvailableTimeSlots } from "../../api/availableTimeSlots";
var useAvailableTimeSlots = function(token, isOperator) {
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var ref = useState(false), reload = ref[0], setReload = ref[1];
    var getAllSlotTime = {
        queryKey: "awsAvailableTimeSlots",
        queryFn: function() {
            return awsAvailableTimeSlots({
                token: token
            });
        },
        options: {
            onSuccess: function() {
                setIsLoading(false);
                setReload(false);
            },
            notifyOnChangeProps: [
                "data",
                "isFetching"
            ],
            onError: function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(error) {
                    var resp, ref, errorPage, message;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                _ctx.next = 2;
                                return error.response.json().catch(function() {
                                    return {
                                        code: "generic-error"
                                    };
                                });
                            case 2:
                                resp = _ctx.sent;
                                ref = getErrorPage(resp === null || resp === void 0 ? void 0 : resp.code, isOperator), errorPage = ref.errorPage, message = ref.message;
                                errorManager.handleError(ErrorSeverity.HIGH, {
                                    errorAction: ErrorAction.PAGE,
                                    errorPage: errorPage,
                                    message: message
                                });
                            case 5:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(error) {
                    return _ref.apply(this, arguments);
                };
            }(),
            refetchInterval: 600000
        }
    };
    var ref1 = handleUseQuery(getAllSlotTime), data = ref1.data, isFetching = ref1.isFetching;
    var timeoutRef = useRef();
    useEffect(function() {
        if (data === null || data === void 0 ? void 0 : data.expiredTimestamp) {
            clearTimeout(timeoutRef.current);
            var timeUntilExpiration = new Date(Number(data === null || data === void 0 ? void 0 : data.expiredTimestamp)).getTime() - Date.now();
            timeoutRef.current = setTimeout(function() {
                errorManager.handleError(ErrorSeverity.HIGH, {
                    errorAction: ErrorAction.PAGE,
                    errorPage: isOperator ? PAGES.TIME_EXPIRED_OPERATOR_ERROR : PAGES.TIME_EXPIRED_ERROR,
                    message: "time-expired-error"
                });
            }, timeUntilExpiration);
        }
        return function() {
            clearTimeout(timeoutRef.current);
        };
    }, [
        data === null || data === void 0 ? void 0 : data.expiredTimestamp
    ]);
    useEffect(function() {
        if (isFetching) {
            setReload(true);
        }
    }, [
        isFetching
    ]);
    return {
        data: data,
        reload: reload
    };
};
var getErrorPage = function(error, isOperator) {
    var ref;
    var code = (ref = error === null || error === void 0 ? void 0 : error.split(":")) === null || ref === void 0 ? void 0 : ref.pop();
    switch(code){
        case "slot-not-available":
            return {
                errorPage: isOperator ? PAGES.NO_AVAILABILITY_OPERATOR_ERROR : PAGES.NO_AVAILABILITY_ERROR,
                message: "no-availability-error"
            };
        case "time-expired":
            return {
                errorPage: isOperator ? PAGES.TIME_EXPIRED_OPERATOR_ERROR : PAGES.TIME_EXPIRED_ERROR,
                message: "time-expired-error"
            };
        default:
            return {
                errorPage: PAGES.GENERIC_ERROR,
                message: "generic-error"
            };
    }
};
export { useAvailableTimeSlots };
