import { useEffect, useState } from "react";
import { getEcommerceCatalog, formatDxlCosts } from "@vfit/business/data-access";
import { errorManager, ErrorAction, ErrorSeverity, useHandleQueriesWithAuthSSO } from "@vfit/business/providers";
import { PAGES } from "@vfit/shared/data-access";
var useGetProductEcommerceCatalogAssociated = function(elements) {
    var ref = useState(true), isLoadingEcomm = ref[0], setIsLoadingEcomm = ref[1];
    var queries = [];
    elements.forEach(function(item) {
        queries.push({
            queryKey: [
                item.dxlId,
                "ecommerceCatalogCoreOfferAssociated"
            ],
            queryFn: function() {
                return getEcommerceCatalog({
                    productId: item.dxlId,
                    productType: item.productType
                });
            },
            options: {
                onSuccess: function(data) {
                    return formatDxlCosts(data);
                },
                onError: function(error) {
                    var status = error.status;
                    var statusNumber = Number(status);
                    errorManager.handleError(ErrorSeverity.HIGH, {
                        errorAction: ErrorAction.PAGE,
                        errorCode: statusNumber
                    });
                }
            }
        });
    });
    var queriesResults = useHandleQueriesWithAuthSSO(queries);
    useEffect(function() {
        if (Object.values(queriesResults).some(function(item) {
            return item.status !== "success";
        })) return;
        if (elements.length === Object.values(queriesResults).length && Object.values(queriesResults).every(function(item) {
            return item.status === "success";
        }) && Object.values(queriesResults).every(function(item) {
            var ref;
            return ((ref = item.data) === null || ref === void 0 ? void 0 : ref.productInfo.length) === 0;
        })) {
            errorManager.handleError(ErrorSeverity.HIGH, {
                errorAction: ErrorAction.PAGE,
                errorPage: PAGES.OFFER_ERROR
            });
        } else {
            setIsLoadingEcomm(false);
        }
    }, [
        queriesResults
    ]);
    var catalog = Object.values(queriesResults).map(function(query) {
        var ref;
        return (ref = query.data) === null || ref === void 0 ? void 0 : ref.productInfo[0];
    }).filter(Boolean);
    return {
        catalog: catalog,
        isLoadingEcomm: isLoadingEcomm
    };
};
export { useGetProductEcommerceCatalogAssociated };
