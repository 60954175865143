import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import CoverageToolAutoCard from "./components/CoverageToolAutoCard/coverageToolAutoCard";
import CoverageToolManualCard from "./components/CoverageToolManualCard/coverageToolManualCard";
import CoverageToolMapCard from "./components/CoverageToolMapCard/coverageToolMapCard";
import CoverageToolListAddressCard from "./components/CoverageToolListAddressCard/coverageToolListAddressCard";
export var ID_FLOWS = {
    AUTO: "auto",
    MANUAL: "Manual",
    MAP: "map",
    CHECK_USER: "checkuser",
    OTP: "otp",
    LIST_ADDRESS: "listaddress"
};
export var AUTO_FLOW = {
    AUTO_CARD: [
        {
            title: ID_FLOWS.AUTO,
            component: CoverageToolAutoCard
        }, 
    ]
};
export var LIST_ADDRESS_FLOW = {
    LIST_CARD: [
        {
            title: ID_FLOWS.LIST_ADDRESS,
            component: CoverageToolListAddressCard
        }, 
    ]
};
export var MANUAL_FLOW = {
    MANUAL_CARD: [
        {
            title: ID_FLOWS.MANUAL,
            component: CoverageToolManualCard
        }, 
    ]
};
export var MAP_FLOW = {
    MAP_CARD: [
        {
            title: ID_FLOWS.MAP,
            component: CoverageToolMapCard
        }, 
    ]
};
export var EMPTY_FLOWS = {
    EMPTY: [
        {
            title: "",
            component: undefined
        },
        {
            title: "",
            component: undefined
        }, 
    ]
};
var COVERAGE_TOOL_FLOW = {
    DEFAULT: _to_consumable_array(AUTO_FLOW.AUTO_CARD).concat(_to_consumable_array(MAP_FLOW.MAP_CARD))
};
export { COVERAGE_TOOL_FLOW };
