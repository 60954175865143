import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 24px;\n  border-radius: 20px;\n  border: 1px solid ",
        ";\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: space-between;\n\n  @media (min-width: ",
        ") {\n    margin: 32px;\n    flex-direction: row;\n   \n  }\n  @media (min-width: ",
        ") {\n    margin: 40px 80px 80px;\n    flex-direction: row;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  flex: 1 1 100%;\n  :last-child {\n    border-right: none;\n  }\n\n  text-align: center;\n  h3 {\n    text-transform: uppercase;\n    color: ",
        ";\n    ",
        ";\n  }\n  h1 {\n    font-family: ",
        ";\n    ",
        ";\n    color: ",
        ";\n  }\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    flex: 1 1 45%;\n  }\n  @media (min-width: ",
        ") {\n    flex: 1 1 auto;\n    border-right: ",
        " solid ",
        ";\n    padding: 20px;\n    margin: 30px auto;\n    h3 {\n      ",
        ";\n    }\n    h1 {\n      ",
        ";\n    }\n  \n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ColumnsContainer = styled.div.withConfig({
    componentId: "sc-506fbbf1-0"
})(_templateObject(), colors.$bebebe, breakpoints.tablet, breakpoints.bigDesktop);
export var ColumnItem = styled.div.withConfig({
    componentId: "sc-506fbbf1-1"
})(_templateObject1(), colors.$e60000, pxToCssFont(20, 28), fonts.exbold, pxToCssFont(28, 36), colors.$262626, breakpoints.tablet, breakpoints.bigDesktop, function(props) {
    return props.border ? "1px" : "0";
}, colors.$bebebe, pxToCssFont(22, 32), pxToCssFont(36, 48));
