import { useGetEcommerceCatalogDevices } from "@vfit/business/data-access";
import { formatData } from "./widgetHubDevice.utils";
var useWidgetHubDevice = function(widget) {
    var product = useGetEcommerceCatalogDevices({
        limit: widget.numberCards,
        category: widget.productType
    }).data;
    return {
        products: product && formatData(widget, product.deviceInfo)
    };
};
export default useWidgetHubDevice;
