import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-flow: ",
        " wrap;\n  justify-content: center;\n  width: 100%;\n  background-color: ",
        ";\n  top: ",
        ";\n  left: 0;\n  z-index: 2;\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  text-align: center;\n\n  button[aria-selected='true'] {\n    font-weight: 700;\n    position: relative;\n  }\n\n  button[aria-selected]::after {\n    display: block;\n    position: absolute;\n    transition: 0.3s;\n    transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);\n    opacity: 0;\n    content: '';\n    bottom: -1px;\n    height: 4px;\n    width: 100%;\n    left: 0;\n    z-index: 2;\n  }\n\n  button[aria-selected='true']::after {\n    background-color: ",
        ";\n    opacity: 1;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  cursor: pointer;\n  position: relative;\n  ",
        "\n  text-align: center;\n  padding: ",
        ";\n  min-width: ",
        ";\n  height: 50px;\n  z-index: 2;\n  border: none;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  :focus-visible {\n    outline-color: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 100%;\n  border-top: 1px solid ",
        ";\n  overflow-y: auto;\n\n  :focus-visible {\n    outline-color: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, colors, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-a1d6ed38-0"
})(_templateObject());
export var TabDiv = styled.div.withConfig({
    componentId: "sc-a1d6ed38-1"
})(_templateObject1(), function(param) {
    var mobileFlexDir = param.mobileFlexDir;
    return mobileFlexDir;
}, colors.$ffffff, pxToRem(80), breakpoints.tablet);
export var H3 = styled.h3.withConfig({
    componentId: "sc-a1d6ed38-2"
})(_templateObject2(), colors.$e60000);
export var Button = styled.button.withConfig({
    componentId: "sc-a1d6ed38-3"
})(_templateObject3(), pxToCssFont(16, 20), pxToRem(12, 12, 8, 12), pxToRem(152), breakpoints.tablet, pxToCssFont(18, 26), colors.$bebebe);
export var Content = styled.div.withConfig({
    componentId: "sc-a1d6ed38-4"
})(_templateObject4(), colors.$bebebe, colors.$bebebe);
