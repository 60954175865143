import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useGetEcommerceCatalogDeviceInfo, useGetProductEcommerceCatalogAssociated } from "@vfit/business/data-access";
import { useContext, useEffect, useState } from "react";
import { ErrorAction, ErrorSeverity, PageLoadingContext, errorManager } from "@vfit/business/providers";
export var useWidgetDeviceHeroBanner = function(widget, product) {
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var ref = useState({}), offerCombination = ref[0], setOfferCombination = ref[1];
    var ref1 = useGetEcommerceCatalogDeviceInfo({
        deviceId: widget.product.dxlId
    }), isLoadingDeviceInfo = ref1.isLoadingDeviceInfo, data = ref1.data;
    var ref2 = useGetProductEcommerceCatalogAssociated(product), isLoadingEcomm = ref2.isLoadingEcomm, catalog = ref2.catalog;
    useEffect(function() {
        if (!isLoadingEcomm && !isLoadingDeviceInfo) {
            var obj = offerCombination;
            catalog === null || catalog === void 0 ? void 0 : catalog.forEach(function(el) {
                var ref, ref1, ref2;
                var arrOfres = (data === null || data === void 0 ? void 0 : (ref = data.deviceInfo) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.resource) === null || ref2 === void 0 ? void 0 : ref2.filter(function(r) {
                    var ref;
                    return r === null || r === void 0 ? void 0 : (ref = r.product) === null || ref === void 0 ? void 0 : ref.find(function(e) {
                        return (e === null || e === void 0 ? void 0 : e.productSpecificationId) === (el === null || el === void 0 ? void 0 : el.productOfferingId);
                    });
                })) || [];
                if (el.productSpecificationId && (arrOfres === null || arrOfres === void 0 ? void 0 : arrOfres.length) > 0) {
                    var newArr = [];
                    arrOfres === null || arrOfres === void 0 ? void 0 : arrOfres.map(function(a) {
                        return newArr.push(_object_spread_props(_object_spread({}, a), {
                            product: [
                                el
                            ]
                        }));
                    });
                    obj[el.productSpecificationId] = newArr;
                }
            });
            if (Object.keys(obj).length === 0) {
                errorManager.handleError(ErrorSeverity.HIGH, {
                    errorAction: ErrorAction.PAGE,
                    errorCode: 404
                });
            } else {
                setOfferCombination(obj);
                setIsLoading(false);
            }
        }
    }, [
        isLoadingEcomm,
        isLoadingDeviceInfo
    ]);
    return {
        device: data,
        offerCombination: offerCombination
    };
};
