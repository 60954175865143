import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  // max-width: 1440px;\n  margin: 0 auto;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
var PageLayout = styled.div.withConfig({
    componentId: "sc-8b94d544-0"
})(_templateObject());
export { PageLayout };
