import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 32px;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n\n  @media (min-width: ",
        ") {\n    margin: 0 80px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: 32px;\n  div {\n    margin: 0;\n    p {\n      margin: 0;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 48px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 0;\n  gap: 33px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    grid-template-columns: repeat(auto-fill, 328px);\n    display: grid;\n    grid-column-gap: 33px;\n    grid-row-gap: 33px;\n    grid-auto-rows: 1fr;\n    align-items: flex-start;\n  }\n\n  @media (min-width: ",
        ") {\n    grid-template-columns: repeat(auto-fill, 404px);\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  > div {\n    margin: 0;\n  }\n  img {\n    object-fit: cover !important;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 0 auto;\n\n  p {\n    text-align: center;\n    font-family: ",
        ";\n    font-size: 40px;\n    font-weight: 400;\n    line-height: 48px;\n    margin: 0 0 32px 0;\n  }\n\n  @media (min-width: ",
        ") {\n    p {\n      margin: 0 0 48px 0;\n    }\n  }\n\n  div {\n    display: flex;\n    width: 216px;\n    justify-items: center;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, fonts } from "@vfit/shared/themes";
import styled from "styled-components";
export var DigitalSolutionsContainer = styled.div.withConfig({
    componentId: "sc-4f52fddd-0"
})(_templateObject(), breakpoints.desktop);
export var TopText = styled.div.withConfig({
    componentId: "sc-4f52fddd-1"
})(_templateObject1(), breakpoints.desktop);
export var CardsListContainer = styled.div.withConfig({
    componentId: "sc-4f52fddd-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var CardContainer = styled.div.withConfig({
    componentId: "sc-4f52fddd-3"
})(_templateObject3());
export var ProductNotAvailable = styled.div.withConfig({
    componentId: "sc-4f52fddd-4"
})(_templateObject4(), fonts.light, breakpoints.desktop);
