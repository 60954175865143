/* stylelint-disable no-descending-specificity */ import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex: 1;\n  border-radius: 20px 20px 0 0;\n  flex-direction: column;\n  margin-left: ",
        ";\n  position: ",
        ";\n  bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-left: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  z-index: 1;\n  flex-direction: column;\n  justify-content: space-between;\n  min-height: 150px;\n  max-height: 240px;\n  position: relative;\n  border-radius: 20px 20px 0 0;\n  padding-left: 24px;\n  padding-right: 24px;\n  transition: transform 0.9s ease-in-out;\n  background-repeat: no-repeat !important;\n  background-size: cover !important;\n  background-position: center !important;\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n    max-height: ",
        ";\n    padding-left: 24px;\n    padding-right: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n    max-height: ",
        ";\n    padding-left: 36px;\n    padding-right: 36px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  z-index: 2;\n  background: ",
        ";\n  border: 1px solid #ccc;\n  border-radius: 0 0 20px 20px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-left: 24px;\n  margin-right: 24px;\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    margin-left: 24px;\n    margin-right: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 32px;\n    margin-right: 32px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  text-transform: uppercase;\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 700;\n  ",
        "\n  color: ",
        ";\n  margin: 0;\n  margin-bottom: 4px;\n  margin-top: 24px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 4px;\n    margin-top: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 7px;\n    margin-top: 33px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n  margin: 0;\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  align-items: flex-end;\n\n  h2 {\n    text-transform: uppercase;\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 700;\n    ",
        "\n    color: ",
        ";\n    margin: 0;\n    margin-bottom: 4px;\n    margin-top: 24px;\n  }\n\n  h3 {\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n    margin: 0;\n    margin-bottom: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    h3 {\n      ",
        "\n    }\n\n    h2 {\n      ",
        "\n      margin-bottom: 4px;\n      margin-top: 24px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    h3 {\n      ",
        "\n    }\n\n    h2 {\n      ",
        "\n      margin-bottom: 7px;\n      margin-top: 33px;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  border-radius: 15px 15px 0 0;\n  position: ",
        ";\n  top: ",
        ";\n  left: ",
        ";\n\n  img {\n    transition: transform 0.9s ease-in-out;\n  }\n\n  &:hover {\n    img {\n      transform: inherit;\n    }\n  }\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    display: inline-block;\n    background: linear-gradient(1.14deg, rgba(0, 0, 0, 40%) 11.28%, rgba(0, 0, 0, 0%) 75.72%);\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: baseline;\n  margin: 0;\n  margin-bottom: 8px;\n\n  svg {\n    margin-right: 16px;\n    width: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  margin-bottom: 32px;\n\n  h6 {\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    color: ",
        ";\n    margin: 0;\n  }\n\n  .productTitle div {\n    margin: 0;\n    color: ",
        ";\n    font-weight: 400;\n    display: flex;\n    align-items: baseline;\n\n    .price {\n      ",
        "\n\n      @media (min-width: ",
        ") {\n        white-space: nowrap;\n        ",
        "\n\n        b:first-of-type {\n          ",
        "\n        }\n      }\n    }\n\n    .rec {\n      ",
        "\n\n      @media (min-width: ",
        ") {\n        ",
        "\n      }\n    }\n  }\n\n  h4 {\n    margin: 0;\n    margin-bottom: 10px;\n    font-family: ",
        " !important;\n    color: ",
        " !important;\n    font-weight: 700 !important;\n    font-size: ",
        " !important;\n    line-height: ",
        " !important;\n  }\n\n  .productDescription div {\n    margin: 0;\n    color: ",
        ";\n  }\n\n  .additionalOfferDetails {\n    margin-top: 8px;\n    min-height: 32px;\n    p {\n      font-weight: 400;\n      ",
        "\n      line-height: ",
        "\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    .productTitle div {\n      ",
        "\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h6 {\n      ",
        "\n    }\n\n    .productDescription div {\n      ",
        "\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    .productTitle div {\n      ",
        "\n    }\n\n    h4 {\n      ",
        "\n      font-family: ",
        ";\n    }\n\n    .productDescription div {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  align-items: flex-start;\n  justify-content: space-between;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  color: ",
        ";\n  margin: 0;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-left: 16px;\n  border: none;\n  padding: 0;\n  font: inherit;\n  cursor: pointer;\n  outline: inherit;\n  background: ",
        ";\n  border-radius: 100px;\n  color: white;\n  width: 26px;\n  height: 26px;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  ",
        "\n  font-family: ",
        ";\n\n  &:hover {\n    background: ",
        ";\n  }\n\n  &:active {\n    background: ",
        ";\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-radius: 20px;\n  height: 73px;\n\n  span {\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 700;\n    ",
        "\n    letter-spacing: 0.5px;\n    text-transform: uppercase;\n    color: ",
        ";\n    padding-top: 10px;\n    margin-left: 36px;\n    display: flex;\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  div {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n    margin: 0;\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize, pxToFontSize, pxToLineHeight } from "@vfit/shared/themes";
export var ProductCardContainer = styled.div.withConfig({
    componentId: "sc-3b737059-0"
})(_templateObject(), function(param) {
    var noMargin = param.noMargin;
    return noMargin ? "0" : "20px";
}, function(param) {
    var haveOfferLabel = param.haveOfferLabel;
    return haveOfferLabel ? "relative" : "inherit";
}, function(param) {
    var haveOfferLabel = param.haveOfferLabel;
    return haveOfferLabel ? "40px" : "inherit";
}, breakpoints.tablet, breakpoints.desktop);
export var ProductCardTopImage = styled.div.withConfig({
    componentId: "sc-3b737059-1"
})(_templateObject1(), breakpoints.tablet, function(param) {
    var large = param.large;
    return large ? "300px" : "170px";
}, function(param) {
    var large = param.large;
    return large ? "300px" : "240px";
}, breakpoints.desktop, function(param) {
    var large = param.large;
    return large ? "300px" : "240px";
}, function(param) {
    var large = param.large;
    return large ? "300px" : "240px";
});
export var ProductCardBottom = styled.div.withConfig({
    componentId: "sc-3b737059-2"
})(_templateObject2(), colors.$ffffff);
export var ProductCardBottomContainer = styled.div.withConfig({
    componentId: "sc-3b737059-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var H2Header = styled.div.withConfig({
    componentId: "sc-3b737059-4"
})(_templateObject4(), fonts.regular, pxToCssFontSize(14), colors.$ffffff, breakpoints.tablet, pxToCssFontSize(14), breakpoints.desktop, pxToCssFontSize(18));
export var H3Header = styled.div.withConfig({
    componentId: "sc-3b737059-5"
})(_templateObject5(), fonts.exbold, pxToCssFontSize(30), colors.$ffffff, breakpoints.tablet, pxToCssFontSize(30), breakpoints.desktop, pxToCssFontSize(42));
export var ProductCardTopImageContent = styled.div.withConfig({
    componentId: "sc-3b737059-6"
})(_templateObject6(), fonts.regular, pxToCssFontSize(14), colors.$ffffff, fonts.exbold, pxToCssFontSize(30), colors.$ffffff, breakpoints.tablet, pxToCssFontSize(30), pxToCssFontSize(14), breakpoints.desktop, pxToCssFontSize(42), pxToCssFontSize(18));
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-3b737059-7"
})(_templateObject7(), function(props) {
    return props.absolute ? "absolute" : "relative";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(props) {
    return props.absolute ? 0 : "";
});
export var ProductCardSpecItem = styled.div.withConfig({
    componentId: "sc-3b737059-8"
})(_templateObject8(), breakpoints.tablet, breakpoints.desktop);
export var ProductCardBottomContainerText = styled.div.withConfig({
    componentId: "sc-3b737059-9"
})(_templateObject9(), fonts.regular, pxToCssFont(16, 22), colors.$262626, colors.$262626, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(24, 40), pxToCssFont(42, 52), pxToCssFont(24, 28), breakpoints.desktop, pxToCssFont(36, 45), fonts.regular, colors.$7e7e7e, pxToFontSize(18), pxToLineHeight(18, 24), colors.$0d0d0d, pxToCssFontSize(14), pxToLineHeight(14, 18), breakpoints.tablet, pxToCssFontSize(24), pxToCssFontSize(16), pxToCssFontSize(18), pxToCssFontSize(14), breakpoints.desktop, pxToCssFontSize(36), pxToCssFontSize(20), fonts.light, pxToCssFontSize(18));
export var ProductCardSpecContainer = styled.div.withConfig({
    componentId: "sc-3b737059-10"
})(_templateObject10());
export var ProductCardSpecTitle = styled.p.withConfig({
    componentId: "sc-3b737059-11"
})(_templateObject11(), fonts.regular, pxToCssFontSize(20), colors.$262626);
export var ProductCardSpecItemAction = styled.button.withConfig({
    componentId: "sc-3b737059-12"
})(_templateObject12(), colors.$e60000, pxToCssFontSize(26), fonts.light, colors.$bd0000, colors.$a10000);
export var ProductCardOfferLabel = styled.div.withConfig({
    componentId: "sc-3b737059-13"
})(_templateObject13(), function(param) {
    var haveLabel = param.haveLabel;
    return haveLabel ? "#00697C" : "transparent";
}, fonts.regular, pxToCssFont(12, 16), colors.$ffffff);
export var ProductCardSpecText = styled.div.withConfig({
    componentId: "sc-3b737059-14"
})(_templateObject14(), fonts.regular, pxToCssFontSize(20), colors.$262626);
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-3b737059-15"
})(_templateObject15(), pxToCssFont(18, 16), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalContent = styled.div.withConfig({
    componentId: "sc-3b737059-16"
})(_templateObject16());
