import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useGetEcommerceCatalogDigitalSolutions } from "@vfit/business/data-access";
import { formatDigitalSolutions } from "./widgetHubDigitalSolutions.utils";
export var useWidgetHubDigitalSolutions = function(widget) {
    var product = useGetEcommerceCatalogDigitalSolutions({
        limit: widget.numberCards,
        category: widget.productType
    }).data;
    var digitalSolutions = product && formatDigitalSolutions(widget, product.businessSolutionInfo);
    var tabsToShow = widget.tags.tagsLabel.filter(function(tag) {
        return tag.isHidden !== "true";
    }).filter(function(tag) {
        return digitalSolutions === null || digitalSolutions === void 0 ? void 0 : digitalSolutions.find(function(ds) {
            return ds.tag === tag.label;
        });
    }).map(function(tag) {
        return tag.label;
    });
    return {
        title: widget.title,
        tabs: [
            widget.tags.allTagsLabel
        ].concat(_to_consumable_array(tabsToShow)),
        digitalSolutions: digitalSolutions,
        productNotAvailable: widget.productNotAvailable
    };
};
