import { ICMSWidgetType } from "@vfit/business/data-access";
import { useDeviceType } from "@vfit/shared/hooks";
import { getDefaultSlider, getLargeSmallSlider } from "./widgetLargeSmallSlider.utils";
var useWidgetLargeSmallSlider = function(widget) {
    var ref = useDeviceType(), isDesktop = ref.isDesktop, isMobile = ref.isMobile;
    var containerStyle = {
        paddingTop: isMobile ? 32 : 48
    };
    var getSlider = function() {
        switch(widget.type){
            case ICMSWidgetType.SLIDER_LARGE_SMALL:
                return getLargeSmallSlider(widget);
            default:
                return getDefaultSlider(widget);
        }
    };
    var getOtherProps = function() {
        switch(widget.type){
            case ICMSWidgetType.SLIDER_LARGE_SMALL:
                return {
                    isCovered: true
                };
            default:
                // DEFAULT_SLIDER
                return {
                    isCovered: false
                };
        }
    };
    return {
        sliderCarousel: getSlider(),
        otherProps: getOtherProps(),
        divider: widget.divider,
        disclaimer: widget.disclaimer,
        forceTextTop: isDesktop,
        containerStyle: containerStyle,
        isMobile: isMobile
    };
};
export { useWidgetLargeSmallSlider };
