import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-business-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
var ref, ref1;
import { checkWindow, checkJWTsValidity } from "@vfit/shared/data-access";
import { handleEnv, LoggerInstance, DxlError, delay } from "@vfit/shared/providers";
import { NextError } from "libs/shared/providers/src/lib/fetcher/utils";
import { HTTPError } from "ky";
var defaultConfig = {
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
        clientId: "9003",
        keysite: handleEnv("NX_KEYSITE") || ""
    },
    cache: "no-cache",
    hooks: {
        afterResponse: [
            function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(_request, _options, response) {
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (!(handleEnv("NODE_ENV") === "LOCAL" && checkWindow())) {
                                    _ctx.next = 3;
                                    break;
                                }
                                _ctx.next = 3;
                                return delay(1000);
                            case 3:
                                if (response.ok) {
                                    _ctx.next = 5;
                                    break;
                                }
                                throw new NextError(response, _request).getError();
                            case 5:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(_request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    },
    prefixUrl: handleEnv("NX_DXL_VBI_PREFIX") || "",
    retry: {
        limit: 3,
        methods: [
            "get"
        ]
    },
    timeout: 30000
};
export var DXL_WITHOUT_JWT = _object_spread_props(_object_spread({}, defaultConfig), {
    headers: _object_spread_props(_object_spread({}, defaultConfig.headers), {
        transactionId: Date.now().toString()
    })
});
export var DXL = _object_spread_props(_object_spread({}, defaultConfig), {
    hooks: _object_spread_props(_object_spread({}, defaultConfig.hooks), {
        beforeRequest: _to_consumable_array(((ref = defaultConfig.hooks) === null || ref === void 0 ? void 0 : ref.beforeRequest) || []).concat([
            function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(request) {
                    var token;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (checkWindow()) {
                                    _ctx.next = 2;
                                    break;
                                }
                                return _ctx.abrupt("return");
                            case 2:
                                token = checkJWTsValidity([
                                    "dxlAuthTokenCB",
                                    "dxlAuthToken"
                                ]);
                                if (!token) {
                                    _ctx.next = 7;
                                    break;
                                }
                                request.headers.set("X-Auth-Token", token);
                                _ctx.next = 9;
                                break;
                            case 7:
                                LoggerInstance.error("Invalid JWT");
                                throw new DxlError("500|_|'AUTH_EXPIRED'|_|'La sessione \xe8 scaduta, ricarica la pagina'");
                            case 9:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(request) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ])
    })
});
export var DXL_CLOUD = {
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*"
    },
    cache: "no-cache",
    prefixUrl: handleEnv("NX_DXL_VBI_CLOUD_PREFIX") || "",
    hooks: {
        afterResponse: [
            function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(_request, _options, response) {
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (response.ok) {
                                    _ctx.next = 2;
                                    break;
                                }
                                throw new HTTPError(response, _request, _options);
                            case 2:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(_request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    },
    retry: 0,
    timeout: 30000
};
export var DXL_CLOUD_WITH_JWT = _object_spread_props(_object_spread({}, DXL_CLOUD), {
    hooks: _object_spread_props(_object_spread({}, DXL_CLOUD.hooks), {
        beforeRequest: _to_consumable_array(((ref1 = defaultConfig.hooks) === null || ref1 === void 0 ? void 0 : ref1.beforeRequest) || []).concat([
            function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(request) {
                    var token;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (checkWindow()) {
                                    _ctx.next = 2;
                                    break;
                                }
                                return _ctx.abrupt("return");
                            case 2:
                                token = checkJWTsValidity([
                                    "dxlAuthTokenCB",
                                    "dxlAuthToken"
                                ]);
                                if (!token) {
                                    _ctx.next = 7;
                                    break;
                                }
                                request.headers.set("X-Auth-Token", token);
                                _ctx.next = 9;
                                break;
                            case 7:
                                LoggerInstance.error("Invalid JWT");
                                throw new DxlError("500|_|'AUTH_EXPIRED'|_|'La sessione \xe8 scaduta, ricarica la pagina'");
                            case 9:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(request) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ])
    })
});
