export var ErrorSeverity;
(function(ErrorSeverity) {
    ErrorSeverity["LOW"] = "low";
    ErrorSeverity["MEDIUM"] = "medium";
    ErrorSeverity["HIGH"] = "high";
})(ErrorSeverity || (ErrorSeverity = {}));
export var ErrorType;
(function(ErrorType) {
    ErrorType["AUTHENTICATION"] = "authentication";
})(ErrorType || (ErrorType = {}));
export var ErrorAction/*

errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
  title: config.title || '',
  message: config.message || '',
  actionText: config.action.title || '',
  ...(config?.action?.type && {
    actionEvent: () => getButtonActionByActionType(config.action, push),
  }),
});

 */ ;
(function(ErrorAction) {
    ErrorAction["MODAL"] = "modal";
    ErrorAction["PAGE"] = "page";
})(ErrorAction || (ErrorAction = {}));
