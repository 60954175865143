import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin: 0 23px 50px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n\n  a {\n    color: ",
        " !important;\n\n    :visited {\n      color: ",
        " !important;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div p {\n    margin-top: 32px;\n    margin-bottom: 52px;\n  }\n\n  @media (min-width: ",
        ") {\n    div p {\n      margin-top: 80px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 324px;\n  margin: 30px auto;\n  div {\n    margin-bottom: 16px;\n\n    a {\n      color: ",
        " !important;\n\n      :visited {\n        color: ",
        " !important;\n      }\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  #sticky-offer-product {\n    a {\n      color: ",
        ";\n      font-weight: 700;\n    }\n\n    a:hover {\n      color: ",
        ";\n    }\n\n    a:visited {\n      color: ",
        ";\n    }\n\n    a:active {\n      color: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
import styled from "styled-components";
export var ButtonDesc = styled.div.withConfig({
    componentId: "sc-190af1a1-0"
})(_templateObject());
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-190af1a1-1"
})(_templateObject1(), fonts.regular, colors.$7e7e7e, colors.$262626, colors.$262626);
export var ContainerDescription = styled.div.withConfig({
    componentId: "sc-190af1a1-2"
})(_templateObject2(), breakpoints.desktop);
export var ActionContainer = styled.p.withConfig({
    componentId: "sc-190af1a1-3"
})(_templateObject3(), colors.$ffffff, colors.$ffffff);
export var StickyOfferContainerTopProduct = styled.div.withConfig({
    componentId: "sc-190af1a1-4"
})(_templateObject4(), colors.$262626, colors.$e60000, colors.$262626, colors.$262626);
