import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  margin-left: 20px;\n  margin-right: 20px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 64px;\n    margin-left: 40px;\n    margin-right: 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 60px;\n    margin-left: 54px;\n    margin-right: 54px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 32px;\n  margin-left: 20px;\n  margin-right: 20px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 64px;\n    margin-left: 40px;\n    margin-right: 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 60px;\n    margin-left: 54px;\n    margin-right: 54px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 32px;\n  right: 20px;\n  left: 20px;\n\n  @media (min-width: ",
        ") {\n    bottom: 64px;\n    left: 40px;\n    right: 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    bottom: 60px;\n    left: 54px;\n    right: 54px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        '\nheight: 300px;\noverflow-y: auto;\nmargin: 20px 20px 100px 20px;\ndisplay: flex;\nflex-direction: column;\ngap: 12px;\npadding: 12px;\n\n div {\n  input[type="radio"] {\n    display: none;\n    padding: 16px;\n  }\n  label {\n    padding: 20px 16px;\n    text-align: left;\n    font-family: ',
        ";\n    ",
        "\n  }\n }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFontSize } from "@vfit/shared/themes";
export var CoverageToolContainer = styled.div.withConfig({
    componentId: "sc-e2508bbb-0"
})(_templateObject());
export var CoverageToolContent = styled.div.withConfig({
    componentId: "sc-e2508bbb-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var CoverageToolContentButton = styled.div.withConfig({
    componentId: "sc-e2508bbb-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var CoverageToolContentButtonAuto = styled.div.withConfig({
    componentId: "sc-e2508bbb-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var WrapperBoxRadioBox = styled.div.withConfig({
    componentId: "sc-e2508bbb-4"
})(_templateObject4(), fonts.exbold, pxToCssFontSize(20));
