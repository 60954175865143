import { IActionType } from "@vfit/shared/models";
import { useContext, useState } from "react";
import { useRouter } from "next/router";
import { AuthenticationContext, TrackingPageContext } from "@vfit/business/providers";
import { useVolaUtils } from "@vfit/business/data-access";
import { useQueryClient } from "react-query";
import { manageErrorLeadPlatform, manageSuccessLeadPlatform, openLeadModal } from "@vfit/shared/data-access";
var useCardFunctionality = function() {
    var descriptionVolaUrl = useContext(TrackingPageContext).descriptionVolaUrl;
    var ref = useVolaUtils(), formatUrlVola = ref.formatUrlVola, openPopupVola = ref.openPopupVola, formatDynamicVolaUrl = ref.formatDynamicVolaUrl;
    var queryClient = useQueryClient();
    var authType = useContext(AuthenticationContext).authType;
    var ref1 = useState(false), isShowCoverageTool = ref1[0], setIsShowCoverageTool = ref1[1];
    var ref2 = useState(false), isShowWhatsapp = ref2[0], setIsShowWhatsapp = ref2[1];
    var ref3 = useState(""), showCheckout = ref3[0], setShowCheckout = ref3[1];
    var ref4 = useState({
        show: false
    }), modalDetails = ref4[0], setModalDetails = ref4[1];
    var ref5 = useState({
        onConfirmAddress: false,
        product: [],
        address: {}
    }), postCoverageData = ref5[0], setPostCoverageData = ref5[1];
    var push = useRouter().push;
    var handleClick = function(param, product, desc, version) {
        var catalog = queryClient.getQueryData([
            product === null || product === void 0 ? void 0 : product.offerId,
            "ecommerceCatalogCoreOffer", 
        ]);
        var address = JSON.parse(localStorage.getItem("valid_address") || "{}");
        var type = (param === null || param === void 0 ? void 0 : param.type) ? +param.type : 0;
        switch(type){
            case IActionType.CUSTOM_URL:
                if (param === null || param === void 0 ? void 0 : param.url) {
                    window.open(param === null || param === void 0 ? void 0 : param.url, (param === null || param === void 0 ? void 0 : param.isBlank) === "true" ? "_blank" : "_self");
                }
                break;
            case IActionType.LOCAL_URL:
                if (param === null || param === void 0 ? void 0 : param.url) push(param === null || param === void 0 ? void 0 : param.url);
                break;
            case IActionType.COVERAGE_TOOL:
                setIsShowCoverageTool(true);
                break;
            case IActionType.WHATSAPP_URL:
                setIsShowWhatsapp(true);
                break;
            case IActionType.CALL_ME_NOW:
                if (param.url) {
                    if (descriptionVolaUrl && desc) openPopupVola(formatDynamicVolaUrl(param === null || param === void 0 ? void 0 : param.url, desc), "vola-ctc");
                    else openPopupVola(formatUrlVola(param === null || param === void 0 ? void 0 : param.url, product), "vola-ctc");
                }
                break;
            case IActionType.CHECKOUT_MODAL_MOBILE:
                setShowCheckout((product === null || product === void 0 ? void 0 : product.offerId) || "");
                break;
            case IActionType.POPUP_DETAIL:
                setModalDetails({
                    show: true
                });
                break;
            case IActionType.POST_COVERAGE:
                var ref, ref1, ref2, ref3;
                queryClient.setQueryData("coverageToolProduct", product);
                setPostCoverageData({
                    onConfirmAddress: true,
                    product: [
                        {
                            productId: (product === null || product === void 0 ? void 0 : product.offerId) || "",
                            productType: catalog === null || catalog === void 0 ? void 0 : (ref = catalog.productInfo) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.productType,
                            productOfferingId: catalog === null || catalog === void 0 ? void 0 : (ref2 = catalog.productInfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.productOfferingId,
                            version: version
                        }, 
                    ],
                    address: {
                        addressCode: address === null || address === void 0 ? void 0 : address.addressCode,
                        streetName: address === null || address === void 0 ? void 0 : address.streetName,
                        streetNr: address === null || address === void 0 ? void 0 : address.streetNr,
                        city: address === null || address === void 0 ? void 0 : address.city,
                        stateOrProvince: address === null || address === void 0 ? void 0 : address.stateOrProvince,
                        postcode: address === null || address === void 0 ? void 0 : address.postcode
                    }
                });
                break;
            case IActionType.LEAD_MODAL:
                if (queryClient) {
                    var allLeads = queryClient === null || queryClient === void 0 ? void 0 : queryClient.getQueryData("getAllLeads");
                    // form name
                    var ref4 = (param === null || param === void 0 ? void 0 : param.url) || "";
                    var forms = allLeads === null || allLeads === void 0 ? void 0 : allLeads[ref4].forms;
                    if (forms) {
                        openLeadModal({
                            form: forms,
                            onSuccess: function(submitOutput) {
                                return manageSuccessLeadPlatform(submitOutput, push, queryClient);
                            },
                            onError: function(submitOutput, errorCmsApi) {
                                return manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient);
                            },
                            trackingOption: {
                                visitor_type: authType
                            }
                        });
                    }
                }
                break;
            default:
                break;
        }
    };
    return {
        handleClick: handleClick,
        isShowCoverageTool: isShowCoverageTool,
        setIsShowCoverageTool: setIsShowCoverageTool,
        isShowWhatsapp: isShowWhatsapp,
        setIsShowWhatsapp: setIsShowWhatsapp,
        showCheckout: showCheckout,
        modalDetails: modalDetails,
        setModalDetails: setModalDetails,
        postCoverageData: postCoverageData,
        setPostCoverageData: setPostCoverageData
    };
};
export { useCardFunctionality };
