import { PlaceholdersID, cmsDataID, getDeviceTotalRenewalCost, getDeviceTotalCost, getLabel, getPrice, getPriceDevice, getPriceDeviceSpecificOffer, getPriceOfferAndDevice, getPriceOfferDevice, useCmsConfig, useSwitchCmsData, DeviceDetailsPlaceholders, getCharacteristicValues } from "@vfit/business/data-access";
import { getValues } from "../WidgetHubDevice/widgetHubDevice.utils";
var getFormatData = function(device, capacityAndColor, widget, selectedRes) {
    var ref, ref1, ref2;
    var allCapacity = capacityAndColor === null || capacityAndColor === void 0 ? void 0 : (ref = capacityAndColor.capacity) === null || ref === void 0 ? void 0 : ref.filter(function(e) {
        var ref;
        return device === null || device === void 0 ? void 0 : (ref = device.capacity) === null || ref === void 0 ? void 0 : ref.find(function(c) {
            return (c === null || c === void 0 ? void 0 : c.id) === (e === null || e === void 0 ? void 0 : e.capacityId);
        });
    });
    var allColors = capacityAndColor === null || capacityAndColor === void 0 ? void 0 : (ref1 = capacityAndColor.colors) === null || ref1 === void 0 ? void 0 : ref1.filter(function(e) {
        var ref;
        return device === null || device === void 0 ? void 0 : (ref = device.colour) === null || ref === void 0 ? void 0 : ref.find(function(c) {
            return (c === null || c === void 0 ? void 0 : c.id) === (e === null || e === void 0 ? void 0 : e.gradientId);
        });
    });
    return ({
        // widget info
        deviceId: widget === null || widget === void 0 ? void 0 : widget.product.dxlId,
        deviceType: widget === null || widget === void 0 ? void 0 : (ref2 = widget.product) === null || ref2 === void 0 ? void 0 : ref2.productType,
        backLabel: widget === null || widget === void 0 ? void 0 : widget.product.backLabel,
        frontLabel: widget === null || widget === void 0 ? void 0 : widget.product.frontLabel,
        offerLabel: widget === null || widget === void 0 ? void 0 : widget.product.offerLabel,
        topPriceLabel: widget === null || widget === void 0 ? void 0 : widget.product.topPriceLabel,
        colorLabel: widget === null || widget === void 0 ? void 0 : widget.product.colorLabel,
        capacityLabel: widget === null || widget === void 0 ? void 0 : widget.product.capacityLabel,
        offerMatchLabel: widget === null || widget === void 0 ? void 0 : widget.product.offerMatchLabel,
        tags: widget === null || widget === void 0 ? void 0 : widget.product.tags,
        tagDevice: widget === null || widget === void 0 ? void 0 : widget.product.tagDevice,
        additionalOfferDetails: getAdditionalOfferDetails((widget === null || widget === void 0 ? void 0 : widget.product.additionalOfferDetails) || {}, selectedRes),
        actions: widget === null || widget === void 0 ? void 0 : widget.product.actions,
        customAction: widget === null || widget === void 0 ? void 0 : widget.customAction,
        tagline: (widget === null || widget === void 0 ? void 0 : widget.product.overrideTagLine) || getValues((device === null || device === void 0 ? void 0 : device.characteristic) || [], "deviceTag"),
        // common device info
        brand: device === null || device === void 0 ? void 0 : device.deviceBrand,
        title: device === null || device === void 0 ? void 0 : device.deviceName,
        allCombinations: {
            colors: allColors || [],
            capacity: allCapacity || []
        }
    } || {});
};
var getResourceData = function(device, capacityAndColor, widget, selectedRes) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
    var ref9 = useSwitchCmsData(cmsDataID.PLACEHOLDER), cmsConfig = ref9.cmsConfig, api = ref9.api;
    var placeholders = useCmsConfig(cmsConfig, api).placeholders;
    // capacity color labels
    var capacity = capacityAndColor === null || capacityAndColor === void 0 ? void 0 : (ref = capacityAndColor.capacity) === null || ref === void 0 ? void 0 : ref.find(function(el) {
        var ref;
        return (el === null || el === void 0 ? void 0 : el.capacityId) === (selectedRes === null || selectedRes === void 0 ? void 0 : (ref = selectedRes.resourceCapacity) === null || ref === void 0 ? void 0 : ref.id);
    });
    return selectedRes ? {
        resource: selectedRes,
        associatedOfferId: (selectedRes === null || selectedRes === void 0 ? void 0 : (ref1 = selectedRes.product) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.productSpecificationId) || "",
        subTitle: getLabel({
            productName: selectedRes === null || selectedRes === void 0 ? void 0 : (ref3 = selectedRes.product) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.productName,
            capacity: capacity
        }, placeholders.placeholders, PlaceholdersID.PRODUCT_TITLE_DEVICE),
        deviceImages: (ref7 = (ref6 = device === null || device === void 0 ? void 0 : (ref5 = device.characteristic) === null || ref5 === void 0 ? void 0 : ref5.find(function(el) {
            return (el === null || el === void 0 ? void 0 : el.name) === "colors";
        })) === null || ref6 === void 0 ? void 0 : ref6.value) === null || ref7 === void 0 ? void 0 : ref7.filter(function(img) {
            var ref;
            return (img === null || img === void 0 ? void 0 : img.id) === (selectedRes === null || selectedRes === void 0 ? void 0 : (ref = selectedRes.resourceColour) === null || ref === void 0 ? void 0 : ref.id);
        }),
        detailsOfferDevice: [
            getPriceOfferDevice(selectedRes, placeholders.placeholders) || "",
            getPrice(selectedRes === null || selectedRes === void 0 ? void 0 : (ref8 = selectedRes.product) === null || ref8 === void 0 ? void 0 : ref8[0], placeholders.placeholders) || "",
            getPriceDevice(selectedRes, placeholders.placeholders) || "",
            (widget === null || widget === void 0 ? void 0 : widget.product.shortDescription) || "", 
        ],
        price: getPriceOfferAndDevice(selectedRes, placeholders.placeholders, PlaceholdersID.TOTAL_COST_WITH_FEE_DEVICE)
    } : {};
};
// START Resource funcions
var findFirstResource = function(offerCombination, capacityAndColor) {
    var ref, ref1, ref2, ref3;
    // select first offer
    var offerId = Object.keys(offerCombination)[0];
    var offerResource = offerCombination[offerId];
    var color = ((ref1 = capacityAndColor === null || capacityAndColor === void 0 ? void 0 : (ref = capacityAndColor.colors) === null || ref === void 0 ? void 0 : ref.find(function(c) {
        return offerResource === null || offerResource === void 0 ? void 0 : offerResource.find(function(r) {
            var ref;
            return (r === null || r === void 0 ? void 0 : (ref = r.resourceColour) === null || ref === void 0 ? void 0 : ref.id) === (c === null || c === void 0 ? void 0 : c.gradientId);
        });
    })) === null || ref1 === void 0 ? void 0 : ref1.gradientId) || "";
    var capacity = ((ref3 = capacityAndColor === null || capacityAndColor === void 0 ? void 0 : (ref2 = capacityAndColor.capacity) === null || ref2 === void 0 ? void 0 : ref2.find(function(c) {
        return offerResource === null || offerResource === void 0 ? void 0 : offerResource.find(function(r) {
            var ref, ref1;
            return (r === null || r === void 0 ? void 0 : (ref = r.resourceCapacity) === null || ref === void 0 ? void 0 : ref.id) === (c === null || c === void 0 ? void 0 : c.capacityId) && (r === null || r === void 0 ? void 0 : (ref1 = r.resourceColour) === null || ref1 === void 0 ? void 0 : ref1.id) === color;
        });
    })) === null || ref3 === void 0 ? void 0 : ref3.capacityId) || "";
    return {
        resource: offerResource === null || offerResource === void 0 ? void 0 : offerResource.find(function(r) {
            var ref, ref1;
            return (r === null || r === void 0 ? void 0 : (ref = r.resourceColour) === null || ref === void 0 ? void 0 : ref.id) === color && (r === null || r === void 0 ? void 0 : (ref1 = r.resourceCapacity) === null || ref1 === void 0 ? void 0 : ref1.id) === capacity;
        }),
        offerId: offerId
    };
};
var findResourceByOfferId = function(offerResource, filter, type) {
    var capacityResource = offerResource === null || offerResource === void 0 ? void 0 : offerResource.find(function(r) {
        var ref;
        return (r === null || r === void 0 ? void 0 : (ref = r.resourceCapacity) === null || ref === void 0 ? void 0 : ref.id) === (filter === null || filter === void 0 ? void 0 : filter.capacity);
    });
    var colourResource = offerResource === null || offerResource === void 0 ? void 0 : offerResource.find(function(r) {
        var ref;
        return (r === null || r === void 0 ? void 0 : (ref = r.resourceColour) === null || ref === void 0 ? void 0 : ref.id) === (filter === null || filter === void 0 ? void 0 : filter.color);
    });
    return type === "capacity" ? capacityResource : colourResource;
};
var findNewResource = function(offerCombination, filter, type) {
    switch(type){
        case "capacity":
            {
                var ref;
                var capacityResOffer;
                var capacityOffer = (ref = Object.entries(offerCombination)) === null || ref === void 0 ? void 0 : ref.find(function(el) {
                    var ref;
                    capacityResOffer = el === null || el === void 0 ? void 0 : (ref = el[1]) === null || ref === void 0 ? void 0 : ref.find(function(e) {
                        var ref;
                        return (e === null || e === void 0 ? void 0 : (ref = e.resourceCapacity) === null || ref === void 0 ? void 0 : ref.id) === (filter === null || filter === void 0 ? void 0 : filter.capacity);
                    });
                    return capacityResOffer ? el : undefined;
                });
                return {
                    resource: capacityResOffer,
                    offerId: (capacityOffer === null || capacityOffer === void 0 ? void 0 : capacityOffer[0]) || ""
                };
            }
        case "color":
            {
                var ref1;
                var colorResOffer;
                var colorOffer = (ref1 = Object.entries(offerCombination)) === null || ref1 === void 0 ? void 0 : ref1.find(function(el) {
                    var ref;
                    colorResOffer = el === null || el === void 0 ? void 0 : (ref = el[1]) === null || ref === void 0 ? void 0 : ref.find(function(e) {
                        var ref;
                        return (e === null || e === void 0 ? void 0 : (ref = e.resourceColour) === null || ref === void 0 ? void 0 : ref.id) === (filter === null || filter === void 0 ? void 0 : filter.color);
                    });
                    return colorResOffer ? el : undefined;
                });
                return {
                    resource: colorResOffer,
                    offerId: (colorOffer === null || colorOffer === void 0 ? void 0 : colorOffer[0]) || ""
                };
            }
        default:
            return {
                resource: offerCombination[filter === null || filter === void 0 ? void 0 : filter.offerId][0],
                offerId: filter === null || filter === void 0 ? void 0 : filter.offerId
            };
    }
};
// END Resource funcions
var getFormatOffers = function(offerCombination, selectedResource) {
    var ref;
    var ref1 = useSwitchCmsData(cmsDataID.PLACEHOLDER), cmsConfig = ref1.cmsConfig, api = ref1.api;
    var placeholders = useCmsConfig(cmsConfig, api).placeholders;
    return (ref = Object.entries(offerCombination)) === null || ref === void 0 ? void 0 : ref.map(function(el) {
        var ref, ref1, ref2;
        var id = el[0];
        var resource = (selectedResource === null || selectedResource === void 0 ? void 0 : (ref = selectedResource.product) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.productSpecificationId) === id ? selectedResource : el[1][0];
        var offer = resource === null || resource === void 0 ? void 0 : (ref2 = resource.product) === null || ref2 === void 0 ? void 0 : ref2[0];
        return {
            id: id,
            title: getLabel(offer, placeholders.placeholders, PlaceholdersID.DEVICE_OFFER_NAME_LABEL) || "",
            description: getValues((offer === null || offer === void 0 ? void 0 : offer.characteristic) || [], "productShortDescription"),
            price: getPriceOfferAndDevice(resource, placeholders.placeholders, PlaceholdersID.TOTAL_COST_WITH_FEE_CUSTOMIZATION),
            unaTantum: getPriceDeviceSpecificOffer(resource, placeholders.placeholders),
            deviceTimeDiscount: (offer === null || offer === void 0 ? void 0 : offer.timeDiscount) && getCharacteristicValues((offer === null || offer === void 0 ? void 0 : offer.characteristic) || [], "timeDiscountTagForDevicePages")
        };
    });
};
var getDetailsPlaceholders = function(placeholder, selectedRes, placeholders) {
    switch(placeholder){
        case DeviceDetailsPlaceholders.MOBILEPHONE_PLAN:
            {
                var ref;
                return getPrice(selectedRes === null || selectedRes === void 0 ? void 0 : (ref = selectedRes.product) === null || ref === void 0 ? void 0 : ref[0], placeholders) || "";
            }
        case DeviceDetailsPlaceholders.RENEWAL_COST_DEVICE:
            {
                return getPriceOfferDevice(selectedRes, placeholders) || "";
            }
        case DeviceDetailsPlaceholders.TOTAL_RENEWAL_COST_DEVICE:
            {
                return getDeviceTotalRenewalCost(selectedRes, placeholders) || "";
            }
        case DeviceDetailsPlaceholders.DEVICE_COST_UNA_TANTUM:
            {
                return getPriceDevice(selectedRes, placeholders) || "";
            }
        case DeviceDetailsPlaceholders.TOTAL_DEVICE_COST:
            {
                return getDeviceTotalCost(selectedRes, placeholders) || "";
            }
        default:
            return "";
    }
};
var getAdditionalOfferDetails = function(details, selectedRes) {
    var ref, ref1;
    var ref2 = useSwitchCmsData(cmsDataID.PLACEHOLDER), cmsConfig = ref2.cmsConfig, api = ref2.api;
    var placeholders = useCmsConfig(cmsConfig, api).placeholders;
    var reg = /([$])\w+/g;
    var newDetails = details.moreInfoContentHtml;
    (ref1 = (ref = details.moreInfoContentHtml) === null || ref === void 0 ? void 0 : ref.match(reg)) === null || ref1 === void 0 ? void 0 : ref1.forEach(function(element) {
        var replacedData = selectedRes ? getDetailsPlaceholders(element, selectedRes, placeholders.placeholders) : "";
        newDetails = newDetails === null || newDetails === void 0 ? void 0 : newDetails.replace(element, replacedData);
    });
    return {
        title: details.title,
        enableMoreInfo: details.enableMoreInfo,
        moreInfoContentHtml: newDetails,
        moreInfoTitle: details.moreInfoTitle
    };
};
export { getFormatData, getResourceData, findFirstResource, findResourceByOfferId, findNewResource, getFormatOffers };
