import { IActionType } from "@vfit/shared/models";
import { useContext, useState } from "react";
import { useRouter } from "next/router";
import { useVolaUtils } from "@vfit/business/data-access";
import { TrackingPageContext } from "@vfit/business/providers";
var useCardFunctionality = function() {
    var descriptionVolaUrl = useContext(TrackingPageContext).descriptionVolaUrl;
    var ref = useVolaUtils(), formatUrlVola = ref.formatUrlVola, openPopupVola = ref.openPopupVola, formatDynamicVolaUrl = ref.formatDynamicVolaUrl;
    var ref1 = useState(false), isShowCoverageTool = ref1[0], setIsShowCoverageTool = ref1[1];
    var ref2 = useState(false), isShowWhatsapp = ref2[0], setIsShowWhatsapp = ref2[1];
    var ref3 = useState(""), showCheckout = ref3[0], setShowCheckout = ref3[1];
    var ref4 = useState({
        show: false
    }), modalDetails = ref4[0], setModalDetails = ref4[1];
    var push = useRouter().push;
    var handleClick = function(param, product, desc) {
        var type = (param === null || param === void 0 ? void 0 : param.type) ? +param.type : 0;
        switch(type){
            case IActionType.CUSTOM_URL:
                if (param === null || param === void 0 ? void 0 : param.url) {
                    window.open(param === null || param === void 0 ? void 0 : param.url, (param === null || param === void 0 ? void 0 : param.isBlank) === "true" ? "_blank" : "_self");
                }
                break;
            case IActionType.LOCAL_URL:
                if (param === null || param === void 0 ? void 0 : param.url) push(param === null || param === void 0 ? void 0 : param.url);
                break;
            case IActionType.COVERAGE_TOOL:
                setIsShowCoverageTool(true);
                break;
            case IActionType.WHATSAPP_URL:
                setIsShowWhatsapp(true);
                break;
            case IActionType.CALL_ME_NOW:
                if (param.url) {
                    if (descriptionVolaUrl && desc) openPopupVola(formatDynamicVolaUrl(param === null || param === void 0 ? void 0 : param.url, desc), "vola-ctc");
                    else openPopupVola(formatUrlVola(param === null || param === void 0 ? void 0 : param.url, product), "vola-ctc");
                }
                break;
            case IActionType.CHECKOUT_MODAL_MOBILE:
                setShowCheckout((product === null || product === void 0 ? void 0 : product.offerId) || "");
                break;
            case IActionType.POPUP_DETAIL:
                setModalDetails({
                    show: true
                });
                break;
            default:
                break;
        }
    };
    return {
        handleClick: handleClick,
        isShowCoverageTool: isShowCoverageTool,
        setIsShowCoverageTool: setIsShowCoverageTool,
        isShowWhatsapp: isShowWhatsapp,
        setIsShowWhatsapp: setIsShowWhatsapp,
        showCheckout: showCheckout,
        modalDetails: modalDetails,
        setModalDetails: setModalDetails
    };
};
export { useCardFunctionality };
