import { ErrorAction, errorManager, ErrorSeverity, useHandleQueryWithAuthSSO } from "@vfit/business/providers";
import { getListLandingPage } from "../../api";
export var useGetListLandingPage = function(param) {
    var code = param.code, version = param.version, enabled = param.enabled, handleNextStep = param.handleNextStep;
    var getListLanding = {
        queryKey: "listLandingPage",
        queryFn: function() {
            return getListLandingPage({
                code: code,
                version: version
            });
        },
        options: {
            onSuccess: function() {
                return handleNextStep();
            },
            onError: function(error) {
                var status = error.status;
                var statusNumber = Number(status);
                if (statusNumber !== 404) {
                    errorManager.handleError(ErrorSeverity.HIGH, {
                        errorAction: ErrorAction.PAGE,
                        errorCode: statusNumber
                    });
                }
            },
            enabled: enabled
        }
    };
    var error = useHandleQueryWithAuthSSO(getListLanding).error;
    return {
        error: error
    };
};
