import { getLabel, PlaceholdersID, getDSIOTPrice, getDSIOTActivationPrice } from "@vfit/business/data-access";
import { getFormattedPriceStringDS } from "@vfit/business/providers";
var formatProducts = function(elements, placeholder, businessSolutionInfo) {
    var products = elements.map(function(el) {
        var ref;
        var editionInfo = businessSolutionInfo === null || businessSolutionInfo === void 0 ? void 0 : (ref = businessSolutionInfo.editionInfo) === null || ref === void 0 ? void 0 : ref.find(function(item) {
            var ref;
            return item.productSpecificationId === (el === null || el === void 0 ? void 0 : (ref = el.dxlId) === null || ref === void 0 ? void 0 : ref.toString());
        });
        if ((el === null || el === void 0 ? void 0 : el.isEditorialCard) === "false" && !editionInfo) return undefined;
        return {
            tagLine: el === null || el === void 0 ? void 0 : el.tagLine,
            offerName: (el === null || el === void 0 ? void 0 : el.overrideOfferName) || editionInfo && getLabel(editionInfo, placeholder, PlaceholdersID.BS_EDITION_NAME),
            frontLabel: el === null || el === void 0 ? void 0 : el.frontLabel,
            bottomLabel: el === null || el === void 0 ? void 0 : el.offerLabel,
            additionalOfferDetails: el === null || el === void 0 ? void 0 : el.additionalOfferDetails,
            offerSpecs: el === null || el === void 0 ? void 0 : el.shortCharacteristics,
            offerId: el === null || el === void 0 ? void 0 : el.dxlId,
            isEditorialCard: el === null || el === void 0 ? void 0 : el.isEditorialCard,
            taggingProductPrice: (el === null || el === void 0 ? void 0 : el.taggingProductPrice) || getFormattedPriceStringDS(editionInfo === null || editionInfo === void 0 ? void 0 : editionInfo.costs) || "",
            priceDescription: (el === null || el === void 0 ? void 0 : el.overrideCostActivationHtml) || editionInfo && getDSIOTActivationPrice(editionInfo.costs, placeholder),
            shortDescription: el === null || el === void 0 ? void 0 : el.shortDescription,
            price: (el === null || el === void 0 ? void 0 : el.overrideCostRenewalHtml) || editionInfo && getDSIOTPrice(editionInfo.costs, placeholder),
            actions: el === null || el === void 0 ? void 0 : el.actions,
            pills: el === null || el === void 0 ? void 0 : el.tags,
            category: (el === null || el === void 0 ? void 0 : el.overrideCategory) || businessSolutionInfo && getLabel(businessSolutionInfo, placeholder, PlaceholdersID.BS_NAME)
        };
    }).filter(Boolean);
    return products;
};
export { formatProducts };
