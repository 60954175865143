import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  overflow-x: auto;\n  padding: 0;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 16px;\n  margin-bottom: 39px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 48px;\n    justify-content: ",
        ";\n  }\n\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: none;\n\n  ::-webkit-scrollbar {\n    display: none; /* for Chrome, Safari, and Opera */\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  padding: 3px 12px;\n  flex-shrink: 0;\n  color: ",
        ";\n  background-color: ",
        ";\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 4px 16px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var TabsContainer = styled.div.withConfig({
    componentId: "sc-20c8c862-0"
})(_templateObject(), breakpoints.desktop, function(param) {
    var align = param.align;
    return align || "flex-start";
});
export var Tab = styled.div.withConfig({
    componentId: "sc-20c8c862-1"
})(_templateObject1(), colors.$262626, fonts.regular, pxToCssFont(16, 22), function(param) {
    var isActive = param.isActive;
    return isActive ? colors.$ffffff : colors.$0d0d0d;
}, function(param) {
    var isActive = param.isActive;
    return isActive ? colors.$262626 : colors.$ffffff;
}, breakpoints.desktop);
