import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getActionUrlByActionType } from "@vfit/shared/data-access";
import { useWidgetsAction } from "../Widgets/widgets.hook";
import { getTableRowsList } from "./widgetSplittedTableText.utils";
export var useWidgetTableText = function(widget) {
    var ref;
    var tableRows = getTableRowsList(widget.elements);
    var handleClick = useWidgetsAction().handleClick;
    return _object_spread({
        title: widget.title,
        description: widget.description,
        tableRows: tableRows,
        action: _object_spread_props(_object_spread({}, widget.customAction), {
            style: Number((ref = widget.customAction) === null || ref === void 0 ? void 0 : ref.style)
        }),
        handleButtonClick: handleClick
    }, widget.customAction && {
        actionNavigationUrl: getActionUrlByActionType(widget.customAction)
    });
};
