import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-business-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useMutation, useQueryClient } from "react-query";
import { errorManager, ErrorAction, ErrorSeverity, useHandleQueryWithAuthSSO, PageLoadingContext } from "@vfit/business/providers";
import { useContext, useEffect } from "react";
import { API, PAGES } from "@vfit/shared/data-access";
import { dxlPostCreateShoppingCart } from "../../api";
import { CMS_CONFIG, useCmsConfig } from "../../cms";
var getContactMediumFromShoppingCart = function(address) {
    var contactMedium = [];
    if (address) {
        contactMedium.push({
            "@type": "GeographicAddress",
            mediumType: "installation address",
            preferred: true,
            characteristic: {
                city: address === null || address === void 0 ? void 0 : address.city,
                postCode: address === null || address === void 0 ? void 0 : address.postalCode,
                socialNetworkId: address === null || address === void 0 ? void 0 : address.placeId,
                stateOrProvince: address === null || address === void 0 ? void 0 : address.stateOrProvince,
                street1: "".concat(address === null || address === void 0 ? void 0 : address.street, "|").concat(address === null || address === void 0 ? void 0 : address.streetNumber)
            }
        });
    }
    return contactMedium;
};
var mapWebCartItemToDxlCartItem = function(cartItem, selectedAddons) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    var productInfo = (cartItem || {}).productInfo;
    var deviceInfo = cartItem === null || cartItem === void 0 ? void 0 : cartItem.deviceInfo;
    var requestCartItem = {
        status: "active",
        quantity: 1
    };
    var product = {};
    requestCartItem.productOffering = {
        id: productInfo === null || productInfo === void 0 ? void 0 : (ref = productInfo[0]) === null || ref === void 0 ? void 0 : ref.productOfferingId,
        "@referredType": productInfo === null || productInfo === void 0 ? void 0 : (ref1 = productInfo[0]) === null || ref1 === void 0 ? void 0 : ref1.productType
    };
    product.productSpecification = {
        id: productInfo === null || productInfo === void 0 ? void 0 : (ref2 = productInfo[0]) === null || ref2 === void 0 ? void 0 : ref2.productSpecificationId,
        name: productInfo === null || productInfo === void 0 ? void 0 : (ref3 = productInfo[0]) === null || ref3 === void 0 ? void 0 : ref3.productName
    };
    product.productCharacteristic = [
        {
            name: "category",
            value: productInfo === null || productInfo === void 0 ? void 0 : (ref4 = productInfo[0]) === null || ref4 === void 0 ? void 0 : ref4.productCategory
        }, 
    ];
    //Add "version" or "MNP" or "GNP"
    if (productInfo === null || productInfo === void 0 ? void 0 : (ref5 = productInfo[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.costs) === null || ref6 === void 0 ? void 0 : ref6.version) {
        var ref12, ref13;
        product.productCharacteristic.push({
            name: "version",
            value: productInfo === null || productInfo === void 0 ? void 0 : (ref12 = productInfo[0]) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.costs) === null || ref13 === void 0 ? void 0 : ref13.version
        });
    }
    //Add "timeDiscount"
    if (productInfo === null || productInfo === void 0 ? void 0 : (ref7 = productInfo[0]) === null || ref7 === void 0 ? void 0 : ref7.timeDiscount) {
        var ref14;
        product.productCharacteristic.push({
            name: "timediscount",
            value: productInfo === null || productInfo === void 0 ? void 0 : (ref14 = productInfo[0]) === null || ref14 === void 0 ? void 0 : ref14.timeDiscount
        });
    }
    //Add mandatory addon
    product.product = [];
    // product.product = productInfo?.[0]?.addOn?.filter(a => a?.productType === 'M')?.map(addon => {
    //   return {
    //     productSpecification: {
    //       id: addon.productSpecificationId,
    //       name: addon?.productName,
    //       '@referredType': 'AddOn',
    //     },
    //   } as DxlShoppingCart.ICartItemProductProduct;
    // })!
    // Add selected AddOns not mandatory
    var addOnNotM = productInfo === null || productInfo === void 0 ? void 0 : (ref8 = productInfo[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.addOn) === null || ref9 === void 0 ? void 0 : ref9.filter(function(addon) {
        return (addon === null || addon === void 0 ? void 0 : addon.productType) !== "M";
    }); //change check: if productType is 'O' 'C' or 'D'
    if (selectedAddons && addOnNotM) {
        var ref15, ref16, ref17;
        var otherAddon = (ref15 = addOnNotM === null || addOnNotM === void 0 ? void 0 : addOnNotM.filter(function(a) {
            var ref;
            return selectedAddons === null || selectedAddons === void 0 ? void 0 : (ref = selectedAddons.addonList) === null || ref === void 0 ? void 0 : ref.find(function(e) {
                return (e === null || e === void 0 ? void 0 : e.id) === (a === null || a === void 0 ? void 0 : a.productSpecificationId);
            });
        })) === null || ref15 === void 0 ? void 0 : ref15.map(function(addon) {
            return {
                productSpecification: {
                    id: addon.productSpecificationId,
                    name: addon === null || addon === void 0 ? void 0 : addon.productName,
                    "@referredType": "AddOn"
                }
            };
        });
        product.product = _to_consumable_array(product.product).concat(_to_consumable_array(otherAddon));
        //add addon power4g
        if ((selectedAddons === null || selectedAddons === void 0 ? void 0 : selectedAddons.power4gAddon) && (productInfo === null || productInfo === void 0 ? void 0 : (ref16 = productInfo[0]) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.technicalPacket) === null || ref17 === void 0 ? void 0 : ref17["4G"])) {
            var addon4g = addOnNotM === null || addOnNotM === void 0 ? void 0 : addOnNotM.find(function(a) {
                var ref, ref1;
                return a.productSpecificationId === (selectedAddons === null || selectedAddons === void 0 ? void 0 : (ref = selectedAddons.power4gAddon) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.id);
            });
            if (addon4g) {
                product.product.push({
                    productSpecification: {
                        id: addon4g === null || addon4g === void 0 ? void 0 : addon4g.productSpecificationId,
                        name: addon4g === null || addon4g === void 0 ? void 0 : addon4g.productName,
                        "@referredType": "AddOn"
                    }
                });
            }
        }
    }
    if (productInfo === null || productInfo === void 0 ? void 0 : (ref10 = productInfo[0]) === null || ref10 === void 0 ? void 0 : ref10.technicalPacket) {
        var ref18, ref19, ref20;
        product.product.push({
            realizingService: [
                {
                    id: productInfo === null || productInfo === void 0 ? void 0 : (ref18 = productInfo[0]) === null || ref18 === void 0 ? void 0 : ref18.technicalPacket.id,
                    name: productInfo === null || productInfo === void 0 ? void 0 : (ref19 = productInfo[0]) === null || ref19 === void 0 ? void 0 : ref19.technicalPacket.mainTech,
                    characteristic: [
                        {
                            name: "4G",
                            value: (productInfo === null || productInfo === void 0 ? void 0 : (ref20 = productInfo[0]) === null || ref20 === void 0 ? void 0 : ref20.technicalPacket["4G"]) ? "Y" : "N"
                        }, 
                    ]
                }, 
            ]
        });
    }
    //tariffPlan
    if (productInfo === null || productInfo === void 0 ? void 0 : (ref11 = productInfo[0]) === null || ref11 === void 0 ? void 0 : ref11.tariffPlan) {
        var ref21, ref22, ref23, ref24;
        product.product.push({
            productSpecification: {
                id: productInfo === null || productInfo === void 0 ? void 0 : (ref21 = productInfo[0]) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.tariffPlan) === null || ref22 === void 0 ? void 0 : ref22.productSpecificationId,
                name: productInfo === null || productInfo === void 0 ? void 0 : (ref23 = productInfo[0]) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.tariffPlan) === null || ref24 === void 0 ? void 0 : ref24.productName,
                "@referredType": "tariffPlan"
            }
        });
    }
    //TODO: manage businessSolutions
    // Add devices e.b
    if ((deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.length) > 0) {
        var _product;
        var selectedDevices = deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.map(function(device) {
            var ref, ref1;
            var resource = device.selectedResource;
            var colour = ((ref = resource.resourceColour) === null || ref === void 0 ? void 0 : ref.id) || "";
            var capacity = ((ref1 = resource.resourceCapacity) === null || ref1 === void 0 ? void 0 : ref1.id) || "";
            //[ECO-5669] <deviceId>|<deviceBrand> <deviceModel> <colour>, <capacity>
            var name = "".concat(device.deviceId || "", "|").concat(device.deviceBrand || "", " ").concat(device.deviceName || "", " ").concat(colour, " ").concat(capacity);
            return {
                realizingResource: [
                    {
                        id: resource.resourceCandidateId,
                        name: name,
                        "@referredType": "PhysicalResourceSpec"
                    }
                ]
            };
        });
        (_product = product.product).push.apply(_product, _to_consumable_array(selectedDevices));
    }
    requestCartItem.product = product;
    return [
        requestCartItem
    ];
};
var mapWebCartItemToDxlCartItemDS = function(cartItem) {
    var ref, ref1, ref2;
    var businessSolutionInfo = (cartItem || {}).businessSolutionInfo;
    var requestCartItem = {
        status: "active",
        quantity: 1
    };
    var product = {};
    var bsEditionInfo = businessSolutionInfo === null || businessSolutionInfo === void 0 ? void 0 : (ref = businessSolutionInfo[0]) === null || ref === void 0 ? void 0 : ref.editionInfo;
    product.product = [];
    requestCartItem.productOffering = {
        id: bsEditionInfo === null || bsEditionInfo === void 0 ? void 0 : (ref1 = bsEditionInfo[0]) === null || ref1 === void 0 ? void 0 : ref1.productOfferingId,
        "@referredType": bsEditionInfo === null || bsEditionInfo === void 0 ? void 0 : (ref2 = bsEditionInfo[0]) === null || ref2 === void 0 ? void 0 : ref2.productType
    };
    product.productSpecification = {
        id: (bsEditionInfo === null || bsEditionInfo === void 0 ? void 0 : bsEditionInfo[0].productSpecificationId) || "",
        name: (bsEditionInfo === null || bsEditionInfo === void 0 ? void 0 : bsEditionInfo[0].productName) || ""
    };
    product.productCharacteristic = [
        {
            name: "category",
            value: bsEditionInfo === null || bsEditionInfo === void 0 ? void 0 : bsEditionInfo[0].productCategory
        }, 
    ];
    requestCartItem.product = product;
    return [
        requestCartItem
    ];
};
var usePostCreateShoppingCart = function() {
    var ref, ref1, ref2;
    //set loading
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var queryClient = useQueryClient();
    //retrive profile data from react query
    var profile = queryClient.getQueryData("CBProfile");
    //retrive cartItem data from react query
    var cartItems = queryClient.getQueryData("ecommerceCatalogWithAddress") || JSON.parse(localStorage.getItem("catalog_address") || "");
    //retrive selected address
    var coverageAddress = queryClient.getQueryData("validAddress");
    // retrive selected addons
    var productDetailsAEM = useCmsConfig(CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE], API.CMS_BFL_GET_DETAIL_PRODUCTS);
    var selectedaddons = productDetailsAEM === null || productDetailsAEM === void 0 ? void 0 : (ref2 = productDetailsAEM[cartItems === null || cartItems === void 0 ? void 0 : (ref = cartItems.productInfo) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.productSpecificationId]) === null || ref2 === void 0 ? void 0 : ref2.selectedaddons;
    //create paylod for postShoppingCart API
    var payload = {
        cartItem: mapWebCartItemToDxlCartItem(cartItems, selectedaddons),
        contactMedium: getContactMediumFromShoppingCart(coverageAddress),
        relatedParty: [],
        validFor: {
            startDateTime: new Date().toISOString(),
            endDateTime: new Date().toISOString()
        }
    };
    //check if user is CB or PROSPECT
    if ((profile === null || profile === void 0 ? void 0 : profile.profileData) && !!(profile === null || profile === void 0 ? void 0 : profile.profileData.username)) {
        payload.relatedParty.push({
            id: profile === null || profile === void 0 ? void 0 : profile.profileData.username,
            role: "web account"
        });
    }
    //create query
    var postCreateShoppingCart = {
        queryKey: "createShoppingCart",
        queryFn: _async_to_generator(regeneratorRuntime.mark(function _callee() {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        return _ctx.abrupt("return", dxlPostCreateShoppingCart(payload));
                    case 1:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        })),
        options: {
            onError: function(error) {
                var status = error.status;
                var statusNumber = Number(status);
                errorManager.handleError(ErrorSeverity.HIGH, {
                    errorAction: ErrorAction.PAGE,
                    errorCode: statusNumber
                });
            },
            enabled: cartItems ? true : false
        }
    };
    //retrive response
    var queryResult = useHandleQueryWithAuthSSO(postCreateShoppingCart);
    var data = queryResult.data, isError = queryResult.isError;
    useEffect(function() {
        if (data) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [
        queryResult
    ]);
    return {
        data: data,
        isError: isError
    };
};
var usePostCreateShoppingCartMobile = function(offerId, showCheckout) {
    var queryClient = useQueryClient();
    //retrive profile data from react query
    var profile = queryClient.getQueryData("CBProfile");
    //retrive cartItem data from react query
    var cartItems = queryClient.getQueryData([
        offerId,
        "ecommerceCatalogCoreOffer", 
    ]);
    //create paylod for postShoppingCart API
    var payload = {
        cartItem: mapWebCartItemToDxlCartItem(cartItems),
        contactMedium: getContactMediumFromShoppingCart(),
        relatedParty: [],
        validFor: {
            startDateTime: new Date().toISOString(),
            endDateTime: new Date().toISOString()
        }
    };
    //check if user is CB or PROSPECT
    if ((profile === null || profile === void 0 ? void 0 : profile.profileData) && !!(profile === null || profile === void 0 ? void 0 : profile.profileData.username)) {
        payload.relatedParty.push({
            id: profile === null || profile === void 0 ? void 0 : profile.profileData.username,
            role: "web account"
        });
    }
    // create query
    var postCreateShoppingCart = {
        queryKey: [
            offerId,
            "createShoppingCartMobile"
        ],
        queryFn: _async_to_generator(regeneratorRuntime.mark(function _callee() {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        return _ctx.abrupt("return", dxlPostCreateShoppingCart(payload));
                    case 1:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        })),
        options: {
            onError: function(error) {
                var status = error.status;
                var statusNumber = Number(status);
                errorManager.handleError(ErrorSeverity.HIGH, {
                    errorAction: ErrorAction.PAGE,
                    errorCode: statusNumber
                });
            },
            enabled: showCheckout === offerId
        }
    };
    //retrive response
    var queryResult = useHandleQueryWithAuthSSO(postCreateShoppingCart);
    var data = queryResult.data;
    return {
        data: data
    };
};
var usePostCreateShoppingCartDevice = function(deviceId, resource, showCheckOut) {
    var ref;
    var queryClient = useQueryClient();
    //retrive profile data from react query
    var profile = queryClient.getQueryData("CBProfile");
    //retrive device data from react query
    var deviceItem = queryClient.getQueryData([
        deviceId,
        "ecommerceCatalogDeviceInfo", 
    ]);
    var cartItems = {
        productInfo: (resource === null || resource === void 0 ? void 0 : resource.product) || [],
        deviceInfo: [
            _object_spread_props(_object_spread({}, deviceItem === null || deviceItem === void 0 ? void 0 : (ref = deviceItem.deviceInfo) === null || ref === void 0 ? void 0 : ref[0]), {
                selectedResource: resource
            })
        ]
    };
    //create paylod for postShoppingCart API
    var payload = {
        cartItem: mapWebCartItemToDxlCartItem(cartItems),
        contactMedium: getContactMediumFromShoppingCart(),
        relatedParty: [],
        validFor: {
            startDateTime: new Date().toISOString(),
            endDateTime: new Date().toISOString()
        }
    };
    //check if user is CB or PROSPECT
    if ((profile === null || profile === void 0 ? void 0 : profile.profileData) && !!(profile === null || profile === void 0 ? void 0 : profile.profileData.username)) {
        payload.relatedParty.push({
            id: profile === null || profile === void 0 ? void 0 : profile.profileData.username,
            role: "web account"
        });
    }
    //create query
    var postCreateShoppingCart = {
        queryKey: "createShoppingCartOfferAndDevice",
        queryFn: _async_to_generator(regeneratorRuntime.mark(function _callee() {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        return _ctx.abrupt("return", dxlPostCreateShoppingCart(payload));
                    case 1:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        })),
        options: {
            onError: function(error) {
                var status = error.status;
                var statusNumber = Number(status);
                errorManager.handleError(ErrorSeverity.HIGH, {
                    errorAction: ErrorAction.PAGE,
                    errorCode: statusNumber
                });
            },
            enabled: showCheckOut
        }
    };
    //retrive response
    var queryResult = useHandleQueryWithAuthSSO(postCreateShoppingCart);
    var data = queryResult.data, isError = queryResult.isError;
    return {
        data: data,
        isError: isError
    };
};
var usePostCreateShoppingCartDSMutation = function(offerId, selectedId) {
    var queryClient = useQueryClient();
    // retrive profile data from react query
    var profile = queryClient.getQueryData("CBProfile");
    // retrive cartItem data from react query
    var cartItems = queryClient.getQueryData([
        offerId,
        "ecommerceCatalogDSOffer", 
    ]);
    var getCartItem = function() {
        var ref, ref1, ref2;
        return !selectedId ? cartItems : {
            businessSolutionInfo: [
                _object_spread_props(_object_spread({}, cartItems === null || cartItems === void 0 ? void 0 : (ref = cartItems.businessSolutionInfo) === null || ref === void 0 ? void 0 : ref[0]), {
                    editionInfo: [
                        ((ref2 = cartItems === null || cartItems === void 0 ? void 0 : (ref1 = cartItems.businessSolutionInfo) === null || ref1 === void 0 ? void 0 : ref1[0].editionInfo) === null || ref2 === void 0 ? void 0 : ref2.find(function(info) {
                            return info.productSpecificationId === selectedId;
                        })) || {}
                    ]
                })
            ]
        };
    };
    // create paylod for postShoppingCart API
    var payload = {
        cartItem: mapWebCartItemToDxlCartItemDS(getCartItem()),
        contactMedium: getContactMediumFromShoppingCart(),
        relatedParty: [],
        validFor: {
            startDateTime: new Date().toISOString(),
            endDateTime: new Date().toISOString()
        }
    };
    // check if user is CB or PROSPECT
    if ((profile === null || profile === void 0 ? void 0 : profile.profileData) && !!(profile === null || profile === void 0 ? void 0 : profile.profileData.username)) {
        payload.relatedParty.push({
            id: profile === null || profile === void 0 ? void 0 : profile.profileData.username,
            role: "web account"
        });
    }
    var ref = useMutation("shoppingCartDS", function() {
        return dxlPostCreateShoppingCart(payload);
    }, {
        onError: function(error) {
            var status = error.status;
            var statusNumber = Number(status);
            errorManager.handleError(ErrorSeverity.HIGH, {
                errorAction: ErrorAction.PAGE,
                errorCode: statusNumber
            });
        }
    }), data = ref.data, mutate = ref.mutate;
    return {
        createShoppingCart: function() {
            return mutate();
        },
        data: data
    };
};
export { usePostCreateShoppingCart, usePostCreateShoppingCartMobile, usePostCreateShoppingCartDevice, usePostCreateShoppingCartDSMutation };
