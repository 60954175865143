import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { IActionType } from "@vfit/shared/models";
import crypto from "crypto-es";
import { useContext } from "react";
import { AuthenticationContext, EAuthType } from "../authentication/authentication";
export var getProductTrackInformation = function(queryClient, currentValue) {
    var ref, ref1, ref2, ref3, ref4;
    var dxlProduct = queryClient.getQueryData([
        currentValue === null || currentValue === void 0 ? void 0 : currentValue.dxlId,
        "ecommerceCatalogCoreOffer", 
    ]);
    var dxlProductWithAddress = queryClient.getQueryData("ecommerceCatalogWithAddress");
    return {
        product_id: [
            (currentValue === null || currentValue === void 0 ? void 0 : currentValue.dxlId) || ""
        ],
        product_name: [
            (currentValue === null || currentValue === void 0 ? void 0 : currentValue.taggingProductName) || (dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref = dxlProduct.productInfo) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.productName) || "", 
        ],
        product_category: [
            (currentValue === null || currentValue === void 0 ? void 0 : currentValue.productType) || (dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref2 = dxlProduct.productInfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.productType) || "", 
        ],
        product_price: [
            (currentValue === null || currentValue === void 0 ? void 0 : currentValue.taggingProductPrice) || getFormattedPriceString(dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref4 = dxlProduct.productInfo) === null || ref4 === void 0 ? void 0 : ref4[0]), 
        ],
        product_technology: [
            (dxlProductWithAddress === null || dxlProductWithAddress === void 0 ? void 0 : dxlProductWithAddress.technology) || ""
        ],
        product_target_segment: [
            "business"
        ],
        product_quantity: "1"
    };
};
export var getVisitorTrackInformation = function(queryClient) {
    var ref, ref1, ref2, ref3, ref4;
    var authType = useContext(AuthenticationContext).authType;
    var SHA256 = crypto.SHA256;
    var visitor = queryClient.getQueryData("CBProfile");
    var billingObjActive = visitor === null || visitor === void 0 ? void 0 : (ref = visitor.billingObjectDataList) === null || ref === void 0 ? void 0 : ref.find(function(e) {
        return (e === null || e === void 0 ? void 0 : e.status) === "Active";
    });
    return {
        visitor_id_asset_active: getAssetId(billingObjActive),
        visitor_id_asset_primary: getAssetId(billingObjActive),
        visitor_asset_plan_type_active: getPlanType(billingObjActive),
        visitor_id_email: (visitor === null || visitor === void 0 ? void 0 : (ref1 = visitor.profileData) === null || ref1 === void 0 ? void 0 : ref1.email) ? SHA256(visitor === null || visitor === void 0 ? void 0 : (ref2 = visitor.profileData) === null || ref2 === void 0 ? void 0 : ref2.email).toString() : "",
        visitor_login_status: getLoginStatus(authType),
        visitor_type: authType,
        visitor_customer_type: "business",
        visitor_login_authorisation_role: getProfileRole(visitor === null || visitor === void 0 ? void 0 : (ref3 = visitor.profileData) === null || ref3 === void 0 ? void 0 : ref3.profile),
        visitor_customer_segment: (visitor === null || visitor === void 0 ? void 0 : (ref4 = visitor.assetData) === null || ref4 === void 0 ? void 0 : ref4.segment) || ""
    };
};
var getAssetId = function(billingObjActive) {
    var SHA256 = crypto.SHA256;
    if (billingObjActive) {
        return (billingObjActive === null || billingObjActive === void 0 ? void 0 : billingObjActive.subscriptionName) === "SIM_MOBILE" ? SHA256(billingObjActive === null || billingObjActive === void 0 ? void 0 : billingObjActive.msisdn).toString() : SHA256(billingObjActive === null || billingObjActive === void 0 ? void 0 : billingObjActive.cli).toString();
    }
    return "";
};
var getProfileRole = function(profile) {
    if (profile) {
        if (profile === "BUSINESS_MASTER_REFERENT" || profile === "BUSINESS_OPERATIVE_REFERENT") return "AccountManager";
        return "EndUser";
    }
    return "";
};
var getPlanType = function(obj) {
    if (obj) {
        if ((obj === null || obj === void 0 ? void 0 : obj.subscriptionName) !== "SIM_MOBILE") return "fixed";
        if ((obj === null || obj === void 0 ? void 0 : obj.paymentType) === "Prepaid") return "payg";
        return "paym";
    }
    return "";
};
var getLoginStatus = function(authType) {
    if (authType === EAuthType.CB) return "logged in";
    return "logged out";
};
export var getFormattedPriceString = function(dxlProduct) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    if ((dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref = dxlProduct.costs) === null || ref === void 0 ? void 0 : ref.renewalCost) > 0 && (!(dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref1 = dxlProduct.costs) === null || ref1 === void 0 ? void 0 : ref1.discountedRenewalCost) || (dxlProduct === null || dxlProduct === void 0 ? void 0 : dxlProduct.costs.discountedRenewalCost) === 0)) return (dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref2 = dxlProduct.costs) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.renewalCost) === null || ref3 === void 0 ? void 0 : ref3.toFixed(2)) || "";
    if ((dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref4 = dxlProduct.costs) === null || ref4 === void 0 ? void 0 : ref4.renewalCost) > 0 && (dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref5 = dxlProduct.costs) === null || ref5 === void 0 ? void 0 : ref5.discountedRenewalCost) > 0) return (dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref6 = dxlProduct.costs) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.discountedRenewalCost) === null || ref7 === void 0 ? void 0 : ref7.toFixed(2)) || "";
    return "";
};
export var getFormattedPriceStringDS = function(costs) {
    var ref, ref1, ref2, ref3;
    if ((costs === null || costs === void 0 ? void 0 : costs.renewalCost) && (costs === null || costs === void 0 ? void 0 : costs.renewalCost) > 0 && (!(costs === null || costs === void 0 ? void 0 : costs.discountedRenewalCost) || costs.discountedRenewalCost === 0)) return (costs === null || costs === void 0 ? void 0 : (ref = costs.renewalCost) === null || ref === void 0 ? void 0 : ref.toFixed(2)) || "";
    if ((costs === null || costs === void 0 ? void 0 : costs.renewalCost) && (costs === null || costs === void 0 ? void 0 : costs.renewalCost) > 0 && (costs === null || costs === void 0 ? void 0 : costs.discountedRenewalCost) && (costs === null || costs === void 0 ? void 0 : costs.discountedRenewalCost) > 0) return (costs === null || costs === void 0 ? void 0 : (ref1 = costs.discountedRenewalCost) === null || ref1 === void 0 ? void 0 : ref1.toFixed(2)) || "";
    if ((costs === null || costs === void 0 ? void 0 : costs.renewalCost) === 0 && (costs === null || costs === void 0 ? void 0 : costs.activationCost) && (costs === null || costs === void 0 ? void 0 : costs.activationCost) > 0 && !(costs === null || costs === void 0 ? void 0 : costs.discountedActivationCost)) return (costs === null || costs === void 0 ? void 0 : (ref2 = costs.activationCost) === null || ref2 === void 0 ? void 0 : ref2.toFixed(2)) || "";
    if ((costs === null || costs === void 0 ? void 0 : costs.renewalCost) === 0 && (costs === null || costs === void 0 ? void 0 : costs.activationCost) && (costs === null || costs === void 0 ? void 0 : costs.activationCost) > 0 && (costs === null || costs === void 0 ? void 0 : costs.discountedActivationCost) && (costs === null || costs === void 0 ? void 0 : costs.discountedActivationCost) > 0) return (costs === null || costs === void 0 ? void 0 : (ref3 = costs.discountedActivationCost) === null || ref3 === void 0 ? void 0 : ref3.toFixed(2)) || "";
    return "";
};
export var getEventLabel = function(type) {
    var actionType = type ? +type : 0;
    switch(actionType){
        case IActionType.LOCAL_URL:
            return ":Scopri di piu";
        case IActionType.CALL_ME_NOW:
            return ":CTC";
        case IActionType.COVERAGE_TOOL:
            return ":Verifica copertura";
        case IActionType.CHECKOUT_MODAL_MOBILE:
            return ":Attiva ora";
        default:
            return "";
    }
};
export var getCoreOfferInformation = function(queryClient, allProducts, selectedProduct) {
    var productsInformation = [];
    if (allProducts) {
        productsInformation = allProducts === null || allProducts === void 0 ? void 0 : allProducts.reduce(function(acc, currentValue) {
            var productTrackingInformation = getProductTrackInformation(queryClient, currentValue);
            return {
                product_id: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_id) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_id)),
                product_name: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_name) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_name)),
                product_category: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_category) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_category)),
                product_price: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_price) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_price)),
                product_target_segment: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_target_segment) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_target_segment)),
                product_technology: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_technology) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_technology)),
                product_quantity: allProducts === null || allProducts === void 0 ? void 0 : allProducts.length.toString()
            };
        }, {});
    } else if (selectedProduct) {
        var productTrackingInformation = getProductTrackInformation(queryClient, selectedProduct);
        productsInformation = {
            product_id: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_id,
            product_name: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_name,
            product_category: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_category,
            product_price: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_price,
            product_target_segment: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_target_segment,
            product_technology: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_technology,
            product_quantity: "1"
        };
    }
    return productsInformation;
};
export var getDeviceTrackInformation = function(queryClient, currentValue, selectedResource) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    var dxlDevice = queryClient.getQueryData([
        currentValue === null || currentValue === void 0 ? void 0 : currentValue.deviceId,
        "ecommerceCatalogDeviceInfo", 
    ]);
    var device = (dxlDevice === null || dxlDevice === void 0 ? void 0 : (ref = dxlDevice.deviceInfo) === null || ref === void 0 ? void 0 : ref[0]) || currentValue;
    return selectedResource ? {
        product_id: [
            (device === null || device === void 0 ? void 0 : device.deviceId) || "",
            (selectedResource === null || selectedResource === void 0 ? void 0 : (ref1 = selectedResource.product) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.productSpecificationId) || "", 
        ],
        product_name: [
            (device === null || device === void 0 ? void 0 : device.deviceName) || "",
            (selectedResource === null || selectedResource === void 0 ? void 0 : (ref3 = selectedResource.product) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.productName) || ""
        ],
        product_category: [
            (device === null || device === void 0 ? void 0 : device.resourceCategoryDescription) || "",
            (selectedResource === null || selectedResource === void 0 ? void 0 : (ref5 = selectedResource.product) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : ref6.productType) || "", 
        ],
        product_price: [
            getFormattedPriceString(selectedResource),
            getFormattedPriceString(selectedResource === null || selectedResource === void 0 ? void 0 : (ref7 = selectedResource.product) === null || ref7 === void 0 ? void 0 : ref7[0]), 
        ],
        product_target_segment: [
            "business",
            "business"
        ],
        product_quantity: "1"
    } : {
        product_id: [
            (device === null || device === void 0 ? void 0 : device.deviceId) || ""
        ],
        product_name: [
            (device === null || device === void 0 ? void 0 : device.deviceName) || ""
        ],
        product_category: [
            (device === null || device === void 0 ? void 0 : device.resourceCategoryDescription) || ""
        ],
        product_price: [
            device === null || device === void 0 ? void 0 : device.lowerCost
        ],
        product_target_segment: [
            "business"
        ],
        product_quantity: "1"
    };
};
export var getDeviceInformation = function(queryClient, isAllProducts, selectedProduct) {
    var productsInformation = [];
    if (isAllProducts) {
        var deviceCatalog = queryClient.getQueryData("ecommerceCatalogDevices");
        var devices = deviceCatalog === null || deviceCatalog === void 0 ? void 0 : deviceCatalog.deviceInfo;
        productsInformation = devices === null || devices === void 0 ? void 0 : devices.reduce(function(acc, currentValue) {
            var productTrackingInformation = getDeviceTrackInformation(queryClient, currentValue);
            return {
                product_id: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_id) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_id)),
                product_name: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_name) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_name)),
                product_category: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_category) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_category)),
                product_price: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_price) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_price)),
                product_target_segment: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_target_segment) || []).concat(_to_consumable_array(productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_target_segment)),
                product_quantity: devices === null || devices === void 0 ? void 0 : devices.length.toString()
            };
        }, {});
    } else if (selectedProduct) {
        var productTrackingInformation = getDeviceTrackInformation(queryClient, selectedProduct);
        productsInformation = {
            product_id: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_id,
            product_name: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_name,
            product_category: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_category,
            product_price: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_price,
            product_target_segment: productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_target_segment,
            product_quantity: "1"
        };
    }
    return productsInformation;
};
export var getDigitalSolutionTrackInformation = function(queryClient, currentValue, editionInfoId) {
    var ref, ref1, ref2, ref3, ref4;
    var dxlDigitalSolution = queryClient.getQueryData([
        currentValue === null || currentValue === void 0 ? void 0 : currentValue.dxlId,
        "ecommerceCatalogDSOffer", 
    ]);
    var digitalSolution = (dxlDigitalSolution === null || dxlDigitalSolution === void 0 ? void 0 : (ref = dxlDigitalSolution.businessSolutionInfo) === null || ref === void 0 ? void 0 : ref[0]) || currentValue;
    var isMultipleDS = !!currentValue.multipleDS;
    if (isMultipleDS) {
        var ref5, ref6;
        var elements = editionInfoId ? currentValue === null || currentValue === void 0 ? void 0 : (ref5 = currentValue.multipleDS) === null || ref5 === void 0 ? void 0 : ref5.elements.filter(function(item) {
            return item.dxlId === editionInfoId;
        }) : currentValue === null || currentValue === void 0 ? void 0 : (ref6 = currentValue.multipleDS) === null || ref6 === void 0 ? void 0 : ref6.elements;
        var totalEditionInfo = elements === null || elements === void 0 ? void 0 : elements.reduce(function(acc, current) {
            var ref, ref1, ref2;
            var currentEditionInfo = dxlDigitalSolution === null || dxlDigitalSolution === void 0 ? void 0 : (ref = dxlDigitalSolution.businessSolutionInfo) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.editionInfo) === null || ref2 === void 0 ? void 0 : ref2.find(function(item) {
                return item.productSpecificationId === current.dxlId;
            });
            return {
                product_id: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_id) || []).concat([
                    current.dxlId || ""
                ]),
                product_name: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_name) || []).concat([
                    "".concat((digitalSolution === null || digitalSolution === void 0 ? void 0 : digitalSolution.overrideOfferName) || (digitalSolution === null || digitalSolution === void 0 ? void 0 : digitalSolution.businessSolutionName), " - ").concat(current.overrideOfferName || (currentEditionInfo === null || currentEditionInfo === void 0 ? void 0 : currentEditionInfo.productName) || ""), 
                ]),
                product_category: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_category) || []).concat([
                    (currentEditionInfo === null || currentEditionInfo === void 0 ? void 0 : currentEditionInfo.productType) || "DS", 
                ]),
                product_price: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_price) || []).concat([
                    current.taggingProductPrice || getFormattedPriceStringDS(currentEditionInfo === null || currentEditionInfo === void 0 ? void 0 : currentEditionInfo.costs), 
                ]),
                product_target_segment: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_target_segment) || []).concat([
                    "business"
                ]),
                product_quantity: elements === null || elements === void 0 ? void 0 : elements.length.toString()
            };
        }, {});
        return totalEditionInfo;
    }
    return {
        product_id: [
            (digitalSolution === null || digitalSolution === void 0 ? void 0 : digitalSolution.dxlId) || (digitalSolution === null || digitalSolution === void 0 ? void 0 : digitalSolution.businessSolutionId) || ""
        ],
        product_name: [
            (digitalSolution === null || digitalSolution === void 0 ? void 0 : digitalSolution.overrideOfferName) || (digitalSolution === null || digitalSolution === void 0 ? void 0 : digitalSolution.businessSolutionName) || "", 
        ],
        product_category: [
            (digitalSolution === null || digitalSolution === void 0 ? void 0 : (ref1 = digitalSolution.editionInfo) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.productType) || "DS"
        ],
        product_price: [
            (digitalSolution === null || digitalSolution === void 0 ? void 0 : digitalSolution.taggingProductPrice) || getFormattedPriceStringDS(digitalSolution === null || digitalSolution === void 0 ? void 0 : (ref3 = digitalSolution.editionInfo) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.costs), 
        ],
        product_target_segment: [
            "business"
        ],
        product_quantity: "1"
    };
};
export var getDigitalSolutionInformation = function(queryClient, isAllProducts, selectedProduct) {
    var productsInformation = [];
    if (isAllProducts) {
        var digitalSolutionCatalog = queryClient.getQueryData("ecommerceCatalogDigitalSolutions");
        var digitalSolutions = digitalSolutionCatalog === null || digitalSolutionCatalog === void 0 ? void 0 : digitalSolutionCatalog.businessSolutionInfo;
        productsInformation = digitalSolutions === null || digitalSolutions === void 0 ? void 0 : digitalSolutions.reduce(function(acc, currentValue) {
            var productTrackingInformation = getDigitalSolutionTrackInformation(queryClient, currentValue);
            return {
                product_id: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_id) || []).concat(_to_consumable_array(productTrackingInformation.product_id)),
                product_name: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_name) || []).concat(_to_consumable_array(productTrackingInformation.product_name)),
                product_category: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_category) || []).concat(_to_consumable_array(productTrackingInformation.product_category)),
                product_target_segment: _to_consumable_array((acc === null || acc === void 0 ? void 0 : acc.product_target_segment) || []).concat(_to_consumable_array(productTrackingInformation.product_target_segment)),
                product_quantity: digitalSolutions === null || digitalSolutions === void 0 ? void 0 : digitalSolutions.length.toString()
            };
        }, {});
    } else if (selectedProduct) {
        var productTrackingInformation = getDigitalSolutionTrackInformation(queryClient, selectedProduct);
        productsInformation = {
            product_id: (productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_id) || "",
            product_name: (productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_name) || "",
            product_category: (productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_category) || "",
            product_price: (productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_price) || "",
            product_target_segment: (productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_target_segment) || "",
            product_quantity: (productTrackingInformation === null || productTrackingInformation === void 0 ? void 0 : productTrackingInformation.product_quantity) || ""
        };
    }
    return productsInformation;
};
