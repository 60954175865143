import { ErrorAction, errorManager, ErrorSeverity, PageLoadingContext, useHandleQueryWithAuthSSO } from "@vfit/business/providers";
import { useContext } from "react";
import { getEcommerceCatalogDigitalSolutions } from "../../api/getEcommerceCatalog/getEcommerceCatalogDigitalSolutions";
var useGetEcommerceCatalogDigitalSolutions = function(param) {
    var limit = param.limit, category = param.category;
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var sortByPositionDS = function(dataDS) {
        var ref;
        return (ref = dataDS.businessSolutionInfo) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
            var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
            var posA = (ref2 = (ref1 = (ref = a.editionInfo) === null || ref === void 0 ? void 0 : ref[0].characteristic) === null || ref1 === void 0 ? void 0 : ref1.find(function(c) {
                return c.name === "eCommercePosition";
            })) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.value) === null || ref3 === void 0 ? void 0 : ref3[0];
            var posB = (ref6 = (ref5 = (ref4 = b.editionInfo) === null || ref4 === void 0 ? void 0 : ref4[0].characteristic) === null || ref5 === void 0 ? void 0 : ref5.find(function(c) {
                return c.name === "eCommercePosition";
            })) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.value) === null || ref7 === void 0 ? void 0 : ref7[0];
            if ((posA === null || posA === void 0 ? void 0 : posA.value) && (posB === null || posB === void 0 ? void 0 : posB.value)) return Number(posA === null || posA === void 0 ? void 0 : posA.value) > Number(posB === null || posB === void 0 ? void 0 : posB.value) ? 1 : -1;
            return (posA === null || posA === void 0 ? void 0 : posA.value) ? -1 : 1;
        });
    };
    var getAllDigitalSolutions = {
        queryKey: "ecommerceCatalogDigitalSolutions",
        queryFn: function() {
            return getEcommerceCatalogDigitalSolutions({
                limit: limit,
                offset: limit && "0",
                productType: category && [
                    category
                ]
            });
        },
        options: {
            onSuccess: function(data) {
                return sortByPositionDS(data);
            },
            onError: function(error) {
                var status = error.status;
                var statusNumber = Number(status);
                errorManager.handleError(ErrorSeverity.HIGH, {
                    errorAction: ErrorAction.PAGE,
                    errorCode: statusNumber
                });
            },
            onSettled: function() {
                setIsLoading(false);
            }
        }
    };
    var data = useHandleQueryWithAuthSSO(getAllDigitalSolutions).data;
    return {
        data: data
    };
};
export { useGetEcommerceCatalogDigitalSolutions };
