export var formatDxlCosts = function(data) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    var newData = data;
    if ((newData === null || newData === void 0 ? void 0 : (ref = newData.productInfo) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.costs) && Array.isArray(newData === null || newData === void 0 ? void 0 : (ref2 = newData.productInfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.costs)) {
        var ref7, ref8;
        newData.productInfo[0].costs = (ref7 = newData.productInfo) === null || ref7 === void 0 ? void 0 : (ref8 = ref7[0]) === null || ref8 === void 0 ? void 0 : ref8.costs[0];
    }
    (ref4 = newData.productInfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.addOn) === null || ref6 === void 0 ? void 0 : ref6.map(function(a, i) {
        if (Array.isArray(a === null || a === void 0 ? void 0 : a.costs)) {
            var ref, ref1, ref2;
            if ((ref1 = (ref = newData.productInfo) === null || ref === void 0 ? void 0 : ref[0].addOn) === null || ref1 === void 0 ? void 0 : ref1[i]) newData.productInfo[0].addOn[i].costs = a === null || a === void 0 ? void 0 : (ref2 = a.costs) === null || ref2 === void 0 ? void 0 : ref2[0];
        }
    });
    return newData;
};
export var formatDxlCostsDS = function(data) {
    var ref, ref1, ref2, ref3, // edition cost
    ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18;
    var newData = data;
    // business solution cost
    if (((ref = newData.businessSolutionInfo) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.costs) && Array.isArray((ref2 = newData.businessSolutionInfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.costs)) {
        var ref19, ref20, ref21;
        newData.businessSolutionInfo[0].costs = (ref19 = newData.businessSolutionInfo) === null || ref19 === void 0 ? void 0 : (ref20 = ref19[0]) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.costs) === null || ref21 === void 0 ? void 0 : ref21[0];
    }
    (ref4 = newData.businessSolutionInfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.editionInfo) === null || ref6 === void 0 ? void 0 : ref6.forEach(function(item, i) {
        var ref;
        if (((ref = newData.businessSolutionInfo) === null || ref === void 0 ? void 0 : ref[0].editionInfo) && item.costs && Array.isArray(item.costs)) {
            var ref1;
            newData.businessSolutionInfo[0].editionInfo[i].costs = (ref1 = item.costs) === null || ref1 === void 0 ? void 0 : ref1[0];
        }
    });
    // device cost
    if (((ref7 = newData.businessSolutionInfo) === null || ref7 === void 0 ? void 0 : (ref8 = ref7[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.editionInfo) === null || ref9 === void 0 ? void 0 : (ref10 = ref9[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.device) === null || ref11 === void 0 ? void 0 : (ref12 = ref11[0]) === null || ref12 === void 0 ? void 0 : ref12.costs) && Array.isArray((ref13 = newData.businessSolutionInfo) === null || ref13 === void 0 ? void 0 : (ref14 = ref13[0]) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.editionInfo) === null || ref15 === void 0 ? void 0 : (ref16 = ref15[0]) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.device) === null || ref17 === void 0 ? void 0 : (ref18 = ref17[0]) === null || ref18 === void 0 ? void 0 : ref18.costs)) {
        var ref22, ref23, ref24, ref25, ref26, ref27, ref28;
        newData.businessSolutionInfo[0].editionInfo[0].device[0].costs = (ref22 = newData.businessSolutionInfo) === null || ref22 === void 0 ? void 0 : (ref23 = ref22[0]) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.editionInfo) === null || ref24 === void 0 ? void 0 : (ref25 = ref24[0]) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.device) === null || ref26 === void 0 ? void 0 : (ref27 = ref26[0]) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.costs) === null || ref28 === void 0 ? void 0 : ref28[0];
    }
    return newData;
};
export var getCharacteristicValues = function(characteristic, name) {
    var ref, ref1;
    var values = (ref = characteristic === null || characteristic === void 0 ? void 0 : characteristic.find(function(ch) {
        return ch.name === name;
    })) === null || ref === void 0 ? void 0 : ref.value;
    return (values === null || values === void 0 ? void 0 : (ref1 = values[0]) === null || ref1 === void 0 ? void 0 : ref1.value) || "";
};
