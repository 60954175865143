import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  body {\n    overflow: hidden;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n  }\n\n  header {\n    display: none !important;\n  }\n\n  #sticky-offer-product {\n    display: none;\n  }\n\n  #sticky-offer-summary {\n    visibility: hidden;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { createGlobalStyle } from "styled-components";
export var OverlayGlobalStyle = createGlobalStyle(_templateObject());
