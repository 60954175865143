import { COVERAGE_TOOL_FLOW } from "./coverageToolModal.flow";
var getCoverageToolFlow = function() {
    return COVERAGE_TOOL_FLOW.DEFAULT;
};
var organizeFlowsAppend = function(flowCards, enableAppend, idFromStep, appendSteps) {
    var indexAppend = flowCards.findIndex(function(el) {
        return el.title === idFromStep;
    });
    var organizedFlows = flowCards.slice(0, indexAppend + 1);
    var lastFlows = flowCards.slice(indexAppend + 1);
    if (enableAppend) {
        var alreadyPresent = appendSteps[0].title === lastFlows[0].title;
        if (alreadyPresent) return flowCards;
        organizedFlows = organizedFlows.concat(appendSteps);
        organizedFlows = organizedFlows.concat(lastFlows);
    } else if (lastFlows[0].title === appendSteps[0].title) {
        var removedAppendInfo = lastFlows.slice(appendSteps.length);
        organizedFlows = organizedFlows.concat(removedAppendInfo);
    } else if ((appendSteps === null || appendSteps === void 0 ? void 0 : appendSteps.findIndex(function(aS) {
        return aS.title === lastFlows[0].title;
    })) > -1) {
        var removedAppendInfo1 = lastFlows.slice(1);
        organizedFlows = organizedFlows.concat(removedAppendInfo1);
    } else {
        organizedFlows = organizedFlows.concat(lastFlows);
    }
    return organizedFlows;
};
export { getCoverageToolFlow, organizeFlowsAppend };
