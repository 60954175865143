import { useContext } from "react";
import { ITimerStatus, RetargetContext } from "@vfit/business/providers";
export var getProductVersion = function(id, productVersion, isPrive) {
    var ref = useContext(RetargetContext), checkRetargetActive = ref.checkRetargetActive, versionAEM = ref.versionAEM;
    var currentState = checkRetargetActive({
        productId: id,
        version: versionAEM || productVersion
    });
    var version = (versionAEM || productVersion) && (currentState === ITimerStatus.ACTIVE || isPrive === "true") && (versionAEM || productVersion);
    return version || "";
};
