import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  p {\n    margin-bottom: 120px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var TextWrapperContainer = styled.div.withConfig({
    componentId: "sc-f225adf9-0"
})(_templateObject());
