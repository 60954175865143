import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  width: 100%;\n  z-index: 1;\n  top: 0;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    box-shadow: 0px 3px 10px 0px ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, colors } from "@vfit/shared/themes";
import styled, { css } from "styled-components";
var fixedCss = css(_templateObject());
export var HeaderWrapper = styled.div.withConfig({
    componentId: "sc-d6a6a60f-0"
})(_templateObject1(), colors.$ffffff, function(param) {
    var fixed = param.fixed;
    return fixed ? fixedCss : "";
}, breakpoints.desktop, colors.$000000_16);
