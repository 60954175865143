import { useContext } from "react";
import { IActionType } from "@vfit/shared/models";
import { CTC_POSITION, useVolaUtils } from "@vfit/business/data-access";
import { TrackingPageContext } from "@vfit/business/providers";
export var useWhatsappModalAction = function() {
    var descriptionVolaUrl = useContext(TrackingPageContext).descriptionVolaUrl;
    var ref = useVolaUtils(), formatUrlVola = ref.formatUrlVola, openPopupVola = ref.openPopupVola, createUrlDesc = ref.createUrlDesc, formatDynamicVolaUrl = ref.formatDynamicVolaUrl;
    var handleClick = function(param, product) {
        var desc = createUrlDesc({
            position: CTC_POSITION.WHATSAPP_MODAL,
            productName: (product === null || product === void 0 ? void 0 : product.taggingProductName) || (product === null || product === void 0 ? void 0 : product.title) || "",
            productPrice: product === null || product === void 0 ? void 0 : product.taggingProductPrice
        });
        var type = (param === null || param === void 0 ? void 0 : param.type) ? +param.type : 0;
        switch(type){
            case IActionType.CUSTOM_URL:
                if (param === null || param === void 0 ? void 0 : param.url) {
                    window.open(param === null || param === void 0 ? void 0 : param.url, (param === null || param === void 0 ? void 0 : param.isBlank) === "true" ? "_blank" : "_self");
                }
                break;
            case IActionType.CALL_ME_NOW:
                if (param === null || param === void 0 ? void 0 : param.url) {
                    if (descriptionVolaUrl && desc) openPopupVola(formatDynamicVolaUrl(param === null || param === void 0 ? void 0 : param.url, desc), "vola-ctc");
                    else openPopupVola(formatUrlVola(param === null || param === void 0 ? void 0 : param.url, product), "vola-ctc");
                }
                break;
            default:
                break;
        }
    };
    return {
        handleClick: handleClick
    };
};
