import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-top: 64px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n\n  a {\n    color: ",
        " !important;\n\n    :visited {\n      color: ",
        " !important;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { colors, fonts } from "@vfit/shared/themes";
import styled from "styled-components";
var WidgetContainer = styled.div.withConfig({
    componentId: "sc-89f311ab-0"
})(_templateObject(), function(param) {
    var isLastWidget = param.isLastWidget;
    return isLastWidget ? "109px" : 0;
});
var WidgetDivider = styled.div.withConfig({
    componentId: "sc-89f311ab-1"
})(_templateObject1());
var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-89f311ab-2"
})(_templateObject2(), fonts.regular, colors.$7e7e7e, colors.$262626, colors.$262626);
export { WidgetContainer, WidgetDivider, DisclaimerContainer };
