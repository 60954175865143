import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { getActionUrlByActionType } from "@vfit/shared/data-access";
export var getFormatprops = function(elements) {
    return elements.map(function(el) {
        return _object_spread({
            image: el === null || el === void 0 ? void 0 : el.image,
            frontLabel: el === null || el === void 0 ? void 0 : el.frontLabel,
            description: el === null || el === void 0 ? void 0 : el.descriptionHtml,
            title: el === null || el === void 0 ? void 0 : el.title,
            action: el === null || el === void 0 ? void 0 : el.action,
            key: el === null || el === void 0 ? void 0 : el.addonId
        }, (el === null || el === void 0 ? void 0 : el.action) && {
            actionNavigationUrl: getActionUrlByActionType(el === null || el === void 0 ? void 0 : el.action)
        });
    });
};
