import { CMS_CONFIG, useCmsConfig, useGetEcommerceCatalogDSById } from "@vfit/business/data-access";
import { API } from "@vfit/shared/data-access";
import { getDSHeroBanner } from "./widgetProductHeroBannerDS.utils";
export var useWidgetProductHeroBannerDS = function(widget) {
    var ref, ref1, ref2;
    var placeholders = useCmsConfig(CMS_CONFIG["BUSINESS_DSIOT_PDP"], API.CMS_DSIOT_GET_COMMON_PLACEHOLDER).placeholders;
    var data = useGetEcommerceCatalogDSById([
        widget === null || widget === void 0 ? void 0 : widget.product
    ]).data;
    var topProduct = getDSHeroBanner(placeholders === null || placeholders === void 0 ? void 0 : placeholders.placeholders, widget === null || widget === void 0 ? void 0 : widget.product, data === null || data === void 0 ? void 0 : (ref = data[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.businessSolutionInfo) === null || ref1 === void 0 ? void 0 : ref1[0]);
    return {
        product: topProduct,
        disclaimer: widget.descriptionHtml,
        description: widget.disclaimerHtml,
        divider: widget.divider,
        disableNavigation: !(widget === null || widget === void 0 ? void 0 : (ref2 = widget.product) === null || ref2 === void 0 ? void 0 : ref2.backLabel),
        offerDetails: widget === null || widget === void 0 ? void 0 : widget.customAction
    };
};
