import { useContext, useEffect } from "react";
import { formatDxlCostsDS, getEcommerceCatalogDSById } from "@vfit/business/data-access";
import { errorManager, ErrorAction, ErrorSeverity, PageLoadingContext, useHandleQueriesWithAuthSSO } from "@vfit/business/providers";
import { PAGES } from "@vfit/shared/data-access";
var useGetEcommerceCatalogDSById = function(elements) {
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var queriesDS = [];
    elements.forEach(function(item) {
        if (item.isEditorialCard === "true") return;
        queriesDS.push({
            queryKey: [
                item.dxlId,
                "ecommerceCatalogDSOffer"
            ],
            queryFn: function() {
                return getEcommerceCatalogDSById({
                    businessSolutionId: [
                        (item === null || item === void 0 ? void 0 : item.dxlId) || ""
                    ]
                });
            },
            options: {
                onSuccess: function(data) {
                    return formatDxlCostsDS(data);
                },
                onError: function(error) {
                    var status = error.status;
                    var statusNumber = Number(status);
                    errorManager.handleError(ErrorSeverity.HIGH, {
                        errorAction: ErrorAction.PAGE,
                        errorCode: statusNumber
                    });
                },
                onSettled: function() {
                    setIsLoading(false);
                }
            }
        });
    });
    var queriesResults = useHandleQueriesWithAuthSSO(queriesDS);
    useEffect(function() {
        if (Object.values(queriesResults).some(function(item) {
            return item.status !== "success";
        })) return;
        if (elements.length === Object.values(queriesResults).length && Object.values(queriesResults).every(function(item) {
            return item.status === "success";
        }) && Object.values(queriesResults).every(function(item) {
            var ref;
            return ((ref = item.data) === null || ref === void 0 ? void 0 : ref.businessSolutionInfo.length) === 0;
        })) {
            errorManager.handleError(ErrorSeverity.HIGH, {
                errorAction: ErrorAction.PAGE,
                errorPage: PAGES.OFFER_ERROR
            });
        } else {
            setIsLoading(false);
        }
    }, [
        queriesResults
    ]);
    var catalog = Object.values(queriesResults).map(function(query) {
        return query.data;
    }).filter(Boolean);
    return {
        data: catalog
    };
};
export { useGetEcommerceCatalogDSById };
