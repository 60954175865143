import { getOfferName, getPrice, getPriceDescription, getUnaTantumPriceDescription, getCharacteristicValues } from "@vfit/business/data-access";
import { getFormattedPriceString } from "@vfit/business/providers";
var formatProducts = function(elements, placeholder) {
    var productInfo = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    var products = elements.map(function(el) {
        var productCatalog = productInfo.find(function(item) {
            var ref;
            return item.productSpecificationId === (el === null || el === void 0 ? void 0 : (ref = el.dxlId) === null || ref === void 0 ? void 0 : ref.toString());
        });
        if ((el === null || el === void 0 ? void 0 : el.isEditorialCard) === "false" && !productCatalog) return undefined;
        return {
            tagLine: el === null || el === void 0 ? void 0 : el.tagLine,
            offerName: (el === null || el === void 0 ? void 0 : el.overrideOfferName) || productCatalog && getOfferName(productCatalog, placeholder),
            frontLabel: el === null || el === void 0 ? void 0 : el.frontLabel,
            bottomLabel: el === null || el === void 0 ? void 0 : el.offerLabel,
            overrideCategory: el === null || el === void 0 ? void 0 : el.overrideCategory,
            additionalOfferDetails: el === null || el === void 0 ? void 0 : el.additionalOfferDetails,
            offerSpecs: el === null || el === void 0 ? void 0 : el.shortCharacteristics,
            offerId: el === null || el === void 0 ? void 0 : el.dxlId,
            isEditorialCard: el === null || el === void 0 ? void 0 : el.isEditorialCard,
            taggingProductPrice: (el === null || el === void 0 ? void 0 : el.taggingProductPrice) || getFormattedPriceString(productCatalog) || "",
            priceDescription: (el === null || el === void 0 ? void 0 : el.overrideCostActivationHtml) || productCatalog && getPriceDescription(productCatalog, placeholder),
            unaTantumPriceDescription: (el === null || el === void 0 ? void 0 : el.overrideUnaTantumCostActivationHtml) || productCatalog && getUnaTantumPriceDescription(productCatalog, placeholder),
            shortDescription: el === null || el === void 0 ? void 0 : el.shortDescription,
            image: el === null || el === void 0 ? void 0 : el.imageBackgroundUrl,
            price: (el === null || el === void 0 ? void 0 : el.overrideCostRenewalHtml) || productCatalog && getPrice(productCatalog, placeholder),
            actions: el === null || el === void 0 ? void 0 : el.actions,
            pills: el === null || el === void 0 ? void 0 : el.tags,
            retarget: el === null || el === void 0 ? void 0 : el.retarget,
            version: el === null || el === void 0 ? void 0 : el.version,
            taggingProductName: (el === null || el === void 0 ? void 0 : el.taggingProductName) || productCatalog && getOfferName(productCatalog, placeholder),
            timeDiscount: productCatalog === null || productCatalog === void 0 ? void 0 : productCatalog.timeDiscount,
            timeDiscountLabels: {
                timeDiscountTag: getCharacteristicValues((productCatalog === null || productCatalog === void 0 ? void 0 : productCatalog.characteristic) || [], "timeDiscountTag"),
                timeDiscountDetails: getCharacteristicValues((productCatalog === null || productCatalog === void 0 ? void 0 : productCatalog.characteristic) || [], "timeDiscountDetails"),
                timeDiscountTagForDevicePages: getCharacteristicValues((productCatalog === null || productCatalog === void 0 ? void 0 : productCatalog.characteristic) || [], "timeDiscountTagForDevicePages")
            }
        };
    }).filter(Boolean);
    return products;
};
export { formatProducts };
