import { useWidgetsAction } from "../Widgets/widgets.hook";
import { getCardsList } from "./widgetSplittedList.utils";
export var useWidgetSplittedList = function(widget) {
    var cards = (widget === null || widget === void 0 ? void 0 : widget.elements) ? getCardsList(widget === null || widget === void 0 ? void 0 : widget.elements) : [];
    var handleClick = useWidgetsAction().handleClick;
    return {
        elements: cards,
        topText: widget.title,
        description: widget.description,
        disclaimer: widget.disclaimer,
        handleButtonClick: handleClick
    };
};
