import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _type_of from "@swc/helpers/src/_type_of.mjs";
import { PlaceholdersID, PlaceholdersMap } from "@vfit/business/data-access";
import { invalidPlaceholderNumber } from "../api";
var findValue = function(data, key) {
    var arr = key.split(".");
    var newData = data;
    arr.map(function(e) {
        if (_type_of(newData === null || newData === void 0 ? void 0 : newData[e]) !== undefined) newData = newData[e];
        else newData = "";
    });
    return newData;
};
export var getPriceDescription = function(productCatalog, placeholders) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    var data = productCatalog === null || productCatalog === void 0 ? void 0 : (ref = productCatalog.addOn) === null || ref === void 0 ? void 0 : ref.find(function(e) {
        return e.productType === "A";
    });
    var newData = _object_spread_props(_object_spread({}, productCatalog), {
        addon: data
    });
    if ((data === null || data === void 0 ? void 0 : (ref1 = data.costs) === null || ref1 === void 0 ? void 0 : ref1.renewalCost) > 0 && (data === null || data === void 0 ? void 0 : (ref2 = data.costs) === null || ref2 === void 0 ? void 0 : ref2.discountedRenewalCost) === 0) // this condition must always be the first to check
    return getLabel(newData, placeholders, PlaceholdersID.FREE_ACT_RECURRING_COST);
    if ((data === null || data === void 0 ? void 0 : (ref3 = data.costs) === null || ref3 === void 0 ? void 0 : ref3.renewalCost) > 0 && (data === null || data === void 0 ? void 0 : (ref4 = data.costs) === null || ref4 === void 0 ? void 0 : ref4.discountedRenewalCost) > 0) return getLabel(newData, placeholders, PlaceholdersID.DISCOUNTED_ACT_RECURRING_COST);
    if ((data === null || data === void 0 ? void 0 : (ref5 = data.costs) === null || ref5 === void 0 ? void 0 : ref5.renewalCost) > 0 && !(data === null || data === void 0 ? void 0 : (ref6 = data.costs) === null || ref6 === void 0 ? void 0 : ref6.discountedRenewalCost)) return getLabel(newData, placeholders, PlaceholdersID.ACT_RECURRING_COST_WITH_FEE);
    return "";
};
export var getUnaTantumPriceDescription = function(productCatalog, placeholders) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    var data = productCatalog === null || productCatalog === void 0 ? void 0 : (ref = productCatalog.addOn) === null || ref === void 0 ? void 0 : ref.find(function(e) {
        return e.productType === "A";
    });
    var newData = _object_spread_props(_object_spread({}, productCatalog), {
        addon: data
    });
    if ((data === null || data === void 0 ? void 0 : (ref1 = data.costs) === null || ref1 === void 0 ? void 0 : ref1.activationCost) > 0 && (data === null || data === void 0 ? void 0 : (ref2 = data.costs) === null || ref2 === void 0 ? void 0 : ref2.discountedActivationCost) === 0) // this condition must always be the first to check
    return getLabel(newData, placeholders, PlaceholdersID.FREE_ACT_COST);
    if ((data === null || data === void 0 ? void 0 : (ref3 = data.costs) === null || ref3 === void 0 ? void 0 : ref3.activationCost) > 0 && (data === null || data === void 0 ? void 0 : (ref4 = data.costs) === null || ref4 === void 0 ? void 0 : ref4.discountedActivationCost) > 0) return getLabel(newData, placeholders, PlaceholdersID.DISCOUNTED_ACT_COST);
    if ((data === null || data === void 0 ? void 0 : (ref5 = data.costs) === null || ref5 === void 0 ? void 0 : ref5.activationCost) > 0 && !(data === null || data === void 0 ? void 0 : (ref6 = data.costs) === null || ref6 === void 0 ? void 0 : ref6.discountedActivationCost)) return getLabel(newData, placeholders, PlaceholdersID.ACT_COST_WITH_FEE);
    return "";
};
export var getResponsivePrice = function(productCatalog, placeholders) {
    var ref, ref1, ref2;
    if (productCatalog === null || productCatalog === void 0 ? void 0 : (ref = productCatalog.costs) === null || ref === void 0 ? void 0 : ref.discountedRenewalCost) {
        getLabel(productCatalog, placeholders, PlaceholdersID.CORE_OFFER_COST_DISCOUNTED_RESPONSIVE);
    } else {
        getLabel(productCatalog, placeholders, PlaceholdersID.CORE_OFFER_COST_WITH_FEE_RESPONSIVE);
    }
    var price = (productCatalog === null || productCatalog === void 0 ? void 0 : (ref1 = productCatalog.costs) === null || ref1 === void 0 ? void 0 : ref1.discountedRenewalCost) || (productCatalog === null || productCatalog === void 0 ? void 0 : (ref2 = productCatalog.costs) === null || ref2 === void 0 ? void 0 : ref2.renewalCost);
    return getLabel({
        costs: {
            renewalCost: price
        }
    }, placeholders, PlaceholdersID.CORE_OFFER_COST_WITH_FEE_RESPONSIVE);
};
export var getPriceDescriptionShoppingCart = function(productCatalog, placeholders) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    var addon = productCatalog === null || productCatalog === void 0 ? void 0 : (ref = productCatalog.addOn) === null || ref === void 0 ? void 0 : ref.find(function(e) {
        return e.productType === "A";
    });
    var newData = _object_spread_props(_object_spread({}, productCatalog), {
        addon: addon
    });
    if ((addon === null || addon === void 0 ? void 0 : (ref1 = addon.costs) === null || ref1 === void 0 ? void 0 : ref1.renewalCost) > 0 && (addon === null || addon === void 0 ? void 0 : (ref2 = addon.costs) === null || ref2 === void 0 ? void 0 : ref2.discountedRenewalCost) === 0) {
        var ref7;
        return (productCatalog === null || productCatalog === void 0 ? void 0 : (ref7 = productCatalog.costs) === null || ref7 === void 0 ? void 0 : ref7.discountedRenewalCost) ? getLabel(newData, placeholders, PlaceholdersID.FREE_ACT_AND_RECURRING_DISCOUNTED_COST_MAP) : getLabel(newData, placeholders, PlaceholdersID.FREE_ACT_AND_RECURRING_COST_MAP);
    }
    if ((addon === null || addon === void 0 ? void 0 : (ref3 = addon.costs) === null || ref3 === void 0 ? void 0 : ref3.renewalCost) > 0 && (addon === null || addon === void 0 ? void 0 : (ref4 = addon.costs) === null || ref4 === void 0 ? void 0 : ref4.discountedRenewalCost) > 0) {
        var ref8;
        return (productCatalog === null || productCatalog === void 0 ? void 0 : (ref8 = productCatalog.costs) === null || ref8 === void 0 ? void 0 : ref8.discountedRenewalCost) ? getLabel(newData, placeholders, PlaceholdersID.DISCOUNTED_ACT_RECURRING_DISCOUNTED_COST_MAP) : getLabel(newData, placeholders, PlaceholdersID.DISCOUNTED_ACT_RECURRING_COST_MAP);
    }
    if ((addon === null || addon === void 0 ? void 0 : (ref5 = addon.costs) === null || ref5 === void 0 ? void 0 : ref5.renewalCost) > 0 && !(addon === null || addon === void 0 ? void 0 : (ref6 = addon.costs) === null || ref6 === void 0 ? void 0 : ref6.discountedRenewalCost)) {
        var ref9;
        return (productCatalog === null || productCatalog === void 0 ? void 0 : (ref9 = productCatalog.costs) === null || ref9 === void 0 ? void 0 : ref9.discountedRenewalCost) ? getLabel(newData, placeholders, PlaceholdersID.ACT_AND_RECURRING_DISCOUNTED_COST_MAP) : getLabel(newData, placeholders, PlaceholdersID.ACT_AND_RECURRING_COST_MAP);
    }
    return "";
};
export var getPriceDescriptionAddons = function(addon, placeholders) {
    var ref, ref1;
    if ((addon === null || addon === void 0 ? void 0 : (ref = addon.costs) === null || ref === void 0 ? void 0 : ref.renewalCost) > 0 && !(addon === null || addon === void 0 ? void 0 : (ref1 = addon.costs) === null || ref1 === void 0 ? void 0 : ref1.discountedRenewalCost)) {
        return getLabel(addon, placeholders, PlaceholdersID.CORE_OFFER_COST_FOR_ADDON);
    }
    // if (addon?.costs?.renewalCost! > 0 && addon?.costs?.discountedRenewalCost! > 0){
    //   getLabel(addon,placeholders,PlaceholdersID.DISCOUNTED_CORE_OFFER_FOR_ADDON)
    // }
    return "";
};
export var getPrice = function(productCatalog, placeholders) {
    var ref, ref1, ref2, ref3;
    if ((productCatalog === null || productCatalog === void 0 ? void 0 : (ref = productCatalog.costs) === null || ref === void 0 ? void 0 : ref.renewalCost) > 0 && (!(productCatalog === null || productCatalog === void 0 ? void 0 : (ref1 = productCatalog.costs) === null || ref1 === void 0 ? void 0 : ref1.discountedRenewalCost) || productCatalog.costs.discountedRenewalCost === 0)) return getLabel(productCatalog, placeholders, PlaceholdersID.CORE_OFFER_COST_WITH_FEE);
    if ((productCatalog === null || productCatalog === void 0 ? void 0 : (ref2 = productCatalog.costs) === null || ref2 === void 0 ? void 0 : ref2.renewalCost) > 0 && (productCatalog === null || productCatalog === void 0 ? void 0 : (ref3 = productCatalog.costs) === null || ref3 === void 0 ? void 0 : ref3.discountedRenewalCost) > 0) return getLabel(productCatalog, placeholders, PlaceholdersID.CORE_OFFER_COST_DISCOUNTED);
    return "";
};
export var getOfferName = function(productCatalog, placeholders) {
    return getLabel(productCatalog, placeholders, PlaceholdersID.OFFER_NAME);
};
export var getLabel = function(productCatalog, placeholders, key) {
    var ref;
    var label = placeholders.find(function(e) {
        return e.id === key;
    }) || {
        value: ""
    };
    var reg = new RegExp(/([$])\w+/g);
    (ref = label.value.match(reg)) === null || ref === void 0 ? void 0 : ref.map(function(e) {
        var labelValue = findValue(productCatalog, PlaceholdersMap[e.substring(1)]);
        if (typeof labelValue === "number" && isValidLabelNumber(e)) {
            labelValue = formatPrice(labelValue);
        }
        label = _object_spread_props(_object_spread({}, label), {
            value: label.value.replace(e, labelValue)
        });
    });
    return label.value;
};
export var formatPrice = function(val) {
    return val === null || val === void 0 ? void 0 : val.toFixed(2).replace(".", ",");
};
// devices
export var getPriceDescriptionDeviceCard = function(price, description) {
    var ref;
    var value = formatPrice(Number(price));
    var label = {
        value: ""
    };
    var reg = new RegExp(/([$])\w+/g);
    (ref = description.match(reg)) === null || ref === void 0 ? void 0 : ref.map(function(e) {
        label = _object_spread_props(_object_spread({}, label), {
            value: description.replace(e, value)
        });
    });
    return label.value;
};
export var getPriceDevice = function(mapped, placeholders) {
    var data = mapped.costs;
    if ((data === null || data === void 0 ? void 0 : data.activationCost) === 0 && !(data === null || data === void 0 ? void 0 : data.discountedActivationCost)) return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_FREE);
    if ((data === null || data === void 0 ? void 0 : data.activationCost) > 0 && (data === null || data === void 0 ? void 0 : data.discountedActivationCost) >= 0) return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_DISCOUNTED);
    if ((data === null || data === void 0 ? void 0 : data.activationCost) > 0 && !(data === null || data === void 0 ? void 0 : data.discountedActivationCost)) return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_WITH_FEE);
    return "";
};
export var getPriceDeviceSpecificOffer = function(mapped, placeholders) {
    var data = mapped === null || mapped === void 0 ? void 0 : mapped.costs;
    if ((data === null || data === void 0 ? void 0 : data.activationCost) === 0 && !(data === null || data === void 0 ? void 0 : data.discountedActivationCost)) return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_FREE_CUSTOMIZATION);
    if ((data === null || data === void 0 ? void 0 : data.activationCost) > 0 && (data === null || data === void 0 ? void 0 : data.discountedActivationCost) >= 0) return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_DISCOUNTED_CUSTOMIZATION);
    if ((data === null || data === void 0 ? void 0 : data.activationCost) > 0 && !(data === null || data === void 0 ? void 0 : data.discountedActivationCost)) return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_WITH_FEE_CUSTOMIZATION);
    return "";
};
export var sumPriceDeviceAndOffer = function(mapped) {
    var ref, ref1, ref2, ref3, ref4;
    var offer = mapped === null || mapped === void 0 ? void 0 : (ref = mapped.product) === null || ref === void 0 ? void 0 : ref[0];
    var costDevice = (mapped === null || mapped === void 0 ? void 0 : (ref1 = mapped.costs) === null || ref1 === void 0 ? void 0 : ref1.discountedRenewalCost) || (mapped === null || mapped === void 0 ? void 0 : (ref2 = mapped.costs) === null || ref2 === void 0 ? void 0 : ref2.renewalCost) || 0;
    var costOffer = (offer === null || offer === void 0 ? void 0 : (ref3 = offer.costs) === null || ref3 === void 0 ? void 0 : ref3.discountedRenewalCost) || (offer === null || offer === void 0 ? void 0 : (ref4 = offer.costs) === null || ref4 === void 0 ? void 0 : ref4.renewalCost) || 0;
    return costDevice + costOffer;
};
export var getPriceOfferAndDevice = function(mapped, placeholders, key) {
    var sum = sumPriceDeviceAndOffer(mapped);
    return getLabel({
        costs: {
            renewalCost: sum
        }
    }, placeholders, key);
};
export var getPriceOfferDevice = function(mapped, placeholders) {
    var data = mapped.costs;
    if ((data === null || data === void 0 ? void 0 : data.renewalCost) > 0 && (data === null || data === void 0 ? void 0 : data.discountedRenewalCost) >= 0) return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_COST_DISCOUNTED);
    if ((data === null || data === void 0 ? void 0 : data.renewalCost) >= 0 && !(data === null || data === void 0 ? void 0 : data.discountedRenewalCost)) return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_COST_WITH_FEE);
    return "";
};
// DS IOT
export var getDSIOTPrice = function(cost, placeholders) {
    var costObj = {
        costs: _object_spread({}, cost)
    };
    if ((cost === null || cost === void 0 ? void 0 : cost.renewalCost) > 0 && cost.discountedRenewalCost === 0) return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_FREE);
    if ((cost === null || cost === void 0 ? void 0 : cost.renewalCost) > 0 && !(cost === null || cost === void 0 ? void 0 : cost.discountedRenewalCost)) return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_WITH_FEE_DS);
    if ((cost === null || cost === void 0 ? void 0 : cost.renewalCost) > 0 && (cost === null || cost === void 0 ? void 0 : cost.discountedRenewalCost) > 0) return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_DISCOUNTED_DS);
    return getDSIOTUnaTantum(cost, placeholders);
};
export var getDSIOTActivationPrice = function(cost, placeholders) {
    var costObj = {
        costs: _object_spread({}, cost)
    };
    if ((cost === null || cost === void 0 ? void 0 : cost.renewalCost) || (cost === null || cost === void 0 ? void 0 : cost.discountedActivationCost)) {
        if ((cost === null || cost === void 0 ? void 0 : cost.activationCost) > 0 && (cost === null || cost === void 0 ? void 0 : cost.discountedActivationCost) === 0) return getLabel(costObj, placeholders, PlaceholdersID.DISCOUNTED_ACT_COST_FREE);
        if ((cost === null || cost === void 0 ? void 0 : cost.activationCost) > 0 && !(cost === null || cost === void 0 ? void 0 : cost.discountedActivationCost)) return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_ACT_COST_WITH_FEE);
        if ((cost === null || cost === void 0 ? void 0 : cost.activationCost) > 0 && (cost === null || cost === void 0 ? void 0 : cost.discountedActivationCost) > 0) return getLabel(costObj, placeholders, PlaceholdersID.DISCOUNTED_ACT_COST_WITH_FEE);
        if ((cost === null || cost === void 0 ? void 0 : cost.activationCost) === 0 && !(cost === null || cost === void 0 ? void 0 : cost.discountedActivationCost)) return getLabel(costObj, placeholders, PlaceholdersID.TOTAl_ACT_COST_FREE);
        return "";
    }
};
export var getDSIOTResponsivePrice = function(cost, placeholders) {
    var price = (cost === null || cost === void 0 ? void 0 : cost.discountedRenewalCost) || (cost === null || cost === void 0 ? void 0 : cost.renewalCost) || (cost === null || cost === void 0 ? void 0 : cost.activationCost);
    return getLabel({
        costs: {
            renewalCost: price
        }
    }, placeholders, PlaceholdersID.TOTAL_COST_WITH_FEE_DS);
};
export var getDSIOTUnaTantum = function(cost, placeholders) {
    var costObj = {
        costs: _object_spread({}, cost)
    };
    if ((cost === null || cost === void 0 ? void 0 : cost.renewalCost) === 0 && (cost === null || cost === void 0 ? void 0 : cost.activationCost) > 0 && !(cost === null || cost === void 0 ? void 0 : cost.discountedActivationCost)) {
        return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_UNA_TANTUM);
    }
    if ((cost === null || cost === void 0 ? void 0 : cost.renewalCost) === 0 && (cost === null || cost === void 0 ? void 0 : cost.activationCost) > 0 && (cost === null || cost === void 0 ? void 0 : cost.discountedActivationCost) > 0) {
        return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_UNA_TANTUM_DISCOUNTED);
    }
    return "";
};
var isValidLabelNumber = function(label) {
    return !invalidPlaceholderNumber.find(function(e) {
        return e === label;
    });
};
export var getDeviceTotalRenewalCost = function(mapped, placeholders) {
    var data = mapped.costs;
    var costDevice = ((data === null || data === void 0 ? void 0 : data.discountedRenewalCost) >= 0 && (data === null || data === void 0 ? void 0 : data.renewalCost) > 0 ? data === null || data === void 0 ? void 0 : data.discountedRenewalCost : data === null || data === void 0 ? void 0 : data.renewalCost) || 0;
    var renewalNumber = typeof (data === null || data === void 0 ? void 0 : data.renewalNumber) === "number" && (data === null || data === void 0 ? void 0 : data.renewalNumber) > 0 ? data === null || data === void 0 ? void 0 : data.renewalNumber : 1;
    return getLabel({
        costs: {
            renewalCost: costDevice * renewalNumber
        }
    }, placeholders, PlaceholdersID.TOTAL_RENEWAL_COST_DEVICE);
};
export var getDeviceTotalCost = function(mapped, placeholders) {
    var data = mapped.costs;
    var costDevice = ((data === null || data === void 0 ? void 0 : data.discountedRenewalCost) >= 0 && (data === null || data === void 0 ? void 0 : data.renewalCost) > 0 ? data === null || data === void 0 ? void 0 : data.discountedRenewalCost : data === null || data === void 0 ? void 0 : data.renewalCost) || 0;
    var renewalNumber = typeof (data === null || data === void 0 ? void 0 : data.renewalNumber) === "number" && (data === null || data === void 0 ? void 0 : data.renewalNumber) > 0 ? data === null || data === void 0 ? void 0 : data.renewalNumber : 1;
    var activationCost = ((data === null || data === void 0 ? void 0 : data.discountedActivationCost) >= 0 && (data === null || data === void 0 ? void 0 : data.activationCost) > 0 ? data === null || data === void 0 ? void 0 : data.discountedActivationCost : data === null || data === void 0 ? void 0 : data.activationCost) || 0;
    return getLabel({
        costs: {
            renewalCost: costDevice * renewalNumber + activationCost
        }
    }, placeholders, PlaceholdersID.TOTAL_DEVICE_COST);
};
