import { IActionType } from "@vfit/shared/models";
import { useContext, useState } from "react";
import { useRouter } from "next/router";
import { useVolaUtils } from "@vfit/business/data-access";
import { TrackingPageContext } from "@vfit/business/providers";
var useHandleClick = function() {
    var ref = useVolaUtils(), formatUrlVola = ref.formatUrlVola, openPopupVola = ref.openPopupVola, formatDynamicVolaUrl = ref.formatDynamicVolaUrl;
    var descriptionVolaUrl = useContext(TrackingPageContext).descriptionVolaUrl;
    var ref1 = useState(false), isShowWhatsapp = ref1[0], setIsShowWhatsapp = ref1[1];
    var ref2 = useState(false), showCheckout = ref2[0], setShowCheckout = ref2[1];
    var push = useRouter().push;
    var handleClick = function(param, product, desc) {
        var type = (param === null || param === void 0 ? void 0 : param.type) ? +param.type : 0;
        switch(type){
            case IActionType.CUSTOM_URL:
                if (param === null || param === void 0 ? void 0 : param.url) {
                    window.open(param === null || param === void 0 ? void 0 : param.url, (param === null || param === void 0 ? void 0 : param.isBlank) === "true" ? "_blank" : "_self");
                }
                break;
            case IActionType.LOCAL_URL:
                if (param === null || param === void 0 ? void 0 : param.url) push(param === null || param === void 0 ? void 0 : param.url);
                break;
            case IActionType.WHATSAPP_URL:
                setIsShowWhatsapp(true);
                break;
            case IActionType.CALL_ME_NOW:
                if (param.url) {
                    if (descriptionVolaUrl && desc) openPopupVola(formatDynamicVolaUrl(param === null || param === void 0 ? void 0 : param.url, desc), "vola-ctc");
                    else openPopupVola(formatUrlVola(param === null || param === void 0 ? void 0 : param.url, product), "vola-ctc");
                }
                break;
            case IActionType.CHECKOUT_MODAL_MOBILE:
                setShowCheckout(true);
                break;
            default:
                break;
        }
    };
    return {
        handleClick: handleClick,
        isShowWhatsapp: isShowWhatsapp,
        setIsShowWhatsapp: setIsShowWhatsapp,
        showCheckout: showCheckout
    };
};
export { useHandleClick };
