import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-business-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { handleEnv } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { delay, retrievePageNavigation } from "libs/consumer/data-access/src/lib/cms/staticPathPages/staticPathPages.utils";
import fetchRetry, { isDevLocal, organizeCMSPages } from "./staticPathPages.utils";
var getSecondLevelPages = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(page) {
        var ms, apiEnv, apiPdpPages, pdpResp, pdpPages, updateObj;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.prev = 0;
                    ms = Date.now();
                    apiEnv = handleEnv("NX_CMS");
                    apiPdpPages = "".concat(API.CMS_GET_PAGES_ESHOP_BUSINESS, "/").concat(page, "/all");
                    _ctx.next = 6;
                    return fetchRetry("".concat(apiEnv, "/").concat(isDevLocal ? apiPdpPages : "".concat(apiPdpPages, ".json"), "?t=").concat(ms));
                case 6:
                    pdpResp = _ctx.sent;
                    _ctx.next = 9;
                    return delay(200);
                case 9:
                    _ctx.next = 11;
                    return pdpResp.json();
                case 11:
                    pdpPages = _ctx.sent;
                    ;
                    if (!(pdpPages === null || pdpPages === void 0 ? void 0 : pdpPages["response"])) {
                        updateObj = Object.fromEntries(Object.entries(pdpPages).map(function(param) {
                            var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
                            return(// Modify key here
                            [
                                "".concat(page, "-").concat(key),
                                value
                            ]);
                        }));
                    }
                    return _ctx.abrupt("return", updateObj);
                case 17:
                    _ctx.prev = 17;
                    _ctx.t0 = _ctx["catch"](0);
                    return _ctx.abrupt("return", undefined);
                case 20:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                0,
                17
            ]
        ]);
    }));
    return function getSecondLevelPages(page) {
        return _ref.apply(this, arguments);
    };
}();
var getStaticPathPagesDxl = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var ms, apiEnv, apiPagesAll, pagesResp, firstLevelPage, allPages, secondLevelPaths, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, page, secondLevelPagesPaths, paths, pageNavigation;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ms = Date.now();
                    apiEnv = handleEnv("NX_CMS");
                    apiPagesAll = "".concat(API.CMS_GET_PAGES_ESHOP_BUSINESS, "/all");
                    _ctx.next = 5;
                    return fetchRetry("".concat(apiEnv, "/").concat(isDevLocal ? apiPagesAll : "".concat(apiPagesAll, ".json"), "?t=").concat(ms));
                case 5:
                    pagesResp = _ctx.sent;
                    _ctx.next = 8;
                    return delay(500);
                case 8:
                    _ctx.next = 10;
                    return pagesResp.json();
                case 10:
                    firstLevelPage = _ctx.sent;
                    allPages = firstLevelPage;
                    secondLevelPaths = [];
                    _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                    _ctx.prev = 14;
                    _iterator = Object.keys(firstLevelPage)[Symbol.iterator]();
                case 16:
                    if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                        _ctx.next = 25;
                        break;
                    }
                    page = _step.value;
                    _ctx.next = 20;
                    return getSecondLevelPages(page);
                case 20:
                    secondLevelPagesPaths = _ctx.sent;
                    secondLevelPaths.push(_object_spread({}, secondLevelPagesPaths));
                case 22:
                    _iteratorNormalCompletion = true;
                    _ctx.next = 16;
                    break;
                case 25:
                    _ctx.next = 31;
                    break;
                case 27:
                    _ctx.prev = 27;
                    _ctx.t0 = _ctx["catch"](14);
                    _didIteratorError = true;
                    _iteratorError = _ctx.t0;
                case 31:
                    _ctx.prev = 31;
                    _ctx.prev = 32;
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                case 34:
                    _ctx.prev = 34;
                    if (!_didIteratorError) {
                        _ctx.next = 37;
                        break;
                    }
                    throw _iteratorError;
                case 37:
                    return _ctx.finish(34);
                case 38:
                    return _ctx.finish(31);
                case 39:
                    if ((secondLevelPaths === null || secondLevelPaths === void 0 ? void 0 : secondLevelPaths.length) > 0) {
                        secondLevelPaths === null || secondLevelPaths === void 0 ? void 0 : secondLevelPaths.forEach(function(element) {
                            allPages = _object_spread({}, allPages, element || {});
                        });
                    }
                    paths = organizeCMSPages(allPages);
                    // region Filter Page on isShow
                    paths = paths === null || paths === void 0 ? void 0 : paths.filter(function(p) {
                        return p.params.isShow;
                    });
                    _ctx.next = 44;
                    return retrievePageNavigation("business-homepage");
                case 44:
                    pageNavigation = _ctx.sent;
                    paths = paths.map(function(path) {
                        return _object_spread_props(_object_spread({}, path), {
                            params: _object_spread_props(_object_spread({}, path.params), {
                                elements: _object_spread_props(_object_spread({}, path.params.elements), {
                                    pageNavigation: pageNavigation
                                })
                            })
                        });
                    });
                    return _ctx.abrupt("return", paths);
                case 47:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                14,
                27,
                31,
                39
            ],
            [
                32,
                ,
                34,
                38
            ]
        ]);
    }));
    return function getStaticPathPagesDxl() {
        return _ref.apply(this, arguments);
    };
}();
export { getStaticPathPagesDxl };
