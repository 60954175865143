import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-business-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { API } from "@vfit/shared/data-access";
import ky from "ky";
import { xml2js } from "xml-js";
import { handleEnv } from "@vfit/shared/providers";
/**
 * @description isLogged, use ObSSOCookie to get a JWT token
 * @returns  {Promise<IDXLCookieLoginResponse>}
 */ export var isLogged = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var options, res;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    options = {
                        compact: true,
                        nativeType: true,
                        ignoreDeclaration: true
                    };
                    _ctx.next = 3;
                    return ky.get(API.IS_LOGGED, {
                        prefixUrl: "https://login.vodafone.it/",
                        searchParams: {
                            k: handleEnv("NX_KEYSITE"),
                            format: "xml"
                        },
                        credentials: "include"
                    }).text();
                case 3:
                    res = _ctx.sent;
                    return _ctx.abrupt("return", xml2js(res, options).islogged.logged._text == 1);
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function isLogged() {
        return _ref.apply(this, arguments);
    };
}();
