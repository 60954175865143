import { ErrorAction, ErrorSeverity, errorManager, useHandleQueryWithAuthSSO } from "@vfit/business/providers";
import { useState } from "react";
import { getEcommerceCatalogDevices } from "../../api/getEcommerceCatalog/getEcommerceCatalogDevices";
var useGetEcommerceCatalogDeviceInfo = function(param) {
    var deviceId = param.deviceId;
    var ref = useState(true), isLoadingDeviceInfo = ref[0], setIsLoadingDeviceInfo = ref[1];
    var getEcommerceCatalogDeviceInfo = {
        queryKey: [
            deviceId,
            "ecommerceCatalogDeviceInfo"
        ],
        queryFn: function() {
            return getEcommerceCatalogDevices({
                deviceId: [
                    deviceId
                ]
            });
        },
        options: {
            onError: function(error) {
                var status = error.status;
                var statusNumber = Number(status);
                errorManager.handleError(ErrorSeverity.HIGH, {
                    errorAction: ErrorAction.PAGE,
                    errorCode: statusNumber
                });
            },
            onSettled: function() {
                setIsLoadingDeviceInfo(false);
            }
        }
    };
    var data = useHandleQueryWithAuthSSO(getEcommerceCatalogDeviceInfo).data;
    return {
        data: data,
        isLoadingDeviceInfo: isLoadingDeviceInfo
    };
};
export { useGetEcommerceCatalogDeviceInfo };
