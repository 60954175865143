import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { PlaceholdersID, getLabel, getDSIOTPrice, getDSIOTActivationPrice, getDSIOTResponsivePrice } from "@vfit/business/data-access";
import { getFormattedPriceStringDS } from "@vfit/business/providers";
import { getValues } from "../WidgetHubDigitalSolutions/widgetHubDigitalSolutions.utils";
var getDSHeroBanner = function(placeholders, product, dxlProduct) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    if ((product === null || product === void 0 ? void 0 : product.isEditorialCard) === "false" && !dxlProduct) return {
        image: product.imageBackgroundUrl || ""
    };
    var getPriceInfo = function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6;
        var isDSSingle = (dxlProduct === null || dxlProduct === void 0 ? void 0 : dxlProduct.editionInfo) && (dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref = dxlProduct.editionInfo) === null || ref === void 0 ? void 0 : ref.length) === 1;
        return isDSSingle ? {
            priceDescription: product.overrideCostActivationHtml || dxlProduct && getDSIOTActivationPrice(dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref1 = dxlProduct.editionInfo) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.costs, placeholders),
            price: product.overrideCostRenewalHtml || dxlProduct && getDSIOTPrice(dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref3 = dxlProduct.editionInfo) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.costs, placeholders),
            priceResponsive: product.overrideCostRenewalResponsive || dxlProduct && getDSIOTResponsivePrice(dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref5 = dxlProduct.editionInfo) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : ref6.costs, placeholders),
            frontLabel: product.frontLabel || "",
            offerLabel: product.offerLabel || ""
        } : {};
    };
    return _object_spread({
        offerId: product.dxlId,
        isEditorialCard: product.isEditorialCard,
        taggingProductPrice: product.taggingProductPrice || getFormattedPriceStringDS(dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref = dxlProduct.editionInfo) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.costs) || "",
        title: product.overrideOfferName || dxlProduct && getLabel(dxlProduct, placeholders, PlaceholdersID.BS_NAME),
        productType: product.productType,
        shortDescription: product === null || product === void 0 ? void 0 : product.shortDescription,
        image: product.imageBackgroundUrl || "",
        backLabel: product.backLabel || undefined,
        actions: product.actions,
        subtitle: (product === null || product === void 0 ? void 0 : product.overrideSubtitle) || getValues((dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref2 = dxlProduct.editionInfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.characteristic) || [], "businessSolutionClaim"),
        iconUrl: (product === null || product === void 0 ? void 0 : product.overrideIconUrl) || getValues((dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref4 = dxlProduct.editionInfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.characteristic) || [], "businessSolutionImage"),
        externalUrl: !(product === null || product === void 0 ? void 0 : product.overrideIconUrl),
        hideDetails: true
    }, getPriceInfo());
};
export { getDSHeroBanner };
