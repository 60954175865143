import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 0 auto;\n\n  p {\n    text-align: center;\n    font-family: ",
        ";\n    font-size: 40px;\n    font-weight: 400;\n    line-height: 48px;\n    margin: 0 0 32px 0;\n  }\n\n  @media (min-width: ",
        ") {\n    p {\n      margin: 0 0 48px 0;\n    }\n  }\n\n  div {\n    display: flex;\n    width: 216px;\n    justify-items: center;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { breakpoints, fonts } from "@vfit/shared/themes";
import styled from "styled-components";
export var DeviceNotAvailable = styled.div.withConfig({
    componentId: "sc-94b38e9f-0"
})(_templateObject(), fonts.light, breakpoints.desktop);
