import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { getActionUrlByActionType } from "@vfit/shared/data-access";
export var useWidgetConnectionCards = function(widget) {
    return {
        topText: widget === null || widget === void 0 ? void 0 : widget.title,
        disclaimer: widget === null || widget === void 0 ? void 0 : widget.disclaimerHtml,
        divider: widget === null || widget === void 0 ? void 0 : widget.divider,
        slides: widget.elements.map(function(el) {
            return _object_spread({}, el, (el === null || el === void 0 ? void 0 : el.action) && {
                actionNavigationUrl: getActionUrlByActionType(el === null || el === void 0 ? void 0 : el.action)
            });
        })
    };
};
