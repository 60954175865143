import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-business-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { API } from "@vfit/shared/data-access";
import { dxlClientWithOutJwt } from "@vfit/business/providers";
/**
 * @description anonymous login, used to get a token for the first time
 * @returns  {Promise<IDXLAnonymousLoginResponse>}
 */ export var dxlAnonymousLogin = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return dxlClientWithOutJwt.get(API.DXL_ANONYMOUS_LOGIN, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function dxlAnonymousLogin() {
        return _ref.apply(this, arguments);
    };
}();
