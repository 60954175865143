import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { TabsContext } from "@vfit/shared/providers";
import { useContext } from "react";
import { useQueryClient } from "react-query";
import { NationContext, PopUpFilterContext } from "@vfit/business/providers";
export var useProductWrapper = function(widget) {
    var ref = useContext(TabsContext), setIsTwoActive = ref.setIsTwoActive, setIsOneActive = ref.setIsOneActive;
    var ref1 = useContext(NationContext), pageType = ref1.pageType, nation = ref1.nation, appType = ref1.appType;
    var selectedOption = useContext(PopUpFilterContext).selectedOption;
    var queryClient = useQueryClient();
    var offers = queryClient.getQueryData("getBusinessOffersFilter");
    var filteredOffers = queryClient.getQueryData("getBusinessOffersFilterForPopUp");
    var consumerOffers = queryClient.getQueryData("getConsumerOffersFilter");
    var _widget = widget.widget, tabId = _widget.tabId, elements = _widget.elements;
    var newElems = [];
    switch(appType){
        case "consumer":
            // eslint-disable-next-line no-case-declarations
            var filterElem = consumerOffers.find(function(offer) {
                return (offer === null || offer === void 0 ? void 0 : offer.slugCountry) === (nation === null || nation === void 0 ? void 0 : nation.slug);
            });
            if (filterElem && pageType["isDetailCountry"] === "true") elements.map(function(elem) {
                return filterElem === null || filterElem === void 0 ? void 0 : filterElem.elements.map(function(o) {
                    if (o.offerId === elem.dxlId) {
                        // eslint-disable-next-line no-param-reassign
                        elem.overrideCostRenewalHtml = o.offerPriceHtml;
                        // eslint-disable-next-line no-param-reassign
                        elem.shortCharacteristics = o.shortCharacteristics;
                        newElems.push(elem);
                    }
                });
            });
            else newElems = elements;
            return _object_spread_props(_object_spread({}, widget), {
                widget: _object_spread_props(_object_spread({}, widget.widget), {
                    elements: newElems
                })
            });
        default:
            if (pageType["isDetailCountry"] === "true") {
                var offerslist = selectedOption ? filteredOffers : offers;
                newElems = elements.filter(function(elem) {
                    return offerslist.find(function(offer) {
                        return offer.dxlId === elem.dxlId && offer.country.includes(nation === null || nation === void 0 ? void 0 : nation.slug);
                    });
                });
                if (elements.length === 0 && tabId === "1") {
                    setIsOneActive(false);
                } else if (elements.length === 0 && tabId === "2") {
                    setIsTwoActive(false);
                } else {
                    setIsOneActive(true);
                    setIsTwoActive(true);
                }
                return _object_spread_props(_object_spread({}, widget), {
                    widget: _object_spread_props(_object_spread({}, widget.widget), {
                        elements: newElems
                    })
                });
            }
            if (pageType["isTariffeEstero"] === "true") {
                var offerslist1 = selectedOption ? filteredOffers : offers;
                newElems = elements.filter(function(elem) {
                    return offerslist1.find(function(offer) {
                        return offer.dxlId === elem.dxlId;
                    });
                });
                return _object_spread_props(_object_spread({}, widget), {
                    widget: _object_spread_props(_object_spread({}, widget.widget), {
                        elements: newElems
                    })
                });
            }
            return _object_spread_props(_object_spread({}, widget), {
                widget: _object_spread_props(_object_spread({}, widget.widget), {
                    elements: elements
                })
            });
            break;
    }
};
