import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-height: ",
        ";\n\n  @media (min-width: ",
        ") {\n    min-height: 737px;\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: 737px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: 20px;\n  color: ",
        ";\n  font-family: ",
        ";\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    width: 366px;\n    margin: 0 auto;\n    font-family: ",
        ";\n    ",
        "\n    text-align: center;\n\n    @media (min-width: ",
        ") {\n      text-align: center;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 32px;\n  right: 20px;\n  left: 20px;\n\n  @media (min-width: ",
        ") {\n    bottom: 64px;\n    left: 40px;\n    right: 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    bottom: 60px;\n    left: 54px;\n    right: 54px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-family: ",
        ";\n  ",
        "\n  text-align: center;\n  color: ",
        ";\n  margin-bottom: 8px;\n  padding: 0 20px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-8353faa3-0"
})(_templateObject(), pxToRem(560), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-8353faa3-1"
})(_templateObject1(), pxToCssFont(42, 52), colors.$e60000, fonts.exbold, breakpoints.tablet, breakpoints.desktop);
export var Description = styled.div.withConfig({
    componentId: "sc-8353faa3-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 30), breakpoints.tablet);
// export const ButtonSlideContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
//   justify-content: center;
//   @media (min-width: ${breakpoints.tablet}) {
//     margin-top: 40px;
//     flex-direction: row;
//     gap: 24px;
//   }
//   @media (min-width: ${breakpoints.desktop}) {
//     margin-top: 48px;
//     gap: 32px;
//   }
//   div {
//     width: 100%;
//     justify-content: center;
//     display: flex;
//     button {
//       max-width: 100%;
//     }
//   }
// `;
export var ButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-8353faa3-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var Subtitle = styled.div.withConfig({
    componentId: "sc-8353faa3-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(24, 30), colors.$262626);
