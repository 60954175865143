import { useGetEcommerceCatalogDSById } from "@vfit/business/data-access";
import { formatProducts } from "./widgetSliderProductDSMultiple.utils";
var useWidgetSliderProductDSMultiple = function(widget, placeholders) {
    var ref, ref1;
    var elements = widget.elements;
    // se tutte le card sono editoriali non viene chiamata la getEcommerceCatalog
    var isEditorialCard = Object.values(elements).every(function(item) {
        return item.isEditorialCard === "true";
    }).toString();
    var data = useGetEcommerceCatalogDSById([
        {
            dxlId: widget.dxlId,
            isEditorialCard: isEditorialCard
        }
    ]).data;
    return {
        title: widget.titleHtml || "",
        products: formatProducts(elements, placeholders, data === null || data === void 0 ? void 0 : (ref = data[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.businessSolutionInfo) === null || ref1 === void 0 ? void 0 : ref1[0])
    };
};
export { useWidgetSliderProductDSMultiple };
