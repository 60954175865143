import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var getValues = function(characteristic, name) {
    var ref, ref1;
    var values = (ref = characteristic === null || characteristic === void 0 ? void 0 : characteristic.find(function(ch) {
        return ch.name === name;
    })) === null || ref === void 0 ? void 0 : ref.value;
    return (values === null || values === void 0 ? void 0 : (ref1 = values[0]) === null || ref1 === void 0 ? void 0 : ref1.value) || "";
};
export var formatDigitalSolutions = function(widget, digitalSolutions) {
    return (digitalSolutions === null || digitalSolutions === void 0 ? void 0 : digitalSolutions.map(function(item) {
        var ref, ref1, ref2, ref3, ref4, ref5;
        return {
            id: (item === null || item === void 0 ? void 0 : item.businessSolutionId) || "",
            image: getValues((item === null || item === void 0 ? void 0 : (ref = item.editionInfo) === null || ref === void 0 ? void 0 : ref[0].characteristic) || [], "backgroundImage").trim() || "",
            title: item.businessSolutionName || "",
            description: getValues((item === null || item === void 0 ? void 0 : (ref1 = item.editionInfo) === null || ref1 === void 0 ? void 0 : ref1[0].characteristic) || [], "businessSolutionClaim") || "",
            tag: getValues((item === null || item === void 0 ? void 0 : (ref2 = item.editionInfo) === null || ref2 === void 0 ? void 0 : ref2[0].characteristic) || [], "tag") || "",
            action: _object_spread_props(_object_spread({}, widget.customAction), {
                style: Number((ref3 = widget.customAction) === null || ref3 === void 0 ? void 0 : ref3.style),
                url: getValues((item === null || item === void 0 ? void 0 : (ref4 = item.editionInfo) === null || ref4 === void 0 ? void 0 : ref4[0].characteristic) || [], "businessSolutionUrl") || "",
                localUrl: getValues((item === null || item === void 0 ? void 0 : (ref5 = item.editionInfo) === null || ref5 === void 0 ? void 0 : ref5[0].characteristic) || [], "businessSolutionUrl") || ""
            })
        };
    })) || [];
};
export var getFilteredProductData = function(tab, digitalSolutions, widget) {
    var filteredData = digitalSolutions === null || digitalSolutions === void 0 ? void 0 : digitalSolutions.filter(function(ds) {
        return ds.tag === tab || tab === widget.tags.allTagsLabel;
    });
    return {
        product_id: _to_consumable_array(filteredData.map(function(item) {
            return item["id"];
        })),
        product_name: _to_consumable_array(filteredData.map(function(item) {
            return item["title"];
        })),
        product_category: _to_consumable_array(filteredData.map(function() {
            return "DS";
        })),
        // product_price: [],
        product_target_segment: _to_consumable_array(filteredData.map(function() {
            return "business";
        })),
        product_quantity: filteredData.length.toString()
    };
};
