import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n\n  a {\n    color: ",
        " !important;\n\n    :visited {\n      color: ",
        " !important;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin-left: 81px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-838a8c5-0"
})(_templateObject(), fonts.regular, colors.$7e7e7e, colors.$262626, colors.$262626, breakpoints.desktop);
