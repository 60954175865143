import { ErrorAction, errorManager, ErrorSeverity, PageLoadingContext, useHandleQueryWithAuthSSO } from "@vfit/business/providers";
import { useContext } from "react";
import { getEcommerceCatalogDevices } from "../../api/getEcommerceCatalog/getEcommerceCatalogDevices";
var useGetEcommerceCatalogDevices = function(param) {
    var limit = param.limit, category = param.category;
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var getAllDevices = {
        queryKey: "ecommerceCatalogDevices",
        queryFn: function() {
            return getEcommerceCatalogDevices({
                limit: limit,
                offset: limit && "0",
                resourceCategoryDescription: category && [
                    category
                ]
            });
        },
        options: {
            onSuccess: function(data) {
                return sortByPosition(data);
            },
            onError: function(error) {
                var status = error.status;
                var statusNumber = Number(status);
                errorManager.handleError(ErrorSeverity.HIGH, {
                    errorAction: ErrorAction.PAGE,
                    errorCode: statusNumber
                });
            },
            onSettled: function() {
                setIsLoading(false);
            }
        }
    };
    var data = useHandleQueryWithAuthSSO(getAllDevices).data;
    return {
        data: data
    };
};
var sortByPosition = function(data) {
    var ref;
    return data === null || data === void 0 ? void 0 : (ref = data.deviceInfo) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
        var ref, ref1, ref2, ref3, ref4, ref5;
        var posA = (ref1 = a === null || a === void 0 ? void 0 : (ref = a.characteristic) === null || ref === void 0 ? void 0 : ref.find(function(c) {
            return c.name === "eCommercePosition";
        })) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.value) === null || ref2 === void 0 ? void 0 : ref2[0];
        var posB = (ref4 = b === null || b === void 0 ? void 0 : (ref3 = b.characteristic) === null || ref3 === void 0 ? void 0 : ref3.find(function(c) {
            return c.name === "eCommercePosition";
        })) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.value) === null || ref5 === void 0 ? void 0 : ref5[0];
        if ((posA === null || posA === void 0 ? void 0 : posA.value) && (posB === null || posB === void 0 ? void 0 : posB.value)) return Number(posA === null || posA === void 0 ? void 0 : posA.value) > Number(posB === null || posB === void 0 ? void 0 : posB.value) ? 1 : -1;
        return (posA === null || posA === void 0 ? void 0 : posA.value) ? -1 : 1;
    });
};
export { useGetEcommerceCatalogDevices };
