import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-wrap: wrap;\n  gap: 32px;\n  justify-content: center;\n  padding-bottom: 40px;\n\n  button,\n  a {\n    min-width: 320px;\n    width: fit-content;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-637a5892-0"
})(_templateObject());
