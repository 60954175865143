import { API, PAGES } from "@vfit/shared/data-access";
import { errorManager, ErrorAction, ErrorSeverity, TrackingPageContext } from "@vfit/business/providers";
import { useContext } from "react";
import { useRouter } from "next/router";
import { CMS_CONFIG } from "../cms.config";
export var cmsDataID;
(function(cmsDataID) {
    cmsDataID["PLACEHOLDER"] = "PLACEHOLDER";
    cmsDataID["WHATSAPP"] = "WHATSAPP";
    cmsDataID["ADDONS"] = "ADDONS";
    cmsDataID["OFFER_DETAILS"] = "OFFER_DETAILS";
    cmsDataID["CAPACITY_AND_COLOR"] = "CAPACITY_AND_COLOR";
    cmsDataID["DEVICE_DETAILS"] = "DEVICE_DETAILS";
    cmsDataID["MOBILE_LINE_DETAILS"] = "MOBILE_LINE_DETAILS";
    cmsDataID["DATI_LINE_DETAILS"] = "DATI_LINE_DETAILS";
})(cmsDataID || (cmsDataID = {}));
export var useSwitchCmsData = function(key) {
    var pageType = useContext(TrackingPageContext).pageType;
    var obj;
    var pageTypeByPathname = useFindPageTypeByPathname();
    var type = pageType || pageTypeByPathname;
    switch(key){
        case cmsDataID.PLACEHOLDER:
            obj = getPlaceholderConfig(type);
            break;
        case cmsDataID.WHATSAPP:
            obj = getWhatsappConfig(type);
            break;
        case cmsDataID.ADDONS:
            obj = getAddonsConfig(type);
            break;
        case cmsDataID.OFFER_DETAILS:
            obj = getOfferDetailsConfig(type);
            break;
        case cmsDataID.CAPACITY_AND_COLOR:
            obj = getCapacityAndColor(type);
            break;
        default:
            break;
    }
    if (typeof obj === "undefined") {
        errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE
        });
    } else {
        return obj;
    }
};
var useFindPageTypeByPathname = function() {
    var pathname = useRouter().pathname;
    if (pathname.includes(PAGES.BUSINESS_FIXED_LINE)) return "FIXED";
    if (pathname.includes(PAGES.BUSINESS_MOBILE_LINE)) return "VOCE";
    if (pathname.includes(PAGES.BUSINESS_DATI_LINE)) return "DATI";
    if (pathname.includes(PAGES.BUSINESS_SMARTPHONE)) return "SMARTPHONE";
    if (pathname.includes(PAGES.BUSINESS_TABLET)) return "TABLET";
    return "FIXED";
};
var getPlaceholderConfig = function(type) {
    switch(type){
        case "FIXED":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
                api: API.CMS_BFL_GET_COMMON_PLACEHOLDER
            };
        case "VOCE":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_MOBILE_LINE],
                api: API.CMS_BML_GET_COMMON_PLACEHOLDER
            };
        case "DATI":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_DATI_LINE],
                api: API.CMS_BML_GET_COMMON_PLACEHOLDER
            };
        case "SMARTPHONE":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_SMARTPHONE_PDP],
                api: API.CMS_DS_GET_COMMON_PLACEHOLDER
            };
        case "TABLET":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_TABLET_PDP],
                api: API.CMS_DT_GET_COMMON_PLACEHOLDER
            };
        case "INNOVATIVE":
            return {
                cmsConfig: CMS_CONFIG["BUSINESS_DSIOT_PDP"],
                api: API.CMS_DSIOT_GET_COMMON_PLACEHOLDER
            };
        default:
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
                api: API.CMS_BFL_GET_COMMON_PLACEHOLDER
            };
    }
};
var getWhatsappConfig = function(type) {
    switch(type){
        case "FIXED":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
                api: API.CMS_BFL_GET_COMMON_WHATSAPP
            };
        case "VOCE":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_MOBILE_LINE],
                api: API.CMS_BML_GET_COMMON_WHATSAPP
            };
        case "DATI":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_DATI_LINE],
                api: API.CMS_BDL_GET_WHATSAPP
            };
        case "SMARTPHONE":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_SMARTPHONE_PDP],
                api: API.CMS_DS_GET_COMMON_WHATSAPP
            };
        case "TABLET":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_TABLET_PDP],
                api: API.CMS_DT_GET_COMMON_WHATSAPP
            };
        case "INNOVATIVE":
            return {
                cmsConfig: CMS_CONFIG["BUSINESS_DSIOT_PDP"],
                api: API.CMS_DSIOT_GET_COMMON_WHATSAPP
            };
        default:
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
                api: API.CMS_BFL_GET_COMMON_WHATSAPP
            };
    }
};
var getAddonsConfig = function(type) {
    switch(type){
        case "FIXED":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
                api: API.CMS_BFL_GET_ADDONS
            };
        case "VOCE":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_MOBILE_LINE],
                api: API.CMS_BVL_GET_ADDONS
            };
        case "DATI":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_DATI_LINE],
                api: API.CMS_BDL_GET_ADDONS
            };
        default:
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
                api: API.CMS_BFL_GET_ADDONS
            };
    }
};
export var getOfferDetailsConfig = function(type) {
    switch(type){
        case "FIXED":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
                api: API.CMS_BFL_GET_DETAIL_PRODUCTS
            };
        case "VOCE":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_MOBILE_LINE],
                api: API.CMS_BVL_GET_DETAIL_PRODUCTS
            };
        case "DATI":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_DATI_LINE],
                api: API.CMS_BDL_GET_DETAIL_PRODUCTS
            };
        case "SMARTPHONE":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_SMARTPHONE_PDP],
                api: API.CMS_DS_GET_DETAIL_PRODUCTS
            };
        case "TABLET":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_TABLET_PDP],
                api: API.CMS_DT_GET_DETAIL_PRODUCTS
            };
        default:
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
                api: API.CMS_BFL_GET_DETAIL_PRODUCTS
            };
    }
};
var getCapacityAndColor = function(type) {
    switch(type){
        case "SMARTPHONE":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_SMARTPHONE_PDP],
                api: API.CMS_GET_CAPACITY_AND_COLOR
            };
        case "TABLET":
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_TABLET_PDP],
                api: API.CMS_GET_CAPACITY_AND_COLOR
            };
        default:
            return {
                cmsConfig: CMS_CONFIG[PAGES.BUSINESS_SMARTPHONE_PDP],
                api: API.CMS_GET_CAPACITY_AND_COLOR
            };
    }
};
