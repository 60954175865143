import { ICMSWidgetType, getPriceDescriptionAddons, useCmsConfig, useSwitchCmsData, cmsDataID } from "@vfit/business/data-access";
export var useGetSelectedAddons = function(productInfo, aemData) {
    var ref = useSwitchCmsData(cmsDataID.ADDONS), cmsConfig = ref.cmsConfig, api = ref.api;
    var addons = useCmsConfig(cmsConfig, api);
    var ref1 = useSwitchCmsData(cmsDataID.PLACEHOLDER), cmsPlaceholder = ref1.cmsConfig, apiPlaceholder = ref1.api;
    var placeholders = useCmsConfig(cmsPlaceholder, apiPlaceholder).placeholders;
    var addonWidget = Object.values(aemData).find(function(item) {
        return item.type === ICMSWidgetType.SLIDER_ADDON;
    });
    if (addonWidget && productInfo && addons) {
        var ref2, ref3;
        var allAddons = Object.values(addons);
        //add check: if productInfo?.addOn?.productType is 'O' 'C' or 'D'
        addonWidget.elements = ((ref3 = productInfo === null || productInfo === void 0 ? void 0 : (ref2 = productInfo.addOn) === null || ref2 === void 0 ? void 0 : ref2.filter(function(a) {
            return allAddons.find(function(addon) {
                var ref;
                return ((ref = addon.addon) === null || ref === void 0 ? void 0 : ref.addonId) === (a === null || a === void 0 ? void 0 : a.productSpecificationId);
            });
        })) === null || ref3 === void 0 ? void 0 : ref3.map(function(item) {
            var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
            return {
                frontLabel: (ref = addons[item === null || item === void 0 ? void 0 : item.productSpecificationId]) === null || ref === void 0 ? void 0 : (ref1 = ref.addon) === null || ref1 === void 0 ? void 0 : ref1.frontLabel,
                title: (ref2 = addons[item === null || item === void 0 ? void 0 : item.productSpecificationId]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.addon) === null || ref3 === void 0 ? void 0 : ref3.addonName,
                action: (ref4 = addons[item === null || item === void 0 ? void 0 : item.productSpecificationId]) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.addon) === null || ref5 === void 0 ? void 0 : ref5.customAction,
                descriptionHtml: getPriceDescriptionAddons(item, placeholders === null || placeholders === void 0 ? void 0 : placeholders.placeholders),
                popupDetail: (ref6 = addons[item === null || item === void 0 ? void 0 : item.productSpecificationId]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.addon) === null || ref7 === void 0 ? void 0 : ref7.popupDetail,
                addonId: (ref8 = addons[item === null || item === void 0 ? void 0 : item.productSpecificationId]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.addon) === null || ref9 === void 0 ? void 0 : ref9.addonId
            };
        })) || [];
    }
    return {
        addonWidget: addonWidget
    };
};
