import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  button {\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  border-top: 1px solid #bebebe;\n  margin-top: 24px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n\n  span {\n    font-size: 22px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  max-width: 32px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  button {\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 330px;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: 250px;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  div {\n    margin: 0;\n    line-height: 42px;\n    margin-bottom: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    line-height: 24px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  border-top: 1px solid #bebebe;\n  margin-top: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n\n  div {\n    margin: 16px 0 0;\n    line-height: 24px;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  max-width: 160px;\n  margin: 24px auto;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n  border-radius: 32px;\n  padding: 10.5px 15px;\n  cursor: pointer;\n  width: 100%;\n  background: #fff;\n  border: 1px solid #262626;\n  text-align: center;\n\n  a {\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 700;\n    font-size: 0.875rem;\n    line-height: 1.55;\n    text-align: center;\n    letter-spacing: 0.5px;\n    text-transform: uppercase;\n    color: #262626;\n    text-decoration: none;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var ExtendedVersionContainer = styled.div.withConfig({
    componentId: "sc-6a3b23e8-0"
})(_templateObject());
export var TopContainer = styled.div.withConfig({
    componentId: "sc-6a3b23e8-1"
})(_templateObject1());
export var BottomContainer = styled.div.withConfig({
    componentId: "sc-6a3b23e8-2"
})(_templateObject2());
export var IconWrapper = styled.div.withConfig({
    componentId: "sc-6a3b23e8-3"
})(_templateObject3());
export var ShortVersionContainer = styled.div.withConfig({
    componentId: "sc-6a3b23e8-4"
})(_templateObject4());
export var ContentLeft = styled.div.withConfig({
    componentId: "sc-6a3b23e8-5"
})(_templateObject5());
export var ContentRight = styled.div.withConfig({
    componentId: "sc-6a3b23e8-6"
})(_templateObject6());
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-6a3b23e8-7"
})(_templateObject7(), pxToCssFont(18, 16), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalDescription = styled.div.withConfig({
    componentId: "sc-6a3b23e8-8"
})(_templateObject8());
export var DescriptionFlex = styled.div.withConfig({
    componentId: "sc-6a3b23e8-9"
})(_templateObject9());
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-6a3b23e8-10"
})(_templateObject10());
export var FreeNumerContainer = styled.div.withConfig({
    componentId: "sc-6a3b23e8-11"
})(_templateObject11(), fonts.regular);
