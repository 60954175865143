import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-business-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { API } from "@vfit/shared/data-access";
import { handleEnv } from "@vfit/shared/providers";
var isDevLocal = handleEnv("NODE_ENV") === "development";
var TABLET_LIST = isDevLocal ? "".concat(API.CMS_DT_GET_DEVICES) : "".concat(API.CMS_DT_GET_DEVICES, ".json");
/**
 * Retrieve Tablet visibility offers from AEM
 */ var getTabletSlug = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var allProductRes, allProductId, allSlugs;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return fetch("".concat(handleEnv("NX_CMS"), "/").concat(TABLET_LIST));
                case 2:
                    allProductRes = _ctx.sent;
                    _ctx.next = 5;
                    return allProductRes.json();
                case 5:
                    allProductId = _ctx.sent;
                    allSlugs = [];
                    Object.values(allProductId).map(function(el) {
                        var ref;
                        var widgetWithSlug = Object.values(el).find(function(w) {
                            return (w === null || w === void 0 ? void 0 : w.type) === "DEVICE_HERO_BANNER";
                        });
                        if (widgetWithSlug) allSlugs.push(widgetWithSlug === null || widgetWithSlug === void 0 ? void 0 : (ref = widgetWithSlug.product) === null || ref === void 0 ? void 0 : ref.slug);
                    });
                    return _ctx.abrupt("return", allSlugs.map(function(fileName) {
                        return {
                            params: {
                                slug: fileName.replace(/\.md$/, "")
                            }
                        };
                    }));
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getTabletSlug() {
        return _ref.apply(this, arguments);
    };
}();
export { getTabletSlug };
