import { getOfferName, getPrice, getPriceDescription, getResponsivePrice, useCmsConfig, useSwitchCmsData, getUnaTantumPriceDescription, cmsDataID, getCharacteristicValues } from "@vfit/business/data-access";
import { getFormattedPriceString } from "@vfit/business/providers";
/**
 * Add in documentation only for product hero banner widget
 * @param elements
 */ var getHeroBanner = function(product, dxlProduct) {
    var ref = useSwitchCmsData(cmsDataID.PLACEHOLDER), cmsConfig = ref.cmsConfig, api = ref.api;
    var placeholders = useCmsConfig(cmsConfig, api).placeholders;
    if ((product === null || product === void 0 ? void 0 : product.isEditorialCard) === "false" && !dxlProduct) return {
        image: product.imageBackgroundUrl || ""
    };
    return {
        offerId: product.dxlId,
        isEditorialCard: product.isEditorialCard,
        taggingProductPrice: product.taggingProductPrice || getFormattedPriceString(dxlProduct) || "",
        title: product.overrideOfferName || dxlProduct && getOfferName(dxlProduct, placeholders === null || placeholders === void 0 ? void 0 : placeholders.placeholders),
        productType: product.productType,
        priceDescription: product.overrideCostActivationHtml || dxlProduct && getPriceDescription(dxlProduct, placeholders === null || placeholders === void 0 ? void 0 : placeholders.placeholders),
        unaTantumPriceDescription: product.overrideUnaTantumCostActivationHtml || dxlProduct && getUnaTantumPriceDescription(dxlProduct, placeholders === null || placeholders === void 0 ? void 0 : placeholders.placeholders),
        price: product.overrideCostRenewalHtml || dxlProduct && getPrice(dxlProduct, placeholders === null || placeholders === void 0 ? void 0 : placeholders.placeholders),
        shortDescription: product === null || product === void 0 ? void 0 : product.shortDescription,
        priceResponsive: product.overrideCostRenewalResponsive || dxlProduct && getResponsivePrice(dxlProduct, placeholders === null || placeholders === void 0 ? void 0 : placeholders.placeholders),
        frontLabel: product.frontLabel || "",
        image: product.imageBackgroundUrl || "",
        backLabel: product.backLabel || undefined,
        offerLabel: product.offerLabel || "",
        actions: product.actions,
        retarget: product.retarget,
        version: product.version,
        landingprive: product === null || product === void 0 ? void 0 : product.landingprive,
        taggingProductName: product.taggingProductName || dxlProduct && getOfferName(dxlProduct, placeholders === null || placeholders === void 0 ? void 0 : placeholders.placeholders),
        timeDiscount: dxlProduct === null || dxlProduct === void 0 ? void 0 : dxlProduct.timeDiscount,
        timeDiscountLabels: {
            timeDiscountTag: getCharacteristicValues((dxlProduct === null || dxlProduct === void 0 ? void 0 : dxlProduct.characteristic) || [], "timeDiscountTag"),
            timeDiscountDetails: getCharacteristicValues((dxlProduct === null || dxlProduct === void 0 ? void 0 : dxlProduct.characteristic) || [], "timeDiscountDetails"),
            timeDiscountTagForDevicePages: getCharacteristicValues((dxlProduct === null || dxlProduct === void 0 ? void 0 : dxlProduct.characteristic) || [], "timeDiscountTagForDevicePages")
        }
    };
};
export { getHeroBanner };
