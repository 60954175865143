import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  padding: 32px 0 32px 0;\n  border-top: 1px solid ",
        ";\n\n  span{\n    cursor: pointer;\n  }\n  .numberBrand{\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 32px;\n   div{\n    margin: 0;\n    span{\n      margin: 0;\n    }\n  }\n  @media (min-width: ",
        ") {\n    margin-bottom: 48px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var FilterRow = styled.div.withConfig({
    componentId: "sc-62d8009-0"
})(_templateObject(), colors.$bebebe, colors.$bebebe);
export var Title = styled.div.withConfig({
    componentId: "sc-62d8009-1"
})(_templateObject1(), breakpoints.desktop);
