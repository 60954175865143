export var PlaceholdersID;
(function(PlaceholdersID) {
    PlaceholdersID["OFFER_NAME"] = "offerName";
    PlaceholdersID["CORE_OFFER_COST_WITH_FEE"] = "coreOfferCostWithFee";
    PlaceholdersID["CORE_OFFER_COST_DISCOUNTED"] = "coreOfferCostDiscounted";
    PlaceholdersID["FREE_ACT_RECURRING_COST"] = "freeActRecurringCost";
    PlaceholdersID["DISCOUNTED_ACT_RECURRING_COST"] = "discountedActRecurringCost";
    PlaceholdersID["ACT_RECURRING_COST_WITH_FEE"] = "actRecurringCostWithFee";
    PlaceholdersID["ACT_AND_RECURRING_COST_MAP"] = "actAndRecurringCostMap";
    PlaceholdersID["ACT_AND_RECURRING_DISCOUNTED_COST_MAP"] = "actAndRecurringDiscountedCostMap";
    PlaceholdersID["FREE_ACT_AND_RECURRING_COST_MAP"] = "freeActAndRecurringCostMap";
    PlaceholdersID["FREE_ACT_AND_RECURRING_DISCOUNTED_COST_MAP"] = "freeActAndRecurringDiscountedCostMap";
    PlaceholdersID["DISCOUNTED_ACT_RECURRING_COST_MAP"] = "discountedActAndRecurringCostMap";
    PlaceholdersID["DISCOUNTED_ACT_RECURRING_DISCOUNTED_COST_MAP"] = "discountedActAndRecurringDiscountedCostMap";
    PlaceholdersID["CORE_OFFER_COST_FOR_ADDON"] = "coreOfferCostForAddon";
    PlaceholdersID["DISCOUNTED_CORE_OFFER_FOR_ADDON"] = "discountedCoreOfferCostForAddon";
    PlaceholdersID["CORE_OFFER_COST_WITH_FEE_RESPONSIVE"] = "coreOfferCostWithFeeResponsive";
    PlaceholdersID["CORE_OFFER_COST_DISCOUNTED_RESPONSIVE"] = "coreOfferCostDiscountedResponsive";
    PlaceholdersID["FREE_ACT_COST"] = "freeActCost";
    PlaceholdersID["DISCOUNTED_ACT_COST"] = "discountedActCost";
    PlaceholdersID["ACT_COST_WITH_FEE"] = "actCostWithFee";
    PlaceholdersID["ACT_COST_WITH_FEE_RESPONSIVE"] = "actCostWithFeeResponsive";
    PlaceholdersID["DISCOUNTED_ACT_COST_RESPONSIVE"] = "discountedActCostResponsive";
    PlaceholdersID[// device
    "DEVICE_OFFER_NAME_LABEL"] = "deviceOfferNameLabel";
    PlaceholdersID["PRODUCT_TITLE_DEVICE"] = "productTitle";
    PlaceholdersID["TOTAL_COST_WITH_FEE_DEVICE"] = "totalCostWithFee";
    PlaceholdersID["DEVICE_COST_WITH_FEE"] = "deviceCostWithFee";
    PlaceholdersID["DEVICE_COST_DISCOUNTED"] = "deviceCostDiscounted";
    PlaceholdersID["ACT_RECURRING_COST_WITH_FEE_DEVICE"] = "actRecurringCostWithFee";
    PlaceholdersID["DISCOUNTED_ACT_RECURRING_COST_DEVICE"] = "discountedActRecurringCost";
    PlaceholdersID["FREE_ACT_RECURRING_COST_DEVICE"] = "freeActRecurringCost";
    PlaceholdersID["DEVICE_ACT_COST_WITH_FEE"] = "deviceActCostWithFee";
    PlaceholdersID["DEVICE_ACT_COST_DISCOUNTED"] = "deviceActCostDiscounted";
    PlaceholdersID["DEVICE_ACT_COST_WITH_FEE_CUSTOMIZATION"] = "deviceActCostWithFeeCustomization";
    PlaceholdersID["DEVICE_ACT_COST_DISCOUNTED_CUSTOMIZATION"] = "deviceActCostDiscountedCustomization";
    PlaceholdersID["TOTAL_COST_WITH_FEE_CUSTOMIZATION"] = "totalCostWithFeeCustomization";
    PlaceholdersID["DEVICE_ACT_COST_FREE"] = "deviceActCostFree";
    PlaceholdersID["DEVICE_ACT_COST_FREE_CUSTOMIZATION"] = "deviceActCostFreeCustomization";
    PlaceholdersID["TOTAL_RENEWAL_COST_DEVICE"] = "totalRenewalCostDevice";
    PlaceholdersID["TOTAL_DEVICE_COST"] = "totalDeviceCost";
    PlaceholdersID[// DS IOT
    "BS_NAME"] = "bsName";
    PlaceholdersID["BS_EDITION_NAME"] = "productName";
    PlaceholdersID["TOTAL_COST_FREE"] = "totalCostFree";
    PlaceholdersID["TOTAL_COST_WITH_FEE_DS"] = "totalCostWithFee";
    PlaceholdersID["TOTAL_COST_DISCOUNTED_DS"] = "totalCostDiscounted";
    PlaceholdersID["TOTAL_ACT_COST_WITH_FEE"] = "totalActCostWithFee";
    PlaceholdersID["DISCOUNTED_ACT_COST_WITH_FEE"] = "totalActCostDiscounted";
    PlaceholdersID["TOTAl_ACT_COST_FREE"] = "totalActCostFree";
    PlaceholdersID["DISCOUNTED_ACT_COST_FREE"] = "totalActCostDiscountedFree";
    PlaceholdersID["TOTAL_COST_UNA_TANTUM"] = "totalCostUnaTantum";
    PlaceholdersID["TOTAL_COST_UNA_TANTUM_DISCOUNTED"] = "totalCostUnaTantumDiscounted";
})(PlaceholdersID || (PlaceholdersID = {}));
export var PlaceholdersMap;
(function(PlaceholdersMap) {
    PlaceholdersMap["productName"] = "productName";
    PlaceholdersMap["renewalCost"] = "costs.renewalCost";
    PlaceholdersMap["discountedRenewalCost"] = "costs.discountedRenewalCost";
    PlaceholdersMap["aoRenCost"] = "addon.costs.renewalCost";
    PlaceholdersMap["discountedAoRenCost"] = "addon.costs.discountedRenewalCost";
    PlaceholdersMap["discountedAoRenNumber"] = "addon.costs.renewalNumber";
    PlaceholdersMap["activationCost"] = "addon.costs.activationCost";
    PlaceholdersMap["discountedActivationCost"] = "addon.costs.discountedActivationCost";
    PlaceholdersMap["capacity"] = "capacity.capacityLabel";
    PlaceholdersMap["deviceRenewalCost"] = "costs.renewalCost";
    PlaceholdersMap["discountedDeviceRenewalCost"] = "costs.discountedRenewalCost";
    PlaceholdersMap["deviceRenewalNumber"] = "costs.renewalNumber";
    PlaceholdersMap["deviceActCost"] = "costs.activationCost";
    PlaceholdersMap["discountedDeviceActCost"] = "costs.discountedActivationCost";
    PlaceholdersMap["totalActCost"] = "costs.renewalCost";
    PlaceholdersMap["businessSolutionName"] = "businessSolutionName";
    PlaceholdersMap["dsActCost"] = "costs.activationCost";
    PlaceholdersMap["discountedDsActCost"] = "costs.discountedActivationCost";
    PlaceholdersMap["totalRenewalCost"] = "costs.renewalCost";
    PlaceholdersMap["totalCost"] = "costs.renewalCost";
})(PlaceholdersMap || (PlaceholdersMap = {}));
export var DeviceDetailsPlaceholders;
(function(DeviceDetailsPlaceholders) {
    DeviceDetailsPlaceholders["MOBILEPHONE_PLAN"] = "$mobilephonePlan";
    DeviceDetailsPlaceholders["RENEWAL_COST_DEVICE"] = "$renewalCostDevice";
    DeviceDetailsPlaceholders["TOTAL_RENEWAL_COST_DEVICE"] = "$totalRenewalCostDevice";
    DeviceDetailsPlaceholders["DEVICE_COST_UNA_TANTUM"] = "$deviceCostUnaTantum";
    DeviceDetailsPlaceholders["TOTAL_DEVICE_COST"] = "$totalDeviceCost";
})(DeviceDetailsPlaceholders || (DeviceDetailsPlaceholders = {}));
export var invalidPlaceholderNumber = [
    "$productName",
    "$discountedAoRenNumber",
    "$deviceRenewalNumber"
];
