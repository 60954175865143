import { useCallback, useState } from "react";
export var useTabs = function(selected, tabs) {
    var ref = useState(selected), current = ref[0], setCurrent = ref[1];
    var handleKeyDown = useCallback(function(code) {
        switch(code){
            case "ArrowRight":
            case "ArrowDown":
                setCurrent(current < tabs.length - 1 ? current + 1 : 0);
                break;
            case "ArrowLeft":
            case "ArrowUp":
                setCurrent(current !== 0 ? current - 1 : tabs.length - 1);
                break;
            default:
                break;
        }
    }, [
        current,
        setCurrent,
        tabs
    ]);
    return {
        current: current,
        setCurrent: setCurrent,
        handleKeyDown: handleKeyDown
    };
};
