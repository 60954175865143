import { TrackingPageContext, getFormattedPriceString } from "@vfit/business/providers";
import { iFrameManager } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
import { IActionType } from "@vfit/shared/models";
import { useContext } from "react";
export var CTC_POSITION = {
    // following the VOLA desc rules NOT USE UNDERSCORE in the value!
    STICKY_OFFER: "STICKY-OFFER",
    TOP_PRODUCT: "TOP",
    PRODUCT_DETAIL: "PRODUCT-DETAIL",
    WHATSAPP_MODAL: "POPUP-WAPP-CTC",
    PRODUCT_CARD: "PRODUCT-CARD",
    STICKY_BAR: "STICKY-BAR",
    COVERAGE_TOOL_KO: "TOOL-KO"
};
export var useVolaUtils = function() {
    var queryClient = useQueryClient();
    var ref = useContext(TrackingPageContext), descriptionVolaUrl = ref.descriptionVolaUrl, pageType = ref.pageType;
    var getPageType = function() {
        // requested for VOLA desc creation
        if (pageType === "INNOVATIVE") return "DS";
        return pageType;
    };
    var formatUrlVola = function(url, product) {
        var urlWithPrice = "";
        if (url.search("DESC=") !== -1) {
            var ref, ref1;
            var urlArr = url.split("DESC=");
            var indexNextParam = urlArr[1].indexOf("&");
            var dxlProduct = queryClient.getQueryData([
                product === null || product === void 0 ? void 0 : product.offerId,
                "ecommerceCatalogCoreOffer", 
            ]);
            if (indexNextParam !== -1) urlWithPrice = "".concat(urlArr[0], "DESC=").concat(urlArr[1].slice(0, indexNextParam)).concat((product === null || product === void 0 ? void 0 : product.taggingProductPrice) || getFormattedPriceString(dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref = dxlProduct.productInfo) === null || ref === void 0 ? void 0 : ref[0])).concat(urlArr[1].slice(indexNextParam));
            else urlWithPrice = url + ((product === null || product === void 0 ? void 0 : product.taggingProductPrice) || getFormattedPriceString(dxlProduct === null || dxlProduct === void 0 ? void 0 : (ref1 = dxlProduct.productInfo) === null || ref1 === void 0 ? void 0 : ref1[0]));
        } else urlWithPrice = url;
        return urlWithPrice;
    };
    var formatDynamicVolaUrl = function(url, desc) {
        var completeUrl = url;
        if (url.search("DESC=") !== -1) {
            var urlArr = url.split("DESC=");
            var indexNextParam = urlArr[1].indexOf("&");
            if (indexNextParam !== -1) completeUrl = "".concat(urlArr[0], "DESC=").concat(desc).concat(urlArr[1].slice(indexNextParam));
            else completeUrl = "".concat(urlArr[0], "DESC=").concat(desc);
        }
        return completeUrl;
    };
    var createUrlDesc = function(param) {
        var position = param.position, productName = param.productName, productPrice = param.productPrice;
        if (descriptionVolaUrl) {
            var descriptionUrl = {
                env: "".concat((descriptionVolaUrl === null || descriptionVolaUrl === void 0 ? void 0 : descriptionVolaUrl.environment) || "", "_"),
                digitalAsset: "".concat((descriptionVolaUrl === null || descriptionVolaUrl === void 0 ? void 0 : descriptionVolaUrl.digitalAsset) || "", "_"),
                abTest: "".concat((descriptionVolaUrl === null || descriptionVolaUrl === void 0 ? void 0 : descriptionVolaUrl.abTestIdentifier) || "", "_"),
                channel: "".concat((descriptionVolaUrl === null || descriptionVolaUrl === void 0 ? void 0 : descriptionVolaUrl.channeltype) || "", "_"),
                pageType: "".concat((descriptionVolaUrl === null || descriptionVolaUrl === void 0 ? void 0 : descriptionVolaUrl.pageType) || "", "_"),
                page: "".concat((descriptionVolaUrl === null || descriptionVolaUrl === void 0 ? void 0 : descriptionVolaUrl.page) || "", "_"),
                position: "".concat(position || "", "_"),
                category: "".concat(getPageType() || "", "_"),
                name: "".concat(productName || "", "_"),
                price: productPrice || "_"
            };
            return Object.values(descriptionUrl).join("").toLocaleLowerCase().split(" ").join("-");
        }
        return undefined;
    };
    var openPopupVola = function(url, id, onClose) {
        if (!url) return;
        iFrameManager.handleIFrame({
            url: url,
            id: id,
            onClose: onClose
        });
    };
    var getVolaUrl = function(product) {
        var ref, ref1;
        return (ref1 = product === null || product === void 0 ? void 0 : (ref = product.actions) === null || ref === void 0 ? void 0 : ref.find(function(b) {
            return (b === null || b === void 0 ? void 0 : b.type) == IActionType.WHATSAPP_URL;
        })) === null || ref1 === void 0 ? void 0 : ref1.url;
    };
    return {
        formatUrlVola: formatUrlVola,
        openPopupVola: openPopupVola,
        getVolaUrl: getVolaUrl,
        createUrlDesc: createUrlDesc,
        formatDynamicVolaUrl: formatDynamicVolaUrl
    };
};
