/* eslint-disable react-hooks/exhaustive-deps */ import { useState } from "react";
import { CoverageErrorType, geographicAddressManagement, formatDxlCosts, getEcommerceCatalogWithAddress } from "@vfit/business/data-access";
import { useQueryClient } from "react-query";
import { useHandleQueryWithAuthSSO } from "@vfit/business/providers";
import { useCoverageToolModalProvider } from "./coverageToolModal.context";
export var useCoverageToolFlow = function(param) {
    var onConfirmAddress = param.onConfirmAddress, product = param.product, address = param.address;
    var ref, ref1, ref2, ref3, ref4;
    var ref5 = useState(), errorType = ref5[0], setErrorType = ref5[1];
    var queryClient = useQueryClient();
    var setOfferType = useCoverageToolModalProvider().setOfferType;
    var validAddress = queryClient.getQueryData("geographicAddressManagement");
    var flowEnabler = !!(validAddress === null || validAddress === void 0 ? void 0 : (ref = validAddress.validAddress) === null || ref === void 0 ? void 0 : ref.city) && !!(validAddress === null || validAddress === void 0 ? void 0 : (ref1 = validAddress.validAddress) === null || ref1 === void 0 ? void 0 : ref1.postcode) && !!(validAddress === null || validAddress === void 0 ? void 0 : (ref2 = validAddress.validAddress) === null || ref2 === void 0 ? void 0 : ref2.stateOrProvince) && !!(validAddress === null || validAddress === void 0 ? void 0 : (ref3 = validAddress.validAddress) === null || ref3 === void 0 ? void 0 : ref3.streetName) && !!(validAddress === null || validAddress === void 0 ? void 0 : (ref4 = validAddress.validAddress) === null || ref4 === void 0 ? void 0 : ref4.streetNr) && onConfirmAddress;
    var ecommerceCatalogWithAddress = {
        queryKey: "ecommerceCatalogWithAddress",
        queryFn: function() {
            return getEcommerceCatalogWithAddress({
                product: product,
                address: address
            });
        },
        options: {
            onSuccess: function(data) {
                formatDxlCosts(data);
                setOfferType === null || setOfferType === void 0 ? void 0 : setOfferType({
                    isOfferAvailable: data === null || data === void 0 ? void 0 : data.isOfferAvailable,
                    technology: data === null || data === void 0 ? void 0 : data.technology,
                    isFwa: data === null || data === void 0 ? void 0 : data.isFwa
                });
                localStorage.setItem("catalog_address", JSON.stringify(data));
                localStorage.setItem("valid_address", JSON.stringify(address));
            },
            onError: function(error) {
                localStorage.removeItem("catalog_address");
                localStorage.removeItem("valid_address");
                var status = error.status;
                var statusNumber = Number(status);
                setErrorType(CoverageErrorType.KO_COVERAGE);
                if (statusNumber >= 400 && statusNumber <= 500) {
                    setErrorType(CoverageErrorType.KO_COVERAGE);
                } else {
                    setErrorType(CoverageErrorType.KO_TECHNICAL);
                }
            },
            enabled: flowEnabler
        }
    };
    var ref6 = useHandleQueryWithAuthSSO(ecommerceCatalogWithAddress), isSuccess = ref6.isSuccess, isLoading = ref6.isLoading;
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        errorType: errorType
    };
};
export var useAddressAndVillage = function(param) {
    var address = param.address, onConfirmAddress = param.onConfirmAddress, setOnConfirmed = param.setOnConfirmed;
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(), addressValidated = ref3[0], setAddressValidated = ref3[1];
    var geographicAddress = {
        queryKey: "geographicAddressManagement",
        queryFn: function() {
            setIsLoading(true);
            return geographicAddressManagement(address);
        },
        options: {
            onSuccess: function(data) {
                setIsSuccess(true);
                setAddressValidated(data);
            },
            onError: function() {
                setIsError(true);
            },
            onSettled: function() {
                setOnConfirmed(false);
                setIsLoading(false);
            },
            enabled: onConfirmAddress
        }
    };
    useHandleQueryWithAuthSSO(geographicAddress);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        data: addressValidated,
        setIsError: setIsError
    };
};
