export var IOfferType;
(function(IOfferType) {
    IOfferType["FIXED_LINE"] = "FIXED_LINE";
    IOfferType["MOBILE_LINE"] = "MOBILE_LINE";
})(IOfferType || (IOfferType = {}));
export var ICMSWidgetType;
(function(ICMSWidgetType) {
    ICMSWidgetType["FAQ"] = "FAQ";
    ICMSWidgetType["SPLITTED_IMAGE_TEXT"] = "SPLITTED_IMAGE_TEXT";
    ICMSWidgetType["SLIDER_LARGE_SMALL"] = "SLIDER_LARGE_SMALL";
    ICMSWidgetType["SLIDER_PRODUCTS_HUB_BUSINESS"] = "SLIDER_PRODUCTS_HUB_BUSINESS";
    ICMSWidgetType["PRODUCT_HERO_BANNER"] = "PRODUCT_HERO_BANNER";
    ICMSWidgetType["SLIDER_ICON_TITLE_DESCRIPTION"] = "SLIDER_ICON_TITLE_DESCRIPTION";
    ICMSWidgetType["OFFER_MAP_SHOPPING_CART"] = "OFFER_MAP_SHOPPING_CART";
    ICMSWidgetType["SHOPPING_CART_TECHNOLOGIES"] = "SHOPPING_CART_TECHNOLOGIES";
    ICMSWidgetType["SLIDER_IMAGE_TITLE_DESCRIPTION"] = "SLIDER_IMAGE_TITLE_DESCRIPTION";
    ICMSWidgetType["SLIDER_ADDON"] = "SLIDER_ADDON";
    ICMSWidgetType["SLIDER_DEFAULT"] = "SLIDER_DEFAULT";
    ICMSWidgetType["HUB_DEVICE"] = "HUB_DEVICE";
    ICMSWidgetType["TEXT"] = "TEXT";
    ICMSWidgetType["DEVICE_HERO_BANNER"] = "DEVICE_HERO_BANNER";
    ICMSWidgetType["SPLITTED_TABLE_TEXT"] = "SPLITTED_TABLE_TEXT";
    ICMSWidgetType["HUB_DS"] = "HUB_DS";
    ICMSWidgetType["DS_HERO_BANNER"] = "DS_HERO_BANNER";
    ICMSWidgetType["TOP_HERO"] = "TOP_HERO";
    ICMSWidgetType["TABS"] = "TABS";
    ICMSWidgetType["SEARCH_NATION"] = "SEARCH_NATION";
    ICMSWidgetType["GUIDED_SELLING_TOOL"] = "GUIDED_SELLING_TOOL";
    ICMSWidgetType["OPERATOR_PLANS_MODAL"] = "OPERATOR_PLANS_MODAL";
    ICMSWidgetType["SLIDER_PRODUCT_DS_PDP"] = "SLIDER_PRODUCT_DS_PDP";
    ICMSWidgetType["EDITORIAL_CARD"] = "EDITORIAL_CARD";
    ICMSWidgetType["SLOT_CALENDAR"] = "SLOT_CALENDAR";
    ICMSWidgetType["EDITORIAL_CARD_SLIDER"] = "EDITORIAL_CARD_SLIDER";
    ICMSWidgetType["SIM_ACTIVATION_OTP"] = "SIM_ACTIVATION_OTP";
    ICMSWidgetType["SEARCHING_OFFERS_EU"] = "SEARCHING_OFFERS_EU";
    ICMSWidgetType["SERIAL_CONFIRMATION_OTP"] = "SERIAL_CONFIRMATION_OTP";
    ICMSWidgetType["COLUMN_ITEMS"] = "COLUMN_ITEMS";
    ICMSWidgetType["OFFERS_WORLD"] = "OFFERS_WORLD";
    ICMSWidgetType["FULL_BANNER"] = "FULL_BANNER";
    ICMSWidgetType["LANDING_ESTERO_POPUP"] = "LANDING_ESTERO_POPUP";
})(ICMSWidgetType || (ICMSWidgetType = {}));
export var KEY_DEVICES = {
    POWER_4G: "power-4g"
};
export var ErrorTypes;
(function(ErrorTypes) {
    ErrorTypes["GENERIC_ERROR"] = "generic-error";
    ErrorTypes["SERVICE_ERROR"] = "service-error";
    ErrorTypes["OFFER_ERROR"] = "offer-error";
})(ErrorTypes || (ErrorTypes = {}));
