import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getPriceDescriptionDeviceCard } from "@vfit/business/data-access";
export var getValues = function(characteristic, name) {
    var ref, ref1;
    var values = (ref = characteristic === null || characteristic === void 0 ? void 0 : characteristic.find(function(ch) {
        return ch.name === name;
    })) === null || ref === void 0 ? void 0 : ref.value;
    return (values === null || values === void 0 ? void 0 : (ref1 = values[0]) === null || ref1 === void 0 ? void 0 : ref1.value) || "";
};
export var formatData = function(widget, devices) {
    var products = devices === null || devices === void 0 ? void 0 : devices.map(function(product) {
        return {
            dxlId: product === null || product === void 0 ? void 0 : product.deviceId,
            image: getValues((product === null || product === void 0 ? void 0 : product.characteristic) || [], "colors") || "",
            brand: product.deviceBrand || "",
            title: product.deviceName || "",
            offerLabel: widget.frontLabel || "",
            price: getPriceDescriptionDeviceCard(product.lowerCost || "0", widget.recurrence || ""),
            discount: getValues((product === null || product === void 0 ? void 0 : product.characteristic) || [], "deviceDetailList") || "",
            description: getValues((product === null || product === void 0 ? void 0 : product.characteristic) || [], "deviceShortDescription") || "",
            action: _object_spread_props(_object_spread({}, widget.customAction), {
                url: getValues((product === null || product === void 0 ? void 0 : product.characteristic) || [], "deviceUrl") || ""
            }),
            haveMarketingLabel: !!getValues((product === null || product === void 0 ? void 0 : product.characteristic) || [], "deviceTag"),
            tagLine: getValues((product === null || product === void 0 ? void 0 : product.characteristic) || [], "deviceTag") || "",
            isOutOfStock: (product === null || product === void 0 ? void 0 : product.availableInStock) == false ? true : false
        };
    });
    return {
        title: widget.title || "",
        labelFilter: widget.labelFilter || "",
        filter: widget.filter,
        filterSetLabel: widget.labelSelectedFilter || "",
        products: products
    };
};
