import _define_property from "@swc/helpers/src/_define_property.mjs";
import { API, PAGES } from "@vfit/shared/data-access";
import { useQueryClient } from "react-query";
var _obj;
export var CMS_CONFIG = (_obj = {}, _define_property(_obj, PAGES.BUSINESS_HOME_PAGE, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getPages",
            api: API.CMS_GET_PAGES_BUSINESS
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_FIXED_LINE, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getBusinessFixedLine",
            api: API.CMS_BFL_GET_PAGE
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getBusinessFixedLineCoverageTool",
            api: API.CMS_BFL_GET_COVERAGE_TOOLS
        },
        {
            key: "getBusinessFixedLinePlaceholders",
            api: API.CMS_BFL_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getCommonError",
            api: API.CMS_GET_COMMON_ERROR
        },
        {
            key: "getBusinessFixedLineCommonWhatsapp",
            api: API.CMS_BFL_GET_COMMON_WHATSAPP
        },
        {
            key: "getBusinessFixedLineCommonTechnologies",
            api: API.CMS_BFL_GET_TECNOLOGIES
        },
        {
            key: "getBusinessFixedLineCommonDevices",
            api: API.CMS_BFL_GET_DEVICES
        },
        {
            key: "getBusinessFixedLineCommonShoppingCart",
            api: API.CMS_BFL_GET_SHOPPING_CART
        },
        {
            key: "getBusinessFixedLineDetailProducts",
            api: API.CMS_BFL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getBusinessFixedLineAddons",
            api: API.CMS_BFL_GET_ADDONS
        },
        {
            key: "getBusinessOffersFilter",
            api: API.CMS_BTO_GET_OFFERS_FILTER
        },
        {
            key: "getBusinessOffersFilterForPopUp",
            api: API.CMS_BTO_GET_OFFERS_FILTER_NEW
        },
        {
            key: "getBusinessPlansOperator",
            api: API.CMS_BTO_GET_PLANS_OPERATOR
        },
        {
            key: "getNations",
            api: API.CMS_GET_NATIONS_VBI
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_FIXED_LINE_PDP, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getBusinessFixedLineProducts",
            api: API.CMS_BFL_GET_PRODUCTS
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getBusinessFixedLineCoverageTool",
            api: API.CMS_BFL_GET_COVERAGE_TOOLS
        },
        {
            key: "getBusinessFixedLineCommonWhatsapp",
            api: API.CMS_BFL_GET_COMMON_WHATSAPP
        },
        {
            key: "getBusinessFixedLinePlaceholders",
            api: API.CMS_BFL_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getCommonError",
            api: API.CMS_GET_COMMON_ERROR
        },
        {
            key: "getBusinessFixedLineDetailProducts",
            api: API.CMS_BFL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_MOBILE_LINE, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getBusinessVoiceLine",
            api: API.CMS_BVL_GET_PAGE
        },
        {
            key: "getBusinessVoiceLineProducts",
            api: API.CMS_BVL_GET_PRODUCTS
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getMobileLinePlaceholders",
            api: API.CMS_BML_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getMobileLineCommonWhatsapp",
            api: API.CMS_BML_GET_COMMON_WHATSAPP
        },
        {
            key: "getCommonError",
            api: API.CMS_GET_COMMON_ERROR
        },
        {
            key: "getBusinessVoiceLineAddons",
            api: API.CMS_BVL_GET_ADDONS
        },
        {
            key: "getBusinessVoiceLineDetailProducts",
            api: API.CMS_BVL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_DATI_LINE, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getBusinessDatiLine",
            api: API.CMS_BDL_GET_PAGE
        },
        {
            key: "getBusinessDatiLineProducts",
            api: API.CMS_BDL_GET_PRODUCTS
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getMobileLinePlaceholders",
            api: API.CMS_BML_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getDatiLineCommonWhatsapp",
            api: API.CMS_BDL_GET_WHATSAPP
        },
        {
            key: "getCommonError",
            api: API.CMS_GET_COMMON_ERROR
        },
        {
            key: "getBusinessDatiLineAddons",
            api: API.CMS_BDL_GET_ADDONS
        },
        {
            key: "getBusinessDatiLineDetailProducts",
            api: API.CMS_BDL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_MOBILE_LINE_PDP, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getBusinessVoiceLineProducts",
            api: API.CMS_BVL_GET_PRODUCTS
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getMobileLineCommonWhatsapp",
            api: API.CMS_BML_GET_COMMON_WHATSAPP
        },
        {
            key: "getMobileLinePlaceholders",
            api: API.CMS_BML_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getBusinessVoiceLineAddons",
            api: API.CMS_BVL_GET_ADDONS
        },
        {
            key: "getBusinessVoiceLineDetailProducts",
            api: API.CMS_BVL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_DATI_LINE_PDP, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getBusinessDatiLineProducts",
            api: API.CMS_BDL_GET_PRODUCTS
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getDatiLineCommonWhatsapp",
            api: API.CMS_BDL_GET_WHATSAPP
        },
        {
            key: "getMobileLinePlaceholders",
            api: API.CMS_BML_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getBusinessDatiLineAddons",
            api: API.CMS_BDL_GET_ADDONS
        },
        {
            key: "getBusinessDatiLineDetailProducts",
            api: API.CMS_BDL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_SMARTPHONE, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getBusinessDeviceSmartphone",
            api: API.CMS_DS_GET_PAGE
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_TABLET, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getBusinessDeviceTablet",
            api: API.CMS_DT_GET_PAGE
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_SMARTPHONE_PDP, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getSmartphoneDevices",
            api: API.CMS_DS_GET_DEVICES
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getBusinessSmartphonePlaceholders",
            api: API.CMS_DS_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getCapacityAndColor",
            api: API.CMS_GET_CAPACITY_AND_COLOR
        },
        {
            key: "getBusinessSmartphoneDetailProducts",
            api: API.CMS_DS_GET_DETAIL_PRODUCTS
        },
        {
            key: "getBusinessVoiceLineDetailProducts",
            api: API.CMS_BVL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getBusinessDatiLineDetailProducts",
            api: API.CMS_BDL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getSmartphoneCommonWhatsapp",
            api: API.CMS_DS_GET_COMMON_WHATSAPP
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_TABLET_PDP, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getTabletDevices",
            api: API.CMS_DT_GET_DEVICES
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getBusinessTabletPlaceholders",
            api: API.CMS_DT_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getCapacityAndColor",
            api: API.CMS_GET_CAPACITY_AND_COLOR
        },
        {
            key: "getBusinessTabletDetailProducts",
            api: API.CMS_DT_GET_DETAIL_PRODUCTS
        },
        {
            key: "getBusinessVoiceLineDetailProducts",
            api: API.CMS_BVL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getBusinessDatiLineDetailProducts",
            api: API.CMS_BDL_GET_DETAIL_PRODUCTS
        },
        {
            key: "getTabletCommonWhatsapp",
            api: API.CMS_DT_GET_COMMON_WHATSAPP
        }, 
    ]
}), _define_property(_obj, PAGES.PAGE_NOT_FOUND, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getPageNotFound",
            api: API.CMS_PAGE_NOT_FOUND
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        }, 
    ]
}), _define_property(_obj, "BUSINESS_DSIOT_PDP", {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getBusinessDSIOTCommonWhatsapp",
            api: API.CMS_DSIOT_GET_COMMON_WHATSAPP
        },
        {
            key: "getBusinessDSIOTPlaceholders",
            api: API.CMS_DSIOT_GET_COMMON_PLACEHOLDER
        },
        {
            key: "getCommonError",
            api: API.CMS_GET_COMMON_ERROR
        }, 
    ]
}), _obj);
export var getConfiguration = function(layout, pageType) {
    if (layout === "HUB") {
        if (pageType === "FIXED") return CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE];
        if (pageType === "VOCE") return CMS_CONFIG[PAGES.BUSINESS_MOBILE_LINE];
        if (pageType === "DATI") return CMS_CONFIG[PAGES.BUSINESS_DATI_LINE];
    }
    if (layout === "PDP") {
        if (pageType === "FIXED") return CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE_PDP];
        if (pageType === "VOCE") return CMS_CONFIG[PAGES.BUSINESS_MOBILE_LINE_PDP];
        if (pageType === "DATI") return CMS_CONFIG[PAGES.BUSINESS_DATI_LINE_PDP];
        if (pageType === "INNOVATIVE") return CMS_CONFIG["BUSINESS_DSIOT_PDP"];
    }
    if (layout === "BLANK") {
        if (pageType === "FIXED") return CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE]; // add for shopping cart fwa page
    }
    return {
        apis: [
            {
                key: "getPageNavigation_BL",
                api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
            },
            {
                key: "getGenericError",
                api: API.CMS_GET_GENERIC_ERROR
            },
            {
                key: "getCommonError",
                api: API.CMS_GET_COMMON_ERROR
            }, 
        ]
    };
};
export var useCmsConfig = function(page, apiKey) {
    var apis = page.apis;
    var allData = {};
    apis.forEach(function(api) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var client = useQueryClient();
        var data = client.getQueryData(api.key, {
            exact: false
        });
        allData[api.api] = data;
    });
    return apiKey ? allData[apiKey] : allData;
};
