export var getTableRowsList = function(elements) {
    return elements.map(function(item, index) {
        var isFirstElement = index === 0;
        var isLastElement = index === elements.length - 1;
        return {
            label: (item === null || item === void 0 ? void 0 : item.label) || "",
            value: (item === null || item === void 0 ? void 0 : item.value) || "",
            isFirstRow: isFirstElement,
            isLastRow: isLastElement
        };
    });
};
