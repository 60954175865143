import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  min-height: 100vh;\n  padding-top: 50px;\n  background-color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    background-color: ",
        ";\n    padding-top: 160px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
var FullPageLayout = styled.div.withConfig({
    componentId: "sc-e34cfae3-0"
})(_templateObject(), colors.$ffffff, breakpoints.desktop, colors.$f2f2f2);
export { FullPageLayout };
