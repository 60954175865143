import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  margin-top: 236px;\n  text-align: center;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  font-family: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  padding-top: 50px;\n  width: 335px;\n  margin: 0 auto;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-1776f376-0"
})(_templateObject());
export var Title = styled.div.withConfig({
    componentId: "sc-1776f376-1"
})(_templateObject1(), colors.$e60000, fonts.exbold, pxToCssFont(70, 70));
export var ButtonError = styled.div.withConfig({
    componentId: "sc-1776f376-2"
})(_templateObject2());
