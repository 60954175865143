import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\nheight: 0px;\ndisplay: ",
        ";\n  @keyframes showBox {\n    to {\n     height: 80px; \n    }\n    from {\n      height: 0px;\n    }\n  }\n\n  animation-name: showBox;\n  animation-delay: 1.2s;\n  animation-duration: 0.8s;\n  animation-fill-mode: forwards;\n\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  z-index: 4;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  opacity: 0.9;\n  height: 80px;\n  transform: translateY(-80px);\n  .wrapperIcon {\n    width: 50px;\n    height: 50px;\n  }\n\n  display: ",
        ";\n  @keyframes show {\n    to {\n      transform: translateY(0px);\n    }\n    from {\n      transform: translateY(-80px);\n    }\n  }\n  animation-name: show;\n  animation-delay: 1.2s;\n  animation-duration: 0.8s;\n  animation-fill-mode: forwards;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  gap: 13px;\n  width: 100%;\n  padding: 15px;\n  background-color: ",
        ";\n  @media (max-width: ",
        ") {\n   div {\n    width: 100%;\n   }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  ",
        "\n  font-family: ",
        ";\n  display: block;\n  @media (max-width: ",
        ") {\n   display: none;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\ndisplay: block;\n  @media (max-width: ",
        ") {\n   display: none;\n  }\n  \n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Stickybox = styled.div.attrs(function(props) {
    return {
        isVisible: props.isVisible
    };
}).withConfig({
    componentId: "sc-22f70f0d-0"
})(_templateObject(), function(props) {
    return props.isVisible ? "block" : "none";
});
export var StickyContainer = styled.div.attrs(function(props) {
    return {
        isVisible: props.isVisible
    };
}).withConfig({
    componentId: "sc-22f70f0d-1"
})(_templateObject1(), function(props) {
    return props.isVisible ? "block" : "none";
});
export var StyledBar = styled.div.withConfig({
    componentId: "sc-22f70f0d-2"
})(_templateObject2(), colors.$ffffff, breakpoints.desktop);
export var StyledText = styled.h5.withConfig({
    componentId: "sc-22f70f0d-3"
})(_templateObject3(), pxToCssFont(24, 30), fonts.exbold, breakpoints.desktop);
export var StyledIcon = styled.div.withConfig({
    componentId: "sc-22f70f0d-4"
})(_templateObject4(), breakpoints.desktop);
