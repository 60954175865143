import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useContext, useEffect, useState } from "react";
import { getEcommerceCatalog, getProductVersion, formatDxlCosts } from "@vfit/business/data-access";
import { errorManager, ErrorAction, ErrorSeverity, PageLoadingContext, useHandleQueriesWithAuthSSO } from "@vfit/business/providers";
import { PAGES } from "@vfit/shared/data-access";
var useGetProductEcommerceCatalog = function(elements) {
    var enabled = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var ref = useState(true), isLoadingEcomm = ref[0], setIsLoadingEcomm = ref[1];
    var queries = [];
    elements.forEach(function(item) {
        var ref;
        if (item.isEditorialCard === "true") return;
        var version = getProductVersion(item === null || item === void 0 ? void 0 : item.dxlId, item === null || item === void 0 ? void 0 : item.version, item === null || item === void 0 ? void 0 : (ref = item.landingprive) === null || ref === void 0 ? void 0 : ref.isPrive);
        queries.push({
            queryKey: [
                item.dxlId,
                "ecommerceCatalogCoreOffer"
            ],
            queryFn: function() {
                return getEcommerceCatalog(_object_spread({
                    productId: item.dxlId,
                    productType: item.productType
                }, version && {
                    productVersion: version
                }));
            },
            options: {
                onSuccess: function(data) {
                    return formatDxlCosts(data);
                },
                onError: function(error) {
                    var status = error.status;
                    var statusNumber = Number(status);
                    errorManager.handleError(ErrorSeverity.HIGH, {
                        errorAction: ErrorAction.PAGE,
                        errorCode: statusNumber
                    });
                },
                enabled: enabled
            }
        });
    });
    var queriesResults = useHandleQueriesWithAuthSSO(queries);
    useEffect(function() {
        if (!isLoadingEcomm) setIsLoading(false);
    }, [
        isLoadingEcomm
    ]);
    useEffect(function() {
        if (Object.values(queriesResults).some(function(item) {
            return item.status !== "success";
        })) return;
        if (elements.length === Object.values(queriesResults).length && Object.values(queriesResults).every(function(item) {
            return item.status === "success";
        }) && Object.values(queriesResults).every(function(item) {
            var ref;
            return ((ref = item.data) === null || ref === void 0 ? void 0 : ref.productInfo.length) === 0;
        })) {
            errorManager.handleError(ErrorSeverity.HIGH, {
                errorAction: ErrorAction.PAGE,
                errorPage: PAGES.OFFER_ERROR
            });
        } else {
            setIsLoadingEcomm(false);
        }
    }, [
        queriesResults
    ]);
    var catalog = Object.values(queriesResults).map(function(query) {
        var ref;
        return (ref = query.data) === null || ref === void 0 ? void 0 : ref.productInfo[0];
    }).filter(Boolean);
    return {
        catalog: catalog
    };
};
export { useGetProductEcommerceCatalog };
