import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useDeviceType } from "@vfit/shared/hooks";
import { getActionUrlByActionType } from "@vfit/shared/data-access";
import { formatFaqProps } from "./widgetFaq.utils";
export var useWidgetFaq = function(widget) {
    var ref = formatFaqProps(widget), faq = ref.faq, topText = ref.topText;
    var isMobile = useDeviceType().isMobile;
    var containerStyle = {
        paddingTop: isMobile ? 32 : 48
    };
    return _object_spread({
        faq: faq,
        topText: topText,
        containerStyle: containerStyle,
        divider: widget.divider,
        disclaimer: widget.disclaimer
    }, widget.customAction && {
        actionNavigationUrl: getActionUrlByActionType(widget.customAction)
    });
};
