import { IActionType } from "@vfit/shared/models";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { manageErrorLeadPlatform, manageSuccessLeadPlatform, openLeadModal } from "@vfit/shared/data-access";
import { AuthenticationContext } from "@vfit/business/providers";
import { getFormatprops } from "./widgetCardInfoProduct.utils";
export var useWidgetCardInfo = function(widget) {
    var formatPropscards = widget.elements ? getFormatprops(widget.elements) : [];
    var queryClient = useQueryClient();
    var authType = useContext(AuthenticationContext).authType;
    var push = useRouter().push;
    var ref = useState({
        show: false,
        text: "",
        title: ""
    }), modal = ref[0], setModal = ref[1];
    var onActionClick = function(action, key) {
        var ref;
        var type = (action === null || action === void 0 ? void 0 : action.type) ? +action.type : 0;
        var card = (ref = widget.elements) === null || ref === void 0 ? void 0 : ref.find(function(el, index) {
            return index === Number(key);
        });
        switch(type){
            case IActionType.CUSTOM_URL:
                if (action === null || action === void 0 ? void 0 : action.url) window.open(action.url, (action === null || action === void 0 ? void 0 : action.isBlank) === "true" ? "_blank" : "_self");
                break;
            case IActionType.LOCAL_URL:
                if (action === null || action === void 0 ? void 0 : action.url) push(action === null || action === void 0 ? void 0 : action.url);
                break;
            case IActionType.POPUP_DETAIL:
                if (card === null || card === void 0 ? void 0 : card.popupDetail) {
                    setModal({
                        show: true,
                        text: card === null || card === void 0 ? void 0 : card.popupDetail.descriptionHtml,
                        title: card === null || card === void 0 ? void 0 : card.popupDetail.title
                    });
                }
                break;
            case IActionType.LEAD_MODAL:
                if (queryClient) {
                    var allLeads = queryClient === null || queryClient === void 0 ? void 0 : queryClient.getQueryData("getAllLeads");
                    // form name
                    var ref1 = (action === null || action === void 0 ? void 0 : action.url) || "";
                    var forms = allLeads === null || allLeads === void 0 ? void 0 : allLeads[ref1].forms;
                    if (forms) {
                        openLeadModal({
                            form: forms,
                            onSuccess: function(submitOutput) {
                                return manageSuccessLeadPlatform(submitOutput, push, queryClient);
                            },
                            onError: function(submitOutput, errorCmsApi) {
                                return manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient);
                            },
                            trackingOption: {
                                visitor_type: authType
                            }
                        });
                    }
                }
                break;
            default:
                break;
        }
    };
    return {
        topText: widget.titleHtml,
        description: widget.descriptionHtml,
        divider: widget.divider,
        disclaimer: widget.disclaimerHtml,
        newCards: formatPropscards,
        onClickCardAction: onActionClick,
        modal: modal,
        setModal: setModal
    };
};
