import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
/**
 * Method to return the faq widget
 * @param widget
 */ var formatFaqProps = function(widget) {
    var ref;
    return {
        topText: (widget === null || widget === void 0 ? void 0 : widget.title) || "",
        faq: {
            divider: widget === null || widget === void 0 ? void 0 : widget.divider,
            elements: (widget === null || widget === void 0 ? void 0 : widget.elements) || [],
            showMore: _object_spread_props(_object_spread({}, widget.customAction), {
                style: Number((ref = widget.customAction) === null || ref === void 0 ? void 0 : ref.style)
            })
        }
    };
};
export { formatFaqProps };
