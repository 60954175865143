import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  div {\n    margin: 0;\n    line-height: 42px;\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    p {\n      margin: 0;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n\n  div {\n    width: 219px;\n  }\n\n  @media (max-width: ",
        ") {\n    div {\n      width: 100%;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\ndisplay: flex;\n  span{\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var VersionContainer = styled.div.withConfig({
    componentId: "sc-8b74cac3-0"
})(_templateObject());
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-8b74cac3-1"
})(_templateObject1(), pxToCssFont(18, 16));
export var ModalDescription = styled.div.withConfig({
    componentId: "sc-8b74cac3-2"
})(_templateObject2());
export var ModalButtonContainer = styled.div.withConfig({
    componentId: "sc-8b74cac3-3"
})(_templateObject3(), breakpoints.tablet);
export var CheckBoxContainer = styled.div.withConfig({
    componentId: "sc-8b74cac3-4"
})(_templateObject4(), pxToCssFont(14, 18));
