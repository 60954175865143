import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: inherit;\n  display: flex;\n  cursor: pointer;\n  margin: 0;\n  appearance: none;\n  background: none;\n  border: none;\n  font-family: inherit;\n  font-size: inherit;\n  padding: 0;\n  text-decoration: none;\n\n  div {\n    transform: rotate(180deg);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      width: 9px;\n      height: 16px;\n\n      path {\n        stroke: ",
        ";\n      }\n    }\n  }\n\n  span {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    line-height: 22px;\n    margin-left: 15px;\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { colors, fonts, pxToCssFontSize } from "@vfit/shared/themes";
import styled from "styled-components";
export var NavBackLinkA = styled.a.withConfig({
    componentId: "sc-a85e759a-0"
})(_templateObject(), function(props) {
    return props.color || colors.$262626;
}, fonts.regular, pxToCssFontSize(16), function(props) {
    return props.color || colors.$262626;
});
