import { IActionType } from "@vfit/shared/models";
import { useRouter } from "next/router";
import { useState } from "react";
import { getFormatprops } from "./widgetAddons.utils";
export var useWidgetAddons = function(widget) {
    var formatPropscards = widget.elements ? getFormatprops(widget.elements) : [];
    var push = useRouter().push;
    var ref = useState({
        show: false,
        text: "",
        title: ""
    }), modal = ref[0], setModal = ref[1];
    var onActionClick = function(action, key) {
        var ref;
        var type = (action === null || action === void 0 ? void 0 : action.type) ? +action.type : 0;
        var addon = (ref = widget.elements) === null || ref === void 0 ? void 0 : ref.find(function(el) {
            return el.addonId === key;
        });
        switch(type){
            case IActionType.CUSTOM_URL:
                if (action === null || action === void 0 ? void 0 : action.url) window.open(action.url, (action === null || action === void 0 ? void 0 : action.isBlank) === "true" ? "_blank" : "_self");
                break;
            case IActionType.LOCAL_URL:
                if (action === null || action === void 0 ? void 0 : action.url) push(action === null || action === void 0 ? void 0 : action.url);
                break;
            case IActionType.POPUP_DETAIL:
                if (addon === null || addon === void 0 ? void 0 : addon.popupDetail) {
                    setModal({
                        show: true,
                        text: addon === null || addon === void 0 ? void 0 : addon.popupDetail.descriptionHtml,
                        title: addon === null || addon === void 0 ? void 0 : addon.popupDetail.title
                    });
                }
                break;
            default:
                break;
        }
    };
    return {
        topText: widget.titleHtml,
        description: widget.descriptionHtml,
        divider: widget.divider,
        disclaimer: widget.disclaimerHtml,
        newCards: formatPropscards,
        onClickCardAction: onActionClick,
        modal: modal,
        setModal: setModal
    };
};
