import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  min-height: 442px;\n\n  @media (min-width: ",
        ") {\n    height: 800px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: inherit;\n    min-height: 680px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  border-radius: 0 0 20px 20px;\n  position: ",
        ";\n  top: ",
        ";\n  left: ",
        ";\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n  padding-left: 30px;\n  margin-bottom: 28px;\n  display: flex;\n  align-items: center;\n\n  @media (min-width: ",
        ") {\n    margin-top: 67px;\n    padding-left: 65px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 56px;\n    padding-left: 80px;\n    margin-bottom: inherit;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.8;\n\n  @media (min-width: ",
        ") {\n    flex: 0.9;\n  }\n\n  @media (min-width: ",
        ") {\n    flex: 0.8;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.2;\n  display: flex;\n  justify-content: center;\n  padding-bottom: 60px;\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 0;\n    flex: 0.1;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 60px;\n    flex: 0.2;\n    position: absolute;\n    bottom: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: 324px;\n  max-width: 400px;\n  padding-bottom: 25px;\n\n  div {\n    width: 324px;\n    max-width: 400px;\n    padding-left: 30px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  h2 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    letter-spacing: ",
        ";\n    text-transform: uppercase;\n    color: ",
        ";\n    margin: 0 30px;\n  }\n\n  h3 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n    margin: 0;\n    max-width: none;\n  }\n\n  h4 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 30px 30px 0;\n    max-width: none;\n  }\n\n  h5 {\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    color: ",
        ";\n    margin: 0 30px;\n  }\n\n  h6 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 10px 30px 0;\n    max-width: none;\n  }\n\n  .text {\n    color: ",
        ";\n    font-family: ",
        ";\n    margin-top: 24px;\n    margin-bottom: 75px;\n\n    span {\n      ",
        "\n      font-weight: 700;\n\n      @media (min-width: ",
        ") {\n        ",
        "\n      }\n    }\n\n    .containerPill {\n      margin-left: 30px;\n      span {\n        ",
        "\n      }\n    }\n\n    .price {\n      margin: 0;\n      margin-left: 30px;\n\n      div {\n        margin: 0;\n        ",
        "\n\n        b:first-of-type {\n          ",
        "\n        }\n\n        @media (min-width: ",
        ") {\n          white-space: nowrap;\n          ",
        "\n\n          b:first-of-type {\n            ",
        "\n          }\n        }\n      }\n    }\n\n    .productDescription div {\n      margin: 0 30px;\n      color: ",
        ";\n      font-family: ",
        ";\n      ",
        ";\n      font-weight: 400;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding-left: 32px;\n    padding-top: 61px;\n\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "/* max-width: 620px; */\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n      max-width: 70%;\n    }\n\n    h6 {\n      margin-top: 24px;\n      ",
        "\n    }\n\n    .text {\n      margin-top: 40px;\n      margin-bottom: 40px;\n\n      div {\n        ",
        "\n        max-width: 70%;\n      }\n      .containerPill {\n        div {\n          max-width: 100%;\n        }\n      }\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding-left: 50px;\n    padding-top: 56px;\n\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    .text {\n      div {\n        ",
        "\n        max-width: 70%;\n      }\n      .containerPill {\n        div {\n          max-width: 100%;\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 30px;\n  padding-bottom: 30px;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin-left: 30px;\n  position: relative;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  h3 {\n    margin-right: ",
        ";\n  }\n  h6 {\n    margin-right: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    h3 {\n      margin-right: 0;\n    }\n    h6 {\n      margin-right: 30px;\n    }\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 0;\n  right: 0;\n  background: ",
        ";\n  border-top-left-radius: 9px;\n  border-bottom-left-radius: 9px;\n  padding-right: 32px;\n\n  > div {\n    width: 56px;\n    height: 56px;\n  }\n\n  @media (min-width: ",
        ") {\n    position: relative;\n    margin-right: 24px;\n    border-top-right-radius: 9px;\n    border-bottom-right-radius: 9px;\n    transform: none;\n    padding-right: 0;\n\n    > div {\n      width: 80px;\n      height: 80px;\n    }\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var SlideProductContainer = styled.div.withConfig({
    componentId: "sc-2a51b584-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-2a51b584-1"
})(_templateObject1(), function(props) {
    return props.absolute ? "absolute" : "relative";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(props) {
    return props.absolute ? 0 : "";
}, breakpoints.tablet, breakpoints.desktop);
export var SlideProductNavigation = styled.div.withConfig({
    componentId: "sc-2a51b584-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductContent = styled.div.withConfig({
    componentId: "sc-2a51b584-3"
})(_templateObject3());
export var SlideProductContentTop = styled.div.withConfig({
    componentId: "sc-2a51b584-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductContentBottom = styled.div.withConfig({
    componentId: "sc-2a51b584-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-2a51b584-6"
})(_templateObject6());
export var SlideProductText = styled.div.withConfig({
    componentId: "sc-2a51b584-7"
})(_templateObject7(), fonts.regular, pxToCssFont(16, 24), pxToRem(0.5), colors.$ffffff, fonts.exbold, pxToCssFont(42, 40), colors.$ffffff, fonts.exbold, pxToCssFont(24, 28), colors.$f2f2f2, fonts.regular, pxToCssFont(16, 22), colors.$bebebe, fonts.regular, pxToCssFont(16, 18), colors.$ffffff, colors.$ffffff, fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24), pxToCssFont(12, 16), pxToCssFont(24, 28), pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(24, 40), pxToCssFont(42, 52), colors.$f2f2f2, fonts.regular, pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(18, 24), pxToCssFont(64, 80), pxToCssFont(30, 38), pxToCssFont(18, 24), pxToCssFont(18, 24), pxToCssFont(14, 18), breakpoints.desktop, pxToCssFont(18, 24), pxToCssFont(70, 65), pxToCssFont(42, 52), pxToCssFont(18, 24));
export var SlideButtons = styled.div.withConfig({
    componentId: "sc-2a51b584-8"
})(_templateObject8());
export var TitleContent = styled.div.withConfig({
    componentId: "sc-2a51b584-9"
})(_templateObject9());
export var TitleText = styled.div.withConfig({
    componentId: "sc-2a51b584-10"
})(_templateObject10(), function(props) {
    return props.iconUrl ? "100px" : "0";
}, function(props) {
    return props.iconUrl ? "100px" : "30px";
}, breakpoints.tablet);
export var IconContainer = styled.div.withConfig({
    componentId: "sc-2a51b584-11"
})(_templateObject11(), colors.$ffffff, breakpoints.tablet);
