import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getActionUrlByActionType } from "@vfit/shared/data-access";
/**
 * Add in documentation only for splitted images text widget
 * @param elements
 */ var getCardsList = function(elements) {
    return elements.map(function(el, indexCard) {
        var ref;
        var isLastCard = indexCard === elements.length - 1;
        return _object_spread({
            title: (el === null || el === void 0 ? void 0 : el.title) || "",
            description: (el === null || el === void 0 ? void 0 : el.description) || "",
            urlImg: (el === null || el === void 0 ? void 0 : el.image) || "",
            urlImgMobile: (el === null || el === void 0 ? void 0 : el.imageMobile) || "",
            action: _object_spread_props(_object_spread({}, el === null || el === void 0 ? void 0 : el.action), {
                style: Number(el === null || el === void 0 ? void 0 : (ref = el.action) === null || ref === void 0 ? void 0 : ref.style)
            }),
            isLastCard: isLastCard
        }, (el === null || el === void 0 ? void 0 : el.action) && {
            actionNavigationUrl: getActionUrlByActionType(el === null || el === void 0 ? void 0 : el.action)
        });
    });
};
export { getCardsList };
