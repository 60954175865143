import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { dxlClient } from "@vfit/business/providers";
import { API } from "@vfit/shared/data-access";
export var postEcommerceCatalogDevices = function(payload) {
    return dxlClient.post(API.DXL_GET_ECOMMERCE_CATALOG, payload);
};
export var getEcommerceCatalogDevices = function(payload) {
    return dxlClient.get(API.DXL_GET_ECOMMERCE_CATALOG, {
        searchParams: _object_spread({}, payload)
    });
};
