import { useGetProductEcommerceCatalog } from "@vfit/business/data-access";
import { getHeroBanner } from "./widgetProductHeroBanner.utils";
export var useWidgetProductHeroBanner = function(widget, enabled) {
    var ref;
    var catalog = useGetProductEcommerceCatalog([
        widget === null || widget === void 0 ? void 0 : widget.product
    ], enabled).catalog;
    var topProduct = getHeroBanner(widget === null || widget === void 0 ? void 0 : widget.product, catalog === null || catalog === void 0 ? void 0 : catalog[0]);
    return {
        product: topProduct,
        disclaimer: widget.descriptionHtml,
        description: widget.disclaimerHtml,
        divider: widget.divider,
        disableNavigation: !(widget === null || widget === void 0 ? void 0 : (ref = widget.product) === null || ref === void 0 ? void 0 : ref.backLabel),
        offerDetails: widget === null || widget === void 0 ? void 0 : widget.customAction
    };
};
